@import url(https://fonts.googleapis.com/css?family=Dosis&subset=latin-ext);
@import "~owl.carousel/dist/assets/owl.carousel.css";
@import "~owl.carousel/dist/assets/owl.theme.default.css";

@font-face {
  font-family: "Avenir-LT-Std";
  src: url("../fonts/AvenirLTStd-Light.otf") format("opentype");
}

@font-face {
  font-family: "Avenir-Next";
  src: url("../fonts/AvenirNextLTPro-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Avenir-LT-Std-BlackOblique";
  src: url("../fonts/AvenirLTStd-BlackOblique_0.otf") format("opentype");
}

@font-face {
  font-family: "Avenir-Bold";
  src: url("../fonts/AvenirBold.otf") format("opentype");
}

:root {
  --color-white: #fff;
  --color-black: #000;
  --color-blue: #0070d2;
  --color-green: #008827;
  --color-primary: #00a1e0;
  --color-red: #c00;
  --color-success: var(--color-green);
  --color-danger: var(--color-red);
  --color-light-blue: #7ed0ee;
  --color-grey1: #f9f9f9;
  --color-grey2: #eee;
  --color-grey3: #ccc;
  --color-grey4: #999;
  --color-grey5: #666;
  --color-grey6: #444;
  --color-grey7: #222;
  --color-grey8: #333;
  --color-grey-transparent-1: rgba(0, 0, 0, 0.65);
  --color-grey-transparent-2: rgba(0, 0, 0, 0.25);
  --color-light-gray: var(--color-grey1);
  --color-slightly-darker-gray: var(--color-grey4);
  --color-dark-gray: var(--color-grey6);
  --color-darker-gray: var(--color-grey7);
  --color-horizontal-rule-grey: var(--color-grey3);
  --color-product-number-grey: var(--color-grey3);
  --color-horizontal-border-grey: var(--color-grey4);
  --color-menu-link: var(--color-grey6);
  --color-close-menu-bg: var(--color-grey2);
  --color-link-color: var(--color-dark-gray);
  --color-hr-border-color: var(--color-grey3);
  --skin-background-color-1: var(--color-gray1);
  --skin-border-color-1: var(--color-white);
  --skin-main-text-color-1: var(--color-gray7);
  --skin-main-text-color-2: var(--color-gray7);
  --skin-menu-color-1: #444;
  --skin-menu-color-1-invert: var(--color-white);
  --skin-menu-color-2: #222;
  --skin-link-color-1: #444;
  --skin-link-color-2: var(--color-blue);
  --skin-primary-color-1: var(--color-primary);
  --skin-primary-color-2: var(--color-primary);
  --skin-primary-color-invert-1: var(--color-white);
  --skin-selectbox-background-color-1: var(--color-white);
  --skin-selectbox-text-color-1: var(--skin-main-text-color-1);
  --skin-banner-background-color-1: #444;
  --skin-banner-background-color-2: #222;
  --skin-banner-text-color-1: var(--color-white);
  --skin-heading-color-1: var(--color-primary);
  --skin-heading-color-1-invert: var(--color-white);
  --skin-price-1: var(--color-gray7);
  --skin-header-font: "Dosis";
  --skin-body-font: var(--font-family-sans-serif);
  --blue: #0070d2;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #c00;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #008827;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #00a1e0;
  --secondary: #6c757d;
  --success: #008827;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #c00;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 544px;
  --breakpoint-md: 769px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

*,
:after,
:before {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: 'Avenir-Next';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #FFFFFF;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  font-style: normal;
  line-height: inherit;
}

address,
dl,
ol,
ul {
  margin-bottom: 1rem;
}

dl,
ol,
ul {
  margin-top: 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #444;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #1e1e1e;
  text-decoration: underline;
}

a:not([href]):not([tabindex]),
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono,
    Courier New, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  border-style: none;
}

img,
svg {
  vertical-align: middle;
}

svg {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="datetime-local"],
input[type="month"],
input[type="time"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.h1,
h1 {
  font-size: 2.5rem;
}

.h2,
h2 {
  font-size: 2rem;
}

.h3,
h3 {
  font-size: 1.75rem;
}

.h4,
h4 {
  font-size: 1.5rem;
}

.h5,
h5 {
  font-size: 1.25rem;
}

.h6,
h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
}

.display-1,
.display-2 {
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
}

.display-3 {
  font-size: 4.5rem;
}

.display-3,
.display-4 {
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #ccc;
}

.small,
small {
  font-size: 80%;
  font-weight: 400;
}

.mark,
mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-inline,
.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer:before {
  content: "\2014\A0";
}

.img-fluid,
.img-thumbnail {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f9f9f9;
  border: 1px solid #dee2e6;
  border-radius: 0.1875rem;
}

.photo-tile-container .figure {
  margin: 0 0 0.6rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

a>code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.1875rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.page .search-banner {
  background-size: cover;
 /*  height: 320px; */
}

.menuCont {
  justify-content: flex-start;
}

.menuColCont {
  padding: 0 5.5rem;
}

#menuDesk .nav-item a:hover header {
  background-color: #000 !important;
}

.quickViewTitle {
  margin-right: 70px;
  font-size: 18px;
  font-family: 'Avenir-Next';
}

.modal-header .full-pdp-link {
  color: #64656A !important;
  font-size: 16px !important;
  font-family: 'Avenir-Next';
  text-decoration: underline;
}

.availability.row.product-availability {
  margin-top: 0;
}

.tooltipAddToCart {
  border: 1px solid #CED4DA;
  color: #B6B6B6;
  height: 49px;
  background-color: transparent;
  padding: 5px 20px;
  font-size: 16px;
  border-radius: 3px;
  width: 100%;
}

.tooltipAddToCart:hover {
  border: 1px solid #000000;
  color: #000000;
}

@media (min-width: 769px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 940px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 544px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-sm-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-sm-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-sm-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-sm-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 769px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-md-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-md-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-md-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-md-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-lg-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-lg-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-lg-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-lg-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xl-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xl-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xl-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xl-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #ccc;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #ccc;
}

.table tbody+tbody {
  border-top: 2px solid #ccc;
}

.table-sm td,
.table-sm th {
  padding: 0.3rem;
}

.table-bordered,
.table-bordered td,
.table-bordered th {
  border: 1px solid #ccc;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}

.table-borderless tbody+tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary>td,
.table-primary>th {
  background-color: #b8e5f6;
}

.table-primary tbody+tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
  border-color: #7aceef;
}

.table-hover .table-primary:hover,
.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
  background-color: #a1ddf3;
}

.table-secondary,
.table-secondary>td,
.table-secondary>th {
  background-color: #d6d8db;
}

.table-secondary tbody+tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover,
.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
  background-color: #c8cbcf;
}

.table-success,
.table-success>td,
.table-success>th {
  background-color: #b8dec3;
}

.table-success tbody+tbody,
.table-success td,
.table-success th,
.table-success thead th {
  border-color: #7ac18f;
}

.table-hover .table-success:hover,
.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
  background-color: #a7d6b4;
}

.table-info,
.table-info>td,
.table-info>th {
  background-color: #bee5eb;
}

.table-info tbody+tbody,
.table-info td,
.table-info th,
.table-info thead th {
  border-color: #86cfda;
}

.table-hover .table-info:hover,
.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
  background-color: #abdde5;
}

.table-warning,
.table-warning>td,
.table-warning>th {
  background-color: #ffeeba;
}

.table-warning tbody+tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover,
.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger>td,
.table-danger>th {
  background-color: #f1b8b8;
}

.table-danger tbody+tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
  border-color: #e47a7a;
}

.table-hover .table-danger:hover,
.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
  background-color: #eda3a3;
}

.table-light,
.table-light>td,
.table-light>th {
  background-color: #fdfdfe;
}

.table-light tbody+tbody,
.table-light td,
.table-light th,
.table-light thead th {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover,
.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
  background-color: #ececf6;
}

.table-dark,
.table-dark>td,
.table-dark>th {
  background-color: #c6c8ca;
}

.table-dark tbody+tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #95999c;
}

.table-hover .table-dark:hover,
.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
  background-color: #b9bbbe;
}

.table-active,
.table-active>td,
.table-active>th,
.table-hover .table-active:hover,
.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #ccc;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: hsla(0, 0%, 100%, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: hsla(0, 0%, 100%, 0.075);
}

#product-search-results .header.page-title.titleCat {
  color: #000 !important;
  background: transparent;
  text-align: center;
  position: inherit;
  font-size: 28px !important;
  font-family: 'Avenir-Next' !important;
  text-transform: uppercase;
  display: block;
}

@media (max-width: 543.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-sm>.table-bordered {
    border: 0;
  }

  .title-container {
    background: #000;
    padding: 0 40px !important;
    height: 40px !important;
  }

  #product-search-results .header.page-title.titleCat {
    display: none;
  }
}

@media (max-width: 426px) {

  .checkout_top_text {
    text-align: center;
  }

  .checkout__text--h3 {
    font-size: 20px !important;
  }
}

@media (max-width: 400px) {
  .checkoutRightCheck {
    padding-left: 1rem !important;
  }

  .checkoutLeftCheck {
    padding-left: 1rem !important;
  }

  .login-checkout {
    padding: 0px 0px !important;
  }

  .form-checkout .checkout--topBorder h3 {
    font-size: 22px;
    font-family: 'Avenir-Next';
  }
}

@media (max-width: 768.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-md>.table-bordered {
    border: 0;
  }

  .myAccount .col-sm-6 {
    padding: 30px 32px 16px 37px !important;
  }

  .myAccount-title {
    font-size: 20px !important;
    line-height: 40px !important;
  }

  .container.account-sections .row {
    padding: 27px 35px 0 34px;
  }

  .promo-container {
    margin-bottom: 0 !important;
  }

}

@media (max-width: 768px) {
  .container.account-sections .row {
    padding: 5px 22px !important;
  }

  .wl-social-sharing {
    padding: 0;
  }

  .order-top {
    margin-top: 14px;
  }

  .card-wishlist {
    margin-top: 10px;
  }


  .container.account-sections .row.addressBottom {
    padding: 0 35px 0 34px !important;
  }

  .container.account-sections .row.backBtnAddress {
    padding: 14px 0 26px 0 !important;
  }

  .login-page .nav-tabs .nav-link.active {
    padding-top: 8px !important;
  }

  .login-page .nav-tabs .nav-link {
    padding-top: 8px !important;
  }

  .login-page .forgot-password {
    float: right !important;
  }

  .login-page .forgot-password a {
    color: #ff0000;
    font-size: 12px;
  }

  .login-page .remember-me {
    float: left !important;
  }

  .login-page .remember-me .custom-control-label {
    font-size: 12px !important;
  }

  #login .clearfix {
    width: 100%;
  }

  .title-container-account h1.page-title {
    height: 40px;
    font-size: 20px !important;
    line-height: 40px;
  }

  .account-sections .card-header h2 {
    font-size: 14px !important;
  }

  .title-container .page-title {
    font-size: 14px !important;
    line-height: 40px;
  }

  .account-sections .card-header h4 {
    font-size: 14px !important;
  }

  .request-password-title {
    font-size: 14px !important;
  }

  .container.account-sections .row.passBody {
    padding-top: 0 !important;
  }

  .request-password-title {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .account-sections .card-body {
    padding: 21px 23px 20px 22px !important;
    border: 1px solid #000;
  }

  .accountBtns .btn {
    margin-bottom: 15px;
    height: 37px;
  }

  .container.account-sections .row.backBtn {
    padding: 28px 35px 34px 34px !important;
  }

  .myAccount .orderCol {
    background-color: #ffffff !important;
    padding-top: 0 !important;
  }

  .myAccount .profileCol {
    padding-bottom: 0 !important;
  }

  .card-body dt {
    font-size: 14px;
  }

  .card-body dd {
    font-size: 14px;
  }

  .container.account-sections .row.newAddress {
    padding: 0 !important;
  }

  .container.account-sections .row.payMonth {
    padding: 0 !important;
  }

  .backPay {
    margin-top: 0 !important;
    margin-bottom: 29px !important;
  }

  #register label {
    font-size: 12px !important;
  }

  .checkout__text.passwordLeyend {
    font-size: 14px !important;
    line-height: 14px;
  }

  #register .privacy-policy {
    font-size: 12px !important;
  }

  .dashboardAccount .card-header {
    height: 39px;
  }

  .dashboardAccount .profile-header,
  .dashboardAccount h2 {
    line-height: 40px;
    font-family: "Avenir-Next" !important;
  }

  .dashboardAccount .card-header>a {
    margin-top: 8px;
  }

  .myAccount .card-body {
    padding: 23px 0 0 28px;
  }

  #password {
    margin-bottom: 21px;
  }

  .dashboardAccount .card-footer {
    padding-top: 27px;
  }

  .experience-commerce_layouts-promoContainer .sales-price {
    margin-top: 8px;
  }

  .cart-page .product-info {
    margin-bottom: 22px;
  }

  .checkout__cart .product-card-footer {
    margin-top: 60px;
  }

  .checkout__cart .product-card-footer .line-item-total-price {
    padding: 0;
    position: absolute;
    right: 0;
  }

  .checkout__cart .item-attributes p,
  .checkout__cart .item-attributes a,
  .line-item-price-info,
  .quantity-label,
  .quantity,
  .checkout__totals {
    font-weight: 900;
    font-family: "Avenir-Next";
  }

  .need-help-phonenumber .checkout-tel {
    color: #0f41f3 !important;
  }

  .need-help-cont {
    margin-bottom: 50px !important;
    margin-top: 50px !important;
  }

  .exampleText {
    font-size: 14px;
  }

  .footerCheckCopy p {
    margin-bottom: 0;
  }

  .checkout-mail-text {
    font-size: 14px !important;
  }

  #checkout-main {
    margin-top: 0 !important;
  }

  /* #actions-icons .minicart-total {
    width: 36px;
  }
 */
  .carousel-control-prev {
    left: 5% !important;
  }

  .carousel-control-next {
    right: 5% !important;
  }

  .promo-carousel .summary__pricing .strike-through span {
    color: #ffffff !important;
  }

  .cart-header .checkout__text {
    line-height: 2;
  }

  .login-checkout {
    margin: 0 30px !important;
  }

  .checkoutRightCheck {
    padding-left: 2rem;
  }

  .experience-commerce_layouts-bannerCarousel .carousel-control-prev,
  .experience-commerce_layouts-bannerCarousel .carousel-control-next,
  .experience-commerce_layouts-carousel .carousel-control-prev,
  .experience-commerce_layouts-carousel .carousel-control-next,
  .promo-carousel .carousel-control-prev,
  .promo-carousel .carousel-control-next {
    display: none !important;
  }

  .wishlist-container .login-page .wishlistItemCards {
    margin: 0 !important;
  }

  .product-details-wrapper .attributes {
    margin-top: 24px;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-lg>.table-bordered {
    border: 0;
  }

  .card-header h2,
  .card-header h3 {
    font-size: 16px !important;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-xl>.table-bordered {
    border: 0;
  }

  .myAccount .breadcrumb {
    left: -15% !important;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive>.table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background: none;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.1875rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  background-color: #fff;
  outline: 0;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.form-control-lg,
.form-control-sm {
  line-height: 1.5;
  border-radius: 0.1875rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
}

select.form-control[multiple],
select.form-control[size],
textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row>.col,
.form-row>[class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled~.form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #008827;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(0, 136, 39, 0.9);
  border-radius: 0.1875rem;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #008827;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23008827' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 100% calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #008827;
  box-shadow: 0 0 0 0.2rem rgba(0, 136, 39, 0.25);
}

.form-control.is-valid~.valid-feedback,
.form-control.is-valid~.valid-tooltip,
.was-validated .form-control:valid~.valid-feedback,
.was-validated .form-control:valid~.valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.custom-select.is-valid,
.was-validated .custom-select:valid {
  border-color: #008827;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23008827' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E") #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
  border-color: #008827;
  box-shadow: 0 0 0 0.2rem rgba(0, 136, 39, 0.25);
}

.custom-select.is-valid~.valid-feedback,
.custom-select.is-valid~.valid-tooltip,
.form-control-file.is-valid~.valid-feedback,
.form-control-file.is-valid~.valid-tooltip,
.was-validated .custom-select:valid~.valid-feedback,
.was-validated .custom-select:valid~.valid-tooltip,
.was-validated .form-control-file:valid~.valid-feedback,
.was-validated .form-control-file:valid~.valid-tooltip {
  display: block;
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
  color: #008827;
}

.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip,
.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip {
  display: block;
}

.custom-control-input.is-valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label {
  color: #008827;
}

.custom-control-input.is-valid~.custom-control-label:before,
.was-validated .custom-control-input:valid~.custom-control-label:before {
  border-color: #008827;
}

.custom-control-input.is-valid~.valid-feedback,
.custom-control-input.is-valid~.valid-tooltip,
.was-validated .custom-control-input:valid~.valid-feedback,
.was-validated .custom-control-input:valid~.valid-tooltip {
  display: block;
}

.custom-control-input.is-valid:checked~.custom-control-label:before,
.was-validated .custom-control-input:valid:checked~.custom-control-label:before {
  border-color: #00bb36;
  background-color: #00bb36;
}

.custom-control-input.is-valid:focus~.custom-control-label:before,
.was-validated .custom-control-input:valid:focus~.custom-control-label:before {
  box-shadow: 0 0 0 0.2rem rgba(0, 136, 39, 0.25);
}

.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label:before,
.custom-file-input.is-valid~.custom-file-label,
.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label:before,
.was-validated .custom-file-input:valid~.custom-file-label {
  border-color: #008827;
}

.custom-file-input.is-valid~.valid-feedback,
.custom-file-input.is-valid~.valid-tooltip,
.was-validated .custom-file-input:valid~.valid-feedback,
.was-validated .custom-file-input:valid~.valid-tooltip {
  display: block;
}

.custom-file-input.is-valid:focus~.custom-file-label,
.was-validated .custom-file-input:valid:focus~.custom-file-label {
  border-color: #008827;
  box-shadow: 0 0 0 0.2rem rgba(0, 136, 39, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: 16px;
  color: #c00;
  font-weight: 500;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(204, 0, 0, 0.9);
  border-radius: 0.1875rem;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #c00;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23c00' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23c00' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 100% calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #c00;
  box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25);
}

.form-control.is-invalid~.invalid-feedback,
.form-control.is-invalid~.invalid-tooltip,
.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.custom-select.is-invalid,
.was-validated .custom-select:invalid {
  border-color: #c00;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23c00' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23c00' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E") #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
  border-color: #c00;
  box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25);
}

.custom-select.is-invalid~.invalid-feedback,
.custom-select.is-invalid~.invalid-tooltip,
.form-control-file.is-invalid~.invalid-feedback,
.form-control-file.is-invalid~.invalid-tooltip,
.was-validated .custom-select:invalid~.invalid-feedback,
.was-validated .custom-select:invalid~.invalid-tooltip,
.was-validated .form-control-file:invalid~.invalid-feedback,
.was-validated .form-control-file:invalid~.invalid-tooltip {
  display: block;
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
  color: #c00;
}

.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip,
.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip {
  display: block;
}

.custom-control-input.is-invalid~.custom-control-label,
.was-validated .custom-control-input:invalid~.custom-control-label {
  color: #c00;
}

.custom-control-input.is-invalid~.custom-control-label:before,
.was-validated .custom-control-input:invalid~.custom-control-label:before {
  border-color: #c00;
}

.custom-control-input.is-invalid~.invalid-feedback,
.custom-control-input.is-invalid~.invalid-tooltip,
.was-validated .custom-control-input:invalid~.invalid-feedback,
.was-validated .custom-control-input:invalid~.invalid-tooltip {
  display: block;
}

.custom-control-input.is-invalid:checked~.custom-control-label:before,
.was-validated .custom-control-input:invalid:checked~.custom-control-label:before {
  border-color: red;
  background-color: red;
}

.custom-control-input.is-invalid:focus~.custom-control-label:before,
.was-validated .custom-control-input:invalid:focus~.custom-control-label:before {
  box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25);
}

.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label:before,
.custom-file-input.is-invalid~.custom-file-label,
.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label:before,
.was-validated .custom-file-input:invalid~.custom-file-label {
  border-color: #c00;
}

.custom-file-input.is-invalid~.invalid-feedback,
.custom-file-input.is-invalid~.invalid-tooltip,
.was-validated .custom-file-input:invalid~.invalid-feedback,
.was-validated .custom-file-input:invalid~.invalid-tooltip {
  display: block;
}

.custom-file-input.is-invalid:focus~.custom-file-label,
.was-validated .custom-file-input:invalid:focus~.custom-file-label {
  border-color: #c00;
  box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25);
}

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 544px) {
  .form-inline label {
    -ms-flex-align: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .form-inline .form-group,
  .form-inline label {
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  .form-inline .form-group {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .custom-select,
  .form-inline .input-group {
    width: auto;
  }

  .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }

  .form-inline .custom-control {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.1875rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 161, 224, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #00a1e0;
  border-color: #00a1e0;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0086ba;
  border-color: #007cad;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 175, 229, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #00a1e0;
  border-color: #00a1e0;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #007cad;
  border-color: #0073a0;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 175, 229, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem hsla(208, 6%, 54%, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem hsla(208, 6%, 54%, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #008827;
  border-color: #008827;
}

.btn-success:hover {
  color: #fff;
  background-color: #00621c;
  border-color: #005518;
}

.btn-success.focus,
.btn-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 154, 71, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #008827;
  border-color: #008827;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #005518;
  border-color: #004815;
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 154, 71, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info.focus,
.btn-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show>.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning.focus,
.btn-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show>.btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #c00;
  border-color: #c00;
}

.btn-danger:hover {
  color: #fff;
  background-color: #a60000;
  border-color: #900;
}

.btn-danger.focus,
.btn-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 38, 38, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #c00;
  border-color: #c00;
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #900;
  border-color: #8c0000;
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 38, 38, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light.focus,
.btn-light:focus {
  box-shadow: 0 0 0 0.2rem hsla(220, 4%, 85%, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem hsla(220, 4%, 85%, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark.focus,
.btn-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show>.btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #00a1e0;
  border-color: #00a1e0;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #00a1e0;
  border-color: #00a1e0;
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 161, 224, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #00a1e0;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #00a1e0;
  border-color: #00a1e0;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 161, 224, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem hsla(208, 7%, 46%, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show>.btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem hsla(208, 7%, 46%, 0.5);
}

.btn-outline-success {
  color: #008827;
  border-color: #008827;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #008827;
  border-color: #008827;
}

.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 136, 39, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #008827;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show>.btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #008827;
  border-color: #008827;
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 136, 39, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info.focus,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show>.btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show>.btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #c00;
  border-color: #c00;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #c00;
  border-color: #c00;
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #c00;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show>.btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #c00;
  border-color: #c00;
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light.focus,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show>.btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show>.btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #444;
  text-decoration: none;
}

.btn-link:hover {
  color: #1e1e1e;
  text-decoration: underline;
}

.btn-link.focus,
.btn-link:focus {
  text-decoration: underline;
  box-shadow: none;
}

.btn-link.disabled,
.btn-link:disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-group-lg>.btn,
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.1875rem;
}

.btn-group-sm>.btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.1875rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block+.btn-block {
  margin-top: 0.5rem;
}

input[type="button"].btn-block,
input[type="reset"].btn-block,
input[type="submit"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 2rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-bottom-right-radius: 0.1875rem;
  border-bottom-left-radius: 0.5rem;
  -webkit-box-shadow: 0px 6px 8px -1px rgba(98, 101, 105, 1);
  -moz-box-shadow: 0px 6px 8px -1px rgba(98, 101, 105, 1);
  box-shadow: 0px 6px 8px -1px rgba(98, 101, 105, 1);
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 544px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 769px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-toggle:after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  display: none;
}

.dropleft .dropdown-toggle:before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropleft .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="top"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #00a1e0;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group-vertical>.btn,
.btn-group>.btn {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:hover,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus,
.btn-group>.btn:hover {
  z-index: 1;
}

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group>.btn-group:not(:first-child),
.btn-group>.btn:not(:first-child) {
  margin-left: -1px;
}

.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split:after,
.dropright .dropdown-toggle-split:after,
.dropup .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-group-sm>.btn+.dropdown-toggle-split,
.btn-sm+.dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-group-lg>.btn+.dropdown-toggle-split,
.btn-lg+.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
  width: 100%;
}

.btn-group-vertical>.btn-group:not(:first-child),
.btn-group-vertical>.btn:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.btn-group-vertical>.btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
  margin-bottom: 0;
}

.btn-group-toggle>.btn-group>.btn input[type="checkbox"],
.btn-group-toggle>.btn-group>.btn input[type="radio"],
.btn-group-toggle>.btn input[type="checkbox"],
.btn-group-toggle>.btn input[type="radio"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.input-group>.custom-file,
.input-group>.custom-select,
.input-group>.form-control,
.input-group>.form-control-plaintext {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group>.custom-file+.custom-file,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.form-control,
.input-group>.custom-select+.custom-file,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.form-control,
.input-group>.form-control+.custom-file,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.form-control,
.input-group>.form-control-plaintext+.custom-file,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.form-control {
  margin-left: -1px;
}

.input-group>.custom-file .custom-file-input:focus~.custom-file-label,
.input-group>.custom-select:focus,
.input-group>.form-control:focus {
  z-index: 3;
}

.input-group>.custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group>.custom-select:not(:last-child),
.input-group>.form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group>.custom-select:not(:first-child),
.input-group>.form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group>.custom-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label:after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-append,
.input-group-prepend {
  display: -ms-flexbox;
  display: flex;
}

.input-group-append .btn,
.input-group-prepend .btn {
  position: relative;
  z-index: 2;
}

.input-group-append .btn:focus,
.input-group-prepend .btn:focus {
  z-index: 3;
}

.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.btn,
.input-group-append .input-group-text+.input-group-text,
.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-prepend .input-group-text+.input-group-text {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.1875rem;
}

.input-group-text input[type="checkbox"],
.input-group-text input[type="radio"] {
  margin-top: 0;
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control:not(textarea) {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg>.custom-select,
.input-group-lg>.form-control,
.input-group-lg>.input-group-append>.btn,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-prepend>.input-group-text {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.1875rem;
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control:not(textarea) {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm>.custom-select,
.input-group-sm>.form-control,
.input-group-sm>.input-group-append>.btn,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-prepend>.input-group-text {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.1875rem;
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
  padding-right: 1.75rem;
}

.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child),
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked~.custom-control-label:before {
  color: #fff;
  border-color: #000;
  border-radius: 50%;
}

.custom-control-input:focus~.custom-control-label:before {
  box-shadow: 0 0 0 0.2rem rgba(0, 161, 224, 0.25);
}

.custom-control-input:focus:not(:checked)~.custom-control-label:before {
  border-color: #61d2ff;
}

.custom-control-input:not(:disabled):active~.custom-control-label:before {
  color: #fff;
  background-color: #94e1ff;
  border-color: #94e1ff;
}

.custom-control-input:disabled~.custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled~.custom-control-label:before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label:before {
  pointer-events: none;
  background-color: #fff;
  border: 1px solid #adb5bd;
}

.custom-control-label:after,
.custom-control-label:before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
}

.checkout_login .custom-control-label:before {
  top: 0.15rem;
}

.custom-checkbox .custom-control-label:before {
  border-radius: 0.1875rem;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label:after {
  background: none;
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label:before {
  border-color: #00a1e0;
  background-color: #00a1e0;
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label:before {
  background-color: rgba(0, 161, 224, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label:before {
  background-color: rgba(0, 161, 224, 0.5);
}

.custom-radio .custom-control-label:before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked~.custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label:before {
  background-color: #ff0000;
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label:before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label:after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label:after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked~.custom-control-label:after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.order-history-form-group {
  padding: 0.375rem 3.75rem 0.375rem 3.75rem;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 1.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.1875rem;
  -webkit-appearance: none;
  appearance: none;
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  display: inline-block;
  margin-bottom: 0;
}

.custom-file,
.custom-file-input {
  position: relative;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
}

.custom-file-input {
  z-index: 2;
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus~.custom-file-label {
  border-color: #61d2ff;
  box-shadow: 0 0 0 0.2rem rgba(0, 161, 224, 0.25);
}

.custom-file-input:disabled~.custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en)~.custom-file-label:after {
  content: "Browse";
}

.custom-file-input~.custom-file-label[data-browse]:after {
  content: attr(data-browse);
}

.custom-file-label {
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  font-weight: 400;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.1875rem;
}

.custom-file-label,
.custom-file-label:after {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
}

.custom-file-label:after {
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.1875rem 0.1875rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f9f9f9, 0 0 0 0.2rem rgba(0, 161, 224, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f9f9f9, 0 0 0 0.2rem rgba(0, 161, 224, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #f9f9f9, 0 0 0 0.2rem rgba(0, 161, 224, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #00a1e0;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #94e1ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #00a1e0;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #94e1ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #00a1e0;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #94e1ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower,
.custom-range::-ms-fill-upper {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label:before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {

  .custom-control-label:before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 0 solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: 0;
}

.nav-tabs .nav-link {
  border: 0 solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #f9f9f9;
  border-color: #dee2e6 #dee2e6 #f9f9f9;
}

.nav-tabs .dropdown-menu {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.1875rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #00a1e0;
}

.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.tab-content>.tab-pane {
  display: none;
}

.tab-content>.active {
  display: block;
}

.navbar {
  position: relative;
  padding: 0.5rem 1rem;
}

.navbar,
.navbar>.container,
.navbar>.container-fluid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.1875rem;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat 50%;
  background-size: 100% 100%;
}

@media (max-width: 543.98px) {

  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 544px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 768.98px) {

  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 769px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {

  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {

  .navbar-expand-xl>.container,
  .navbar-expand-xl>.container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xl>.container,
  .navbar-expand-xl>.container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand,
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: hsla(0, 0%, 100%, 0.5);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: hsla(0, 0%, 100%, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: hsla(0, 0%, 100%, 0.25);
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: hsla(0, 0%, 100%, 0.5);
  border-color: hsla(0, 0%, 100%, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: hsla(0, 0%, 100%, 0.5);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.1875rem;
}

.card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card>.list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.1875rem;
  border-top-right-radius: 0.1875rem;
}

.card>.list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
}

.card-subtitle,
.card-text:last-child {
  margin-bottom: 0;
}

.card-link {
  text-decoration: none;
}

.card-link:hover {
  text-decoration: none;
}

.card-link+.card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.1875rem - 1px) calc(0.1875rem - 1px) 0 0;
}

.card-header+.list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.1875rem - 1px) calc(0.1875rem - 1px);
}

.card-header-tabs {
  margin-bottom: -0.75rem;
  border-bottom: 0;
}

.card-header-pills,
.card-header-tabs {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.1875rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.1875rem - 1px);
  border-top-right-radius: calc(0.1875rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.1875rem - 1px);
  border-bottom-left-radius: calc(0.1875rem - 1px);
}

.card-deck {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 544px) {
  .card-deck {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .card-deck .card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.card-group>.card {
  margin-bottom: 15px;
}

@media (min-width: 544px) {
  .card-group {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }

  .card-group>.card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-header,
  .card-group>.card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-footer,
  .card-group>.card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-header,
  .card-group>.card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-footer,
  .card-group>.card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 544px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion>.card {
  overflow: hidden;
}

.accordion>.card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion>.card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion>.card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion>.card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion>.card .card-header {
  margin-bottom: -1px;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.1875rem;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item:before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item+.breadcrumb-item:hover:before {
  text-decoration: underline;
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.1875rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #444;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #1e1e1e;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 161, 224, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #00a1e0;
  border-color: #00a1e0;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.1875rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:focus,
a.badge:hover {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #00a1e0;
}

a.badge-primary:focus,
a.badge-primary:hover {
  color: #fff;
  background-color: #007cad;
}

a.badge-primary.focus,
a.badge-primary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 161, 224, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:focus,
a.badge-secondary:hover {
  color: #fff;
  background-color: #545b62;
}

a.badge-secondary.focus,
a.badge-secondary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem hsla(208, 7%, 46%, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #008827;
}

a.badge-success:focus,
a.badge-success:hover {
  color: #fff;
  background-color: #005518;
}

a.badge-success.focus,
a.badge-success:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 136, 39, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:focus,
a.badge-info:hover {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info.focus,
a.badge-info:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:focus,
a.badge-warning:hover {
  color: #212529;
  background-color: #d39e00;
}

a.badge-warning.focus,
a.badge-warning:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #c00;
}

a.badge-danger:focus,
a.badge-danger:hover {
  color: #fff;
  background-color: #900;
}

a.badge-danger.focus,
a.badge-danger:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:focus,
a.badge-light:hover {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light.focus,
a.badge-light:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:focus,
a.badge-dark:hover {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark.focus,
a.badge-dark:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.1875rem;
}

@media (min-width: 544px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.1875rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #005474;
  background-color: #ccecf9;
  border-color: #b8e5f6;
}

.alert-primary hr {
  border-top-color: #a1ddf3;
}

.alert-primary .alert-link {
  color: #002f41;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #299106;
  background-color: #d4d4d4;
  border-color: none;
}

.alert-success hr {
  border-top-color: #a7d6b4;
}

.alert-success .alert-link {
  color: #299106;
}

.alert-info {
  color: #0c5460;
  background-color: #d4d4d4;
  border-color: none;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #d4d4d4;
  border-color: none;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #FF4438;
  background-color: #d4d4d4;
  border-color: none;
}

.alert-danger hr {
  border-top-color: #eda3a3;
}

.alert-danger .alert-link {
  color: #370000;
}

.alert-light {
  color: #818182;
  background-color: #d4d4d4;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #C2C3C6;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  0% {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.1875rem;
}

.progress,
.progress-bar {
  display: -ms-flexbox;
  display: flex;
}

.progress-bar {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #00a1e0;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg,
      hsla(0, 0%, 100%, 0.15) 25%,
      transparent 0,
      transparent 50%,
      hsla(0, 0%, 100%, 0.15) 0,
      hsla(0, 0%, 100%, 0.15) 75%,
      transparent 0,
      transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -ms-flex: 1;
  flex: 1;
}

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:focus,
.list-group-item-action:hover {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.1875rem;
  border-top-right-radius: 0.1875rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #00a1e0;
  border-color: #00a1e0;
}

.list-group-horizontal {
  -ms-flex-direction: row;
  flex-direction: row;
}

.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}

.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
  border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem;
  border-bottom-left-radius: 0;
}

@media (min-width: 544px) {
  .list-group-horizontal-sm {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }

  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 0.1875rem;
    border-bottom-left-radius: 0.1875rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.1875rem;
    border-bottom-right-radius: 0.1875rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 769px) {
  .list-group-horizontal-md {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }

  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 0.1875rem;
    border-bottom-left-radius: 0.1875rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.1875rem;
    border-bottom-right-radius: 0.1875rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }

  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 0.1875rem;
    border-bottom-left-radius: 0.1875rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.1875rem;
    border-bottom-right-radius: 0.1875rem;
    border-bottom-left-radius: 0;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }

  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 0.1875rem;
    border-bottom-left-radius: 0.1875rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.1875rem;
    border-bottom-right-radius: 0.1875rem;
    border-bottom-left-radius: 0;
  }
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.list-group-item-primary {
  color: #005474;
  background-color: #b8e5f6;
}

.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #005474;
  background-color: #a1ddf3;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #005474;
  border-color: #005474;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #004714;
  background-color: #b8dec3;
}

.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #004714;
  background-color: #a7d6b4;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #004714;
  border-color: #004714;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #6a0000;
  background-color: #f1b8b8;
}

.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #6a0000;
  background-color: #eda3a3;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #6a0000;
  border-color: #6a0000;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translateY(-50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered:before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable:before {
  content: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.1875rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.1875rem;
  border-top-right-radius: 0.1875rem;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.modal-footer> :not(:first-child) {
  margin-left: 0.25rem;
}

.modal-footer> :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 544px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered:before {
    height: calc(100vh - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow:before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-auto[x-placement^="top"],
.bs-tooltip-top {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[x-placement^="top"] .arrow,
.bs-tooltip-top .arrow {
  bottom: 0;
}

.bs-tooltip-auto[x-placement^="top"] .arrow:before,
.bs-tooltip-top .arrow:before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-auto[x-placement^="right"],
.bs-tooltip-right {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[x-placement^="right"] .arrow,
.bs-tooltip-right .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[x-placement^="right"] .arrow:before,
.bs-tooltip-right .arrow:before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-auto[x-placement^="bottom"],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow,
.bs-tooltip-bottom .arrow {
  top: 0;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow:before,
.bs-tooltip-bottom .arrow:before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-auto[x-placement^="left"],
.bs-tooltip-left {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[x-placement^="left"] .arrow,
.bs-tooltip-left .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[x-placement^="left"] .arrow:before,
.bs-tooltip-left .arrow:before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.1875rem;
}

.popover {
  top: 0;
  left: 0;
  z-index: 1060;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.1875rem;
}

.popover ul {
  display: flex;
  flex-direction: column !important;
}

.popover,
.popover .arrow {
  position: absolute;
  display: block;
}

.popover .arrow {
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.1875rem;
}

.popover .arrow:after,
.popover .arrow:before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-auto[x-placement^="top"],
.bs-popover-top {
  margin-bottom: 0.5rem;
}

.bs-popover-auto[x-placement^="top"]>.arrow,
.bs-popover-top>.arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-auto[x-placement^="top"]>.arrow:before,
.bs-popover-top>.arrow:before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^="top"]>.arrow:after,
.bs-popover-top>.arrow:after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-auto[x-placement^="right"],
.bs-popover-right {
  margin-left: 0.5rem;
}

.bs-popover-auto[x-placement^="right"]>.arrow,
.bs-popover-right>.arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.1875rem 0;
}

.bs-popover-auto[x-placement^="right"]>.arrow:before,
.bs-popover-right>.arrow:before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^="right"]>.arrow:after,
.bs-popover-right>.arrow:after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-auto[x-placement^="bottom"],
.bs-popover-bottom {
  margin-top: 0.5rem;
}

.bs-popover-auto[x-placement^="bottom"]>.arrow,
.bs-popover-bottom>.arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-auto[x-placement^="bottom"]>.arrow:before,
.bs-popover-bottom>.arrow:before {
  top: 0;
  border-width: 0 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^="bottom"]>.arrow:after,
.bs-popover-bottom>.arrow:after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-auto[x-placement^="bottom"] .popover-header:before,
.bs-popover-bottom .popover-header:before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-auto[x-placement^="left"],
.bs-popover-left {
  margin-right: 0.5rem;
}

.bs-popover-auto[x-placement^="left"]>.arrow,
.bs-popover-left>.arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.1875rem 0;
}

.bs-popover-auto[x-placement^="left"]>.arrow:before,
.bs-popover-left>.arrow:before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[x-placement^="left"]>.arrow:after,
.bs-popover-left>.arrow:after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.1875rem - 1px);
  border-top-right-radius: calc(0.1875rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner:after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}

.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
  transform: translateX(100%);
}

.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-control-next,
  .carousel-control-prev {
    transition: none;
  }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #00a1e0 !important;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #007cad !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #008827 !important;
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #005518 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #c00 !important;
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #900 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #00a1e0 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #008827 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #c00 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded,
.rounded-sm {
  border-radius: 0.1875rem !important;
}

.rounded-top {
  border-top-left-radius: 0.1875rem !important;
}

.rounded-right,
.rounded-top {
  border-top-right-radius: 0.1875rem !important;
}

.rounded-bottom,
.rounded-right {
  border-bottom-right-radius: 0.1875rem !important;
}

.rounded-bottom,
.rounded-left {
  border-bottom-left-radius: 0.1875rem !important;
}

.rounded-left {
  border-top-left-radius: 0.1875rem !important;
}

.rounded-lg {
  border-radius: 0.1875rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix:after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 544px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 769px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive:before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9:before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9:before {
  padding-top: 56.25%;
}

.embed-responsive-4by3:before {
  padding-top: 75%;
}

.embed-responsive-1by1:before {
  padding-top: 100%;
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  -ms-grid-row-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  -ms-grid-row-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  -ms-grid-row-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 544px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
    align-self: auto !important;
  }

  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
    align-self: center !important;
  }

  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 769px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
    align-self: auto !important;
  }

  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-md-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
    align-self: center !important;
  }

  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
    align-self: auto !important;
  }

  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
    align-self: center !important;
  }

  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    -ms-grid-row-align: auto !important;
    align-self: auto !important;
  }

  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    -ms-grid-row-align: center !important;
    align-self: center !important;
  }

  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    -ms-grid-row-align: stretch !important;
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 544px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 769px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  top: 0;
}

.fixed-bottom,
.fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  bottom: 0;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  white-space: nowrap;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: transparent;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 544px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 769px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono,
    Courier New, monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 544px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 769px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #00a1e0 !important;
}

a.text-primary:focus,
a.text-primary:hover {
  color: #006a94 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:focus,
a.text-secondary:hover {
  color: #494f54 !important;
}

.text-success {
  color: #008827 !important;
}

a.text-success:focus,
a.text-success:hover {
  color: #003c11 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:focus,
a.text-info:hover {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:focus,
a.text-warning:hover {
  color: #ba8b00 !important;
}

.text-danger {
  color: #c00 !important;
}

a.text-danger:focus,
a.text-danger:hover {
  color: maroon !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:focus,
a.text-light:hover {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:focus,
a.text-dark:hover {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: hsla(0, 0%, 100%, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {

  *,
  :after,
  :before {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  blockquote,
  pre {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  img,
  tr {
    page-break-inside: avoid;
  }

  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  .container,
  body {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: #fff !important;
  }

  .table-bordered td,
  .table-bordered th {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark tbody+tbody,
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #ccc;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #ccc;
  }
}

.carousel .icon-next,
.carousel .icon-prev {
  background-color: #fff;
  font-size: 1.875em;
  height: 3rem;
  padding-top: 0.24em;
  width: 3rem;
}

.carousel .icon-next:before,
.carousel .icon-prev:before {
  color: #000;
  font-family: FontAwesome;
}

.carousel .icon-prev:before {
  content: "\F104";
}

.carousel .icon-next:before {
  content: "\F105";
}

.carousel .carousel-control-prev {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.carousel .carousel-control-next {
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.carousel .carousel-control-next,
.carousel .carousel-control-prev {
  width: 3rem;
}

.carousel .carousel-control-next:focus,
.carousel .carousel-control-prev:focus {
  outline: auto #000;
}

.nav-tabs {
  border-bottom: 1px solid #ccc;
}

.nav-tabs .nav-link {
  font-size: 1.1rem;
  color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.active {
  border-bottom: 0.188em solid var(--skin-primary-color-1);
}

.card {
  margin-bottom: 1em;
}

.card-header h4 {
  margin-bottom: 0;
}

.modal .modal-body {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

dt {
  color: #495057;
  font-weight: 400;
}

.custom-checkbox .custom-control-label:before {
  border: 1px solid #000;
  background: #f9f9f9;
  background: linear-gradient(#f9f9f9, #ccc);
}

.custom-checkbox .custom-control-input:checked~.custom-control-label:after {
  background-color: #ff0000;
  border-radius: 50%;
}

.custom-radio .custom-control-label:before {
  border: 1px solid #000;
  background: #ccc;
  background: linear-gradient(#ccc, #666);
}

.form-control.is-invalid {
  background-image: none;
}

.pull-left-xs {
  float: left;
}

.pull-right-xs {
  float: right;
}

@media (min-width: 544px) and (max-width: 768.98px) {
  .pull-left-sm {
    float: left;
  }

  .pull-right-sm {
    float: right;
  }
}

@media (min-width: 769px) and (max-width: 991.98px) {
  .pull-left-md {
    float: left;
  }

  .pull-right-md {
    float: right;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .pull-left-lg {
    float: left;
  }

  .pull-right-lg {
    float: right;
  }
}

@media (min-width: 1200px) {
  .pull-left-xl {
    float: left;
  }

  .pull-right-xl {
    float: right;
  }
}

@media (max-width: 543.98px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (max-width: 768.98px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (max-width: 991.98px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (max-width: 1199.98px) {
  .hidden-lg-down {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}

.row.equal-height>[class^="col"] {
  display: -ms-flexbox;
  display: flex;
}

@font-face {
  font-family: FontAwesome;
  src: url("../fonts/fontawesome-webfont.eot?v=4.7.0");
  src: url("../fonts/fontawesome-webfont.eot?#iefix&v=4.7.0") format("embedded-opentype"),
    url("../fonts/fontawesome-webfont.woff2?v=4.7.0") format("woff2"),
    url("../fonts/fontawesome-webfont.woff?v=4.7.0") format("woff"),
    url("../fonts/fontawesome-webfont.ttf?v=4.7.0") format("truetype"),
    url("../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: 400;
  font-style: normal;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -15%;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-fw {
  width: 1.28571em;
  text-align: center;
}

.fa-ul {
  padding-left: 0;
  margin-left: 2.14286em;
  list-style-type: none;
}

.fa-ul>li {
  position: relative;
}

.fa-li {
  position: absolute;
  left: -2.14286em;
  width: 2.14286em;
  top: 0.14286em;
  text-align: center;
}

.fa-li.fa-lg {
  left: -1.85714em;
}

.fa-border {
  padding: 0.2em 0.25em 0.15em;
  border: 0.08em solid #eee;
  border-radius: 0.1em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left {
  margin-right: 0.3em;
}

.fa.fa-pull-right {
  margin-left: 0.3em;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.fa.pull-left {
  margin-right: 0.3em;
}

.fa.pull-right {
  margin-left: 0.3em;
}

.fa-spin {
  animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scaleX(-1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scaleY(-1);
}

:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270 {
  filter: none;
}

.fa-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}

.fa-stack-1x,
.fa-stack-2x {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  text-align: center;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

.fa-glass:before {
  content: "\F000";
}

.fa-music:before {
  content: "\F001";
}

.fa-search:before {
  content: "\F002";
}

.fa-envelope-o:before {
  content: "\F003";
}

.fa-heart:before {
  content: "\F004";
}

.fa-star:before {
  content: "\F005";
}

.fa-star-o:before {
  content: "\F006";
}

.fa-user:before {
  content: "\F007";
}

.fa-film:before {
  content: "\F008";
}

.fa-th-large:before {
  content: "\F009";
}

.fa-th:before {
  content: "\F00A";
}

.fa-th-list:before {
  content: "\F00B";
}

.fa-check:before {
  content: "\F00C";
}

.fa-close:before,
.fa-remove:before,
.fa-times:before {
  content: "\F00D";
}

.fa-search-plus:before {
  content: "\F00E";
}

.fa-search-minus:before {
  content: "\F010";
}

.fa-power-off:before {
  content: "\F011";
}

.fa-signal:before {
  content: "\F012";
}

.fa-cog:before,
.fa-gear:before {
  content: "\F013";
}

.fa-trash-o:before {
  content: "\F014";
}

.fa-home:before {
  content: "\F015";
}

.fa-file-o:before {
  content: "\F016";
}

.fa-clock-o:before {
  content: "\F017";
}

.fa-road:before {
  content: "\F018";
}

.fa-download:before {
  content: "\F019";
}

.fa-arrow-circle-o-down:before {
  content: "\F01A";
}

.fa-arrow-circle-o-up:before {
  content: "\F01B";
}

.fa-inbox:before {
  content: "\F01C";
}

.fa-play-circle-o:before {
  content: "\F01D";
}

.fa-repeat:before,
.fa-rotate-right:before {
  content: "\F01E";
}

.fa-refresh:before {
  content: "\F021";
}

.fa-list-alt:before {
  content: "\F022";
}

.fa-lock:before {
  content: "\F023";
}

.fa-flag:before {
  content: "\F024";
}

.fa-headphones:before {
  content: "\F025";
}

.fa-volume-off:before {
  content: "\F026";
}

.fa-volume-down:before {
  content: "\F027";
}

.fa-volume-up:before {
  content: "\F028";
}

.fa-qrcode:before {
  content: "\F029";
}

.fa-barcode:before {
  content: "\F02A";
}

.fa-tag:before {
  content: "\F02B";
}

.fa-tags:before {
  content: "\F02C";
}

.fa-book:before {
  content: "\F02D";
}

.fa-bookmark:before {
  content: "\F02E";
}

.fa-print:before {
  content: "\F02F";
}

.fa-camera:before {
  content: "\F030";
}

.fa-font:before {
  content: "\F031";
}

.fa-bold:before {
  content: "\F032";
}

.fa-italic:before {
  content: "\F033";
}

.fa-text-height:before {
  content: "\F034";
}

.fa-text-width:before {
  content: "\F035";
}

.fa-align-left:before {
  content: "\F036";
}

.fa-align-center:before {
  content: "\F037";
}

.fa-align-right:before {
  content: "\F038";
}

.fa-align-justify:before {
  content: "\F039";
}

.fa-list:before {
  content: "\F03A";
}

.fa-dedent:before,
.fa-outdent:before {
  content: "\F03B";
}

.fa-indent:before {
  content: "\F03C";
}

.fa-video-camera:before {
  content: "\F03D";
}

.fa-image:before,
.fa-photo:before,
.fa-picture-o:before {
  content: "\F03E";
}

.fa-pencil:before {
  content: "\F040";
}

.fa-map-marker:before {
  content: "\F041";
}

.fa-adjust:before {
  content: "\F042";
}

.fa-tint:before {
  content: "\F043";
}

.fa-edit:before,
.fa-pencil-square-o:before {
  content: "\F044";
}

.fa-share-square-o:before {
  content: "\F045";
}

.fa-check-square-o:before {
  content: "\F046";
}

.fa-arrows:before {
  content: "\F047";
}

.fa-step-backward:before {
  content: "\F048";
}

.fa-fast-backward:before {
  content: "\F049";
}

.fa-backward:before {
  content: "\F04A";
}

.fa-play:before {
  content: "\F04B";
}

.fa-pause:before {
  content: "\F04C";
}

.fa-stop:before {
  content: "\F04D";
}

.fa-forward:before {
  content: "\F04E";
}

.fa-fast-forward:before {
  content: "\F050";
}

.fa-step-forward:before {
  content: "\F051";
}

.fa-eject:before {
  content: "\F052";
}

.fa-chevron-left:before {
  content: "\F053";
}

.fa-chevron-right:before {
  content: "\F054";
}

.fa-plus-circle:before {
  content: "\F055";
}

.fa-minus-circle:before {
  content: "\F056";
}

.fa-times-circle:before {
  content: "\F057";
}

.fa-check-circle:before {
  content: "\F058";
}

.fa-question-circle:before {
  content: "\F059";
}

.fa-info-circle:before {
  content: "\F05A";
}

.fa-crosshairs:before {
  content: "\F05B";
}

.fa-times-circle-o:before {
  content: "\F05C";
}

.fa-check-circle-o:before {
  content: "\F05D";
}

.fa-ban:before {
  content: "\F05E";
}

.fa-arrow-left:before {
  content: "\F060";
}

.fa-arrow-right:before {
  content: "\F061";
}

.fa-arrow-up:before {
  content: "\F062";
}

.fa-arrow-down:before {
  content: "\F063";
}

.fa-mail-forward:before,
.fa-share:before {
  content: "\F064";
}

.fa-expand:before {
  content: "\F065";
}

.fa-compress:before {
  content: "\F066";
}

.fa-plus:before {
  content: "\F067";
}

.fa-minus:before {
  content: "\F068";
}

.fa-asterisk:before {
  content: "\F069";
}

.fa-exclamation-circle:before {
  content: "\F06A";
}

.fa-gift:before {
  content: "\F06B";
}

.fa-leaf:before {
  content: "\F06C";
}

.fa-fire:before {
  content: "\F06D";
}

.fa-eye:before {
  content: "\F06E";
}

.fa-eye-slash:before {
  content: "\F070";
}

.fa-exclamation-triangle:before,
.fa-warning:before {
  content: "\F071";
}

.fa-plane:before {
  content: "\F072";
}

.fa-calendar:before {
  content: "\F073";
}

.fa-random:before {
  content: "\F074";
}

.fa-comment:before {
  content: "\F075";
}

.fa-magnet:before {
  content: "\F076";
}

.fa-chevron-up:before {
  content: "\F077";
}

.fa-chevron-down:before {
  content: "\F078";
}

.fa-retweet:before {
  content: "\F079";
}

.fa-shopping-cart:before {
  content: "\F07A";
}

.fa-folder:before {
  content: "\F07B";
}

.fa-folder-open:before {
  content: "\F07C";
}

.fa-arrows-v:before {
  content: "\F07D";
}

.fa-arrows-h:before {
  content: "\F07E";
}

.fa-bar-chart-o:before,
.fa-bar-chart:before {
  content: "\F080";
}

.fa-twitter-square:before {
  content: "\F081";
}

.fa-facebook-square:before {
  content: "\F082";
}

.fa-camera-retro:before {
  content: "\F083";
}

.fa-key:before {
  content: "\F084";
}

.fa-cogs:before,
.fa-gears:before {
  content: "\F085";
}

.fa-comments:before {
  content: "\F086";
}

.fa-thumbs-o-up:before {
  content: "\F087";
}

.fa-thumbs-o-down:before {
  content: "\F088";
}

.fa-star-half:before {
  content: "\F089";
}

.fa-heart-o:before {
  content: "\F08A";
}

.fa-sign-out:before {
  content: "\F08B";
}

.fa-linkedin-square:before {
  content: "\F08C";
}

.fa-thumb-tack:before {
  content: "\F08D";
}

.fa-external-link:before {
  content: "\F08E";
}

.fa-sign-in:before {
  content: "\F090";
}

.fa-trophy:before {
  content: "\F091";
}

.fa-github-square:before {
  content: "\F092";
}

.fa-upload:before {
  content: "\F093";
}

.fa-lemon-o:before {
  content: "\F094";
}

.fa-phone:before {
  content: "\F095";
}

.fa-square-o:before {
  content: "\F096";
}

.fa-bookmark-o:before {
  content: "\F097";
}

.fa-phone-square:before {
  content: "\F098";
}

.fa-twitter:before {
  content: "\F099";
}

.fa-facebook-f:before,
.fa-facebook:before {
  content: "\F09A";
}

.fa-github:before {
  content: "\F09B";
}

.fa-unlock:before {
  content: "\F09C";
}

.fa-credit-card:before {
  content: "\F09D";
}

.fa-feed:before,
.fa-rss:before {
  content: "\F09E";
}

.fa-hdd-o:before {
  content: "\F0A0";
}

.fa-bullhorn:before {
  content: "\F0A1";
}

.fa-bell:before {
  content: "\F0F3";
}

.fa-certificate:before {
  content: "\F0A3";
}

.fa-hand-o-right:before {
  content: "\F0A4";
}

.fa-hand-o-left:before {
  content: "\F0A5";
}

.fa-hand-o-up:before {
  content: "\F0A6";
}

.fa-hand-o-down:before {
  content: "\F0A7";
}

.fa-arrow-circle-left:before {
  content: "\F0A8";
}

.fa-arrow-circle-right:before {
  content: "\F0A9";
}

.fa-arrow-circle-up:before {
  content: "\F0AA";
}

.fa-arrow-circle-down:before {
  content: "\F0AB";
}

.fa-globe:before {
  content: "\F0AC";
}

.fa-wrench:before {
  content: "\F0AD";
}

.fa-tasks:before {
  content: "\F0AE";
}

.fa-filter:before {
  content: "\F0B0";
}

.fa-briefcase:before {
  content: "\F0B1";
}

.fa-arrows-alt:before {
  content: "\F0B2";
}

.fa-group:before,
.fa-users:before {
  content: "\F0C0";
}

.fa-chain:before,
.fa-link:before {
  content: "\F0C1";
}

.fa-cloud:before {
  content: "\F0C2";
}

.fa-flask:before {
  content: "\F0C3";
}

.fa-cut:before,
.fa-scissors:before {
  content: "\F0C4";
}

.fa-copy:before,
.fa-files-o:before {
  content: "\F0C5";
}

.fa-paperclip:before {
  content: "\F0C6";
}

.fa-floppy-o:before,
.fa-save:before {
  content: "\F0C7";
}

.fa-square:before {
  content: "\F0C8";
}

.fa-bars:before,
.fa-navicon:before,
.fa-reorder:before {
  content: "\F0C9";
}

.fa-list-ul:before {
  content: "\F0CA";
}

.fa-list-ol:before {
  content: "\F0CB";
}

.fa-strikethrough:before {
  content: "\F0CC";
}

.fa-underline:before {
  content: "\F0CD";
}

.fa-table:before {
  content: "\F0CE";
}

.fa-magic:before {
  content: "\F0D0";
}

.fa-truck:before {
  content: "\F0D1";
}

.fa-pinterest:before {
  content: "\F0D2";
}

.fa-pinterest-square:before {
  content: "\F0D3";
}

.fa-google-plus-square:before {
  content: "\F0D4";
}

.fa-google-plus:before {
  content: "\F0D5";
}

.fa-money:before {
  content: "\F0D6";
}

.fa-caret-down:before {
  content: "\F0D7";
}

.fa-caret-up:before {
  content: "\F0D8";
}

.fa-caret-left:before {
  content: "\F0D9";
}

.fa-caret-right:before {
  content: "\F0DA";
}

.fa-columns:before {
  content: "\F0DB";
}

.fa-sort:before,
.fa-unsorted:before {
  content: "\F0DC";
}

.fa-sort-desc:before,
.fa-sort-down:before {
  content: "\F0DD";
}

.fa-sort-asc:before,
.fa-sort-up:before {
  content: "\F0DE";
}

.fa-envelope:before {
  content: "\F0E0";
}

.fa-linkedin:before {
  content: "\F0E1";
}

.fa-rotate-left:before,
.fa-undo:before {
  content: "\F0E2";
}

.fa-gavel:before,
.fa-legal:before {
  content: "\F0E3";
}

.fa-dashboard:before,
.fa-tachometer:before {
  content: "\F0E4";
}

.fa-comment-o:before {
  content: "\F0E5";
}

.fa-comments-o:before {
  content: "\F0E6";
}

.fa-bolt:before,
.fa-flash:before {
  content: "\F0E7";
}

.fa-sitemap:before {
  content: "\F0E8";
}

.fa-umbrella:before {
  content: "\F0E9";
}

.fa-clipboard:before,
.fa-paste:before {
  content: "\F0EA";
}

.fa-lightbulb-o:before {
  content: "\F0EB";
}

.fa-exchange:before {
  content: "\F0EC";
}

.fa-cloud-download:before {
  content: "\F0ED";
}

.fa-cloud-upload:before {
  content: "\F0EE";
}

.fa-user-md:before {
  content: "\F0F0";
}

.fa-stethoscope:before {
  content: "\F0F1";
}

.fa-suitcase:before {
  content: "\F0F2";
}

.fa-bell-o:before {
  content: "\F0A2";
}

.fa-coffee:before {
  content: "\F0F4";
}

.fa-cutlery:before {
  content: "\F0F5";
}

.fa-file-text-o:before {
  content: "\F0F6";
}

.fa-building-o:before {
  content: "\F0F7";
}

.fa-hospital-o:before {
  content: "\F0F8";
}

.fa-ambulance:before {
  content: "\F0F9";
}

.fa-medkit:before {
  content: "\F0FA";
}

.fa-fighter-jet:before {
  content: "\F0FB";
}

.fa-beer:before {
  content: "\F0FC";
}

.fa-h-square:before {
  content: "\F0FD";
}

.fa-plus-square:before {
  content: "\F0FE";
}

.fa-angle-double-left:before {
  content: "\F100";
}

.fa-angle-double-right:before {
  content: "\F101";
}

.fa-angle-double-up:before {
  content: "\F102";
}

.fa-angle-double-down:before {
  content: "\F103";
}

.fa-angle-left:before {
  content: "\F104";
}

.fa-angle-right:before {
  content: "\F105";
}

.fa-angle-up:before {
  content: "\F106";
}

.fa-angle-down:before {
  content: "\F107";
}

.fa-desktop:before {
  content: "\F108";
}

.fa-laptop:before {
  content: "\F109";
}

.fa-tablet:before {
  content: "\F10A";
}

.fa-mobile-phone:before,
.fa-mobile:before {
  content: "\F10B";
}

.fa-circle-o:before {
  content: "\F10C";
}

.fa-quote-left:before {
  content: "\F10D";
}

.fa-quote-right:before {
  content: "\F10E";
}

.fa-spinner:before {
  content: "\F110";
}

.fa-circle:before {
  content: "\F111";
}

.fa-mail-reply:before,
.fa-reply:before {
  content: "\F112";
}

.fa-github-alt:before {
  content: "\F113";
}

.fa-folder-o:before {
  content: "\F114";
}

.fa-folder-open-o:before {
  content: "\F115";
}

.fa-smile-o:before {
  content: "\F118";
}

.fa-frown-o:before {
  content: "\F119";
}

.fa-meh-o:before {
  content: "\F11A";
}

.fa-gamepad:before {
  content: "\F11B";
}

.fa-keyboard-o:before {
  content: "\F11C";
}

.fa-flag-o:before {
  content: "\F11D";
}

.fa-flag-checkered:before {
  content: "\F11E";
}

.fa-terminal:before {
  content: "\F120";
}

.fa-code:before {
  content: "\F121";
}

.fa-mail-reply-all:before,
.fa-reply-all:before {
  content: "\F122";
}

.fa-star-half-empty:before,
.fa-star-half-full:before,
.fa-star-half-o:before {
  content: "\F123";
}

.fa-location-arrow:before {
  content: "\F124";
}

.fa-crop:before {
  content: "\F125";
}

.fa-code-fork:before {
  content: "\F126";
}

.fa-chain-broken:before,
.fa-unlink:before {
  content: "\F127";
}

.fa-question:before {
  content: "\F128";
}

.fa-info:before {
  content: "\F129";
}

.fa-exclamation:before {
  content: "\F12A";
}

.fa-superscript:before {
  content: "\F12B";
}

.fa-subscript:before {
  content: "\F12C";
}

.fa-eraser:before {
  content: "\F12D";
}

.fa-puzzle-piece:before {
  content: "\F12E";
}

.fa-microphone:before {
  content: "\F130";
}

.fa-microphone-slash:before {
  content: "\F131";
}

.fa-shield:before {
  content: "\F132";
}

.fa-calendar-o:before {
  content: "\F133";
}

.fa-fire-extinguisher:before {
  content: "\F134";
}

.fa-rocket:before {
  content: "\F135";
}

.fa-maxcdn:before {
  content: "\F136";
}

.fa-chevron-circle-left:before {
  content: "\F137";
}

.fa-chevron-circle-right:before {
  content: "\F138";
}

.fa-chevron-circle-up:before {
  content: "\F139";
}

.fa-chevron-circle-down:before {
  content: "\F13A";
}

.fa-html5:before {
  content: "\F13B";
}

.fa-css3:before {
  content: "\F13C";
}

.fa-anchor:before {
  content: "\F13D";
}

.fa-unlock-alt:before {
  content: "\F13E";
}

.fa-bullseye:before {
  content: "\F140";
}

.fa-ellipsis-h:before {
  content: "\F141";
}

.fa-ellipsis-v:before {
  content: "\F142";
}

.fa-rss-square:before {
  content: "\F143";
}

.fa-play-circle:before {
  content: "\F144";
}

.fa-ticket:before {
  content: "\F145";
}

.fa-minus-square:before {
  content: "\F146";
}

.fa-minus-square-o:before {
  content: "\F147";
}

.fa-level-up:before {
  content: "\F148";
}

.fa-level-down:before {
  content: "\F149";
}

.fa-check-square:before {
  content: "\F14A";
}

.fa-pencil-square:before {
  content: "\F14B";
}

.fa-external-link-square:before {
  content: "\F14C";
}

.fa-share-square:before {
  content: "\F14D";
}

.fa-compass:before {
  content: "\F14E";
}

.fa-caret-square-o-down:before,
.fa-toggle-down:before {
  content: "\F150";
}

.fa-caret-square-o-up:before,
.fa-toggle-up:before {
  content: "\F151";
}

.fa-caret-square-o-right:before,
.fa-toggle-right:before {
  content: "\F152";
}

.fa-eur:before,
.fa-euro:before {
  content: "\F153";
}

.fa-gbp:before {
  content: "\F154";
}

.fa-dollar:before,
.fa-usd:before {
  content: "\F155";
}

.fa-inr:before,
.fa-rupee:before {
  content: "\F156";
}

.fa-cny:before,
.fa-jpy:before,
.fa-rmb:before,
.fa-yen:before {
  content: "\F157";
}

.fa-rouble:before,
.fa-rub:before,
.fa-ruble:before {
  content: "\F158";
}

.fa-krw:before,
.fa-won:before {
  content: "\F159";
}

.fa-bitcoin:before,
.fa-btc:before {
  content: "\F15A";
}

.fa-file:before {
  content: "\F15B";
}

.fa-file-text:before {
  content: "\F15C";
}

.fa-sort-alpha-asc:before {
  content: "\F15D";
}

.fa-sort-alpha-desc:before {
  content: "\F15E";
}

.fa-sort-amount-asc:before {
  content: "\F160";
}

.fa-sort-amount-desc:before {
  content: "\F161";
}

.fa-sort-numeric-asc:before {
  content: "\F162";
}

.fa-sort-numeric-desc:before {
  content: "\F163";
}

.fa-thumbs-up:before {
  content: "\F164";
}

.fa-thumbs-down:before {
  content: "\F165";
}

.fa-youtube-square:before {
  content: "\F166";
}

.fa-youtube:before {
  content: "\F167";
}

.fa-xing:before {
  content: "\F168";
}

.fa-xing-square:before {
  content: "\F169";
}

.fa-youtube-play:before {
  content: "\F16A";
}

.fa-dropbox:before {
  content: "\F16B";
}

.fa-stack-overflow:before {
  content: "\F16C";
}

.fa-instagram:before {
  content: "\F16D";
}

.fa-flickr:before {
  content: "\F16E";
}

.fa-adn:before {
  content: "\F170";
}

.fa-bitbucket:before {
  content: "\F171";
}

.fa-bitbucket-square:before {
  content: "\F172";
}

.fa-tumblr:before {
  content: "\F173";
}

.fa-tumblr-square:before {
  content: "\F174";
}

.fa-long-arrow-down:before {
  content: "\F175";
}

.fa-long-arrow-up:before {
  content: "\F176";
}

.fa-long-arrow-left:before {
  content: "\F177";
}

.fa-long-arrow-right:before {
  content: "\F178";
}

.fa-apple:before {
  content: "\F179";
}

.fa-windows:before {
  content: "\F17A";
}

.fa-android:before {
  content: "\F17B";
}

.fa-linux:before {
  content: "\F17C";
}

.fa-dribbble:before {
  content: "\F17D";
}

.fa-skype:before {
  content: "\F17E";
}

.fa-foursquare:before {
  content: "\F180";
}

.fa-trello:before {
  content: "\F181";
}

.fa-female:before {
  content: "\F182";
}

.fa-male:before {
  content: "\F183";
}

.fa-gittip:before,
.fa-gratipay:before {
  content: "\F184";
}

.fa-sun-o:before {
  content: "\F185";
}

.fa-moon-o:before {
  content: "\F186";
}

.fa-archive:before {
  content: "\F187";
}

.fa-bug:before {
  content: "\F188";
}

.fa-vk:before {
  content: "\F189";
}

.fa-weibo:before {
  content: "\F18A";
}

.fa-renren:before {
  content: "\F18B";
}

.fa-pagelines:before {
  content: "\F18C";
}

.fa-stack-exchange:before {
  content: "\F18D";
}

.fa-arrow-circle-o-right:before {
  content: "\F18E";
}

.fa-arrow-circle-o-left:before {
  content: "\F190";
}

.fa-caret-square-o-left:before,
.fa-toggle-left:before {
  content: "\F191";
}

.fa-dot-circle-o:before {
  content: "\F192";
}

.fa-wheelchair:before {
  content: "\F193";
}

.fa-vimeo-square:before {
  content: "\F194";
}

.fa-try:before,
.fa-turkish-lira:before {
  content: "\F195";
}

.fa-plus-square-o:before {
  content: "\F196";
}

.fa-space-shuttle:before {
  content: "\F197";
}

.fa-slack:before {
  content: "\F198";
}

.fa-envelope-square:before {
  content: "\F199";
}

.fa-wordpress:before {
  content: "\F19A";
}

.fa-openid:before {
  content: "\F19B";
}

.fa-bank:before,
.fa-institution:before,
.fa-university:before {
  content: "\F19C";
}

.fa-graduation-cap:before,
.fa-mortar-board:before {
  content: "\F19D";
}

.fa-yahoo:before {
  content: "\F19E";
}

.fa-google:before {
  content: "\F1A0";
}

.fa-reddit:before {
  content: "\F1A1";
}

.fa-reddit-square:before {
  content: "\F1A2";
}

.fa-stumbleupon-circle:before {
  content: "\F1A3";
}

.fa-stumbleupon:before {
  content: "\F1A4";
}

.fa-delicious:before {
  content: "\F1A5";
}

.fa-digg:before {
  content: "\F1A6";
}

.fa-pied-piper-pp:before {
  content: "\F1A7";
}

.fa-pied-piper-alt:before {
  content: "\F1A8";
}

.fa-drupal:before {
  content: "\F1A9";
}

.fa-joomla:before {
  content: "\F1AA";
}

.fa-language:before {
  content: "\F1AB";
}

.fa-fax:before {
  content: "\F1AC";
}

.fa-building:before {
  content: "\F1AD";
}

.fa-child:before {
  content: "\F1AE";
}

.fa-paw:before {
  content: "\F1B0";
}

.fa-spoon:before {
  content: "\F1B1";
}

.fa-cube:before {
  content: "\F1B2";
}

.fa-cubes:before {
  content: "\F1B3";
}

.fa-behance:before {
  content: "\F1B4";
}

.fa-behance-square:before {
  content: "\F1B5";
}

.fa-steam:before {
  content: "\F1B6";
}

.fa-steam-square:before {
  content: "\F1B7";
}

.fa-recycle:before {
  content: "\F1B8";
}

.fa-automobile:before,
.fa-car:before {
  content: "\F1B9";
}

.fa-cab:before,
.fa-taxi:before {
  content: "\F1BA";
}

.fa-tree:before {
  content: "\F1BB";
}

.fa-spotify:before {
  content: "\F1BC";
}

.fa-deviantart:before {
  content: "\F1BD";
}

.fa-soundcloud:before {
  content: "\F1BE";
}

.fa-database:before {
  content: "\F1C0";
}

.fa-file-pdf-o:before {
  content: "\F1C1";
}

.fa-file-word-o:before {
  content: "\F1C2";
}

.fa-file-excel-o:before {
  content: "\F1C3";
}

.fa-file-powerpoint-o:before {
  content: "\F1C4";
}

.fa-file-image-o:before,
.fa-file-photo-o:before,
.fa-file-picture-o:before {
  content: "\F1C5";
}

.fa-file-archive-o:before,
.fa-file-zip-o:before {
  content: "\F1C6";
}

.fa-file-audio-o:before,
.fa-file-sound-o:before {
  content: "\F1C7";
}

.fa-file-movie-o:before,
.fa-file-video-o:before {
  content: "\F1C8";
}

.fa-file-code-o:before {
  content: "\F1C9";
}

.fa-vine:before {
  content: "\F1CA";
}

.fa-codepen:before {
  content: "\F1CB";
}

.fa-jsfiddle:before {
  content: "\F1CC";
}

.fa-life-bouy:before,
.fa-life-buoy:before,
.fa-life-ring:before,
.fa-life-saver:before,
.fa-support:before {
  content: "\F1CD";
}

.fa-circle-o-notch:before {
  content: "\F1CE";
}

.fa-ra:before,
.fa-rebel:before,
.fa-resistance:before {
  content: "\F1D0";
}

.fa-empire:before,
.fa-ge:before {
  content: "\F1D1";
}

.fa-git-square:before {
  content: "\F1D2";
}

.fa-git:before {
  content: "\F1D3";
}

.fa-hacker-news:before,
.fa-y-combinator-square:before,
.fa-yc-square:before {
  content: "\F1D4";
}

.fa-tencent-weibo:before {
  content: "\F1D5";
}

.fa-qq:before {
  content: "\F1D6";
}

.fa-wechat:before,
.fa-weixin:before {
  content: "\F1D7";
}

.fa-paper-plane:before,
.fa-send:before {
  content: "\F1D8";
}

.fa-paper-plane-o:before,
.fa-send-o:before {
  content: "\F1D9";
}

.fa-history:before {
  content: "\F1DA";
}

.fa-circle-thin:before {
  content: "\F1DB";
}

.fa-header:before {
  content: "\F1DC";
}

.fa-paragraph:before {
  content: "\F1DD";
}

.fa-sliders:before {
  content: "\F1DE";
}

.fa-share-alt:before {
  content: "\F1E0";
}

.fa-share-alt-square:before {
  content: "\F1E1";
}

.fa-bomb:before {
  content: "\F1E2";
}

.fa-futbol-o:before,
.fa-soccer-ball-o:before {
  content: "\F1E3";
}

.fa-tty:before {
  content: "\F1E4";
}

.fa-binoculars:before {
  content: "\F1E5";
}

.fa-plug:before {
  content: "\F1E6";
}

.fa-slideshare:before {
  content: "\F1E7";
}

.fa-twitch:before {
  content: "\F1E8";
}

.fa-yelp:before {
  content: "\F1E9";
}

.fa-newspaper-o:before {
  content: "\F1EA";
}

.fa-wifi:before {
  content: "\F1EB";
}

.fa-calculator:before {
  content: "\F1EC";
}

.fa-paypal:before {
  content: "\F1ED";
}

.fa-google-wallet:before {
  content: "\F1EE";
}

.fa-cc-visa:before {
  content: "\F1F0";
}

.fa-cc-mastercard:before {
  content: "\F1F1";
}

.fa-cc-discover:before {
  content: "\F1F2";
}

.fa-cc-amex:before {
  content: "\F1F3";
}

.fa-cc-paypal:before {
  content: "\F1F4";
}

.fa-cc-stripe:before {
  content: "\F1F5";
}

.fa-bell-slash:before {
  content: "\F1F6";
}

.fa-bell-slash-o:before {
  content: "\F1F7";
}

.fa-trash:before {
  content: "\F1F8";
}

.fa-copyright:before {
  content: "\F1F9";
}

.fa-at:before {
  content: "\F1FA";
}

.fa-eyedropper:before {
  content: "\F1FB";
}

.fa-paint-brush:before {
  content: "\F1FC";
}

.fa-birthday-cake:before {
  content: "\F1FD";
}

.fa-area-chart:before {
  content: "\F1FE";
}

.fa-pie-chart:before {
  content: "\F200";
}

.fa-line-chart:before {
  content: "\F201";
}

.fa-lastfm:before {
  content: "\F202";
}

.fa-lastfm-square:before {
  content: "\F203";
}

.fa-toggle-off:before {
  content: "\F204";
}

.fa-toggle-on:before {
  content: "\F205";
}

.fa-bicycle:before {
  content: "\F206";
}

.fa-bus:before {
  content: "\F207";
}

.fa-ioxhost:before {
  content: "\F208";
}

.fa-angellist:before {
  content: "\F209";
}

.fa-cc:before {
  content: "\F20A";
}

.fa-ils:before,
.fa-shekel:before,
.fa-sheqel:before {
  content: "\F20B";
}

.fa-meanpath:before {
  content: "\F20C";
}

.fa-buysellads:before {
  content: "\F20D";
}

.fa-connectdevelop:before {
  content: "\F20E";
}

.fa-dashcube:before {
  content: "\F210";
}

.fa-forumbee:before {
  content: "\F211";
}

.fa-leanpub:before {
  content: "\F212";
}

.fa-sellsy:before {
  content: "\F213";
}

.fa-shirtsinbulk:before {
  content: "\F214";
}

.fa-simplybuilt:before {
  content: "\F215";
}

.fa-skyatlas:before {
  content: "\F216";
}

.fa-cart-plus:before {
  content: "\F217";
}

.fa-cart-arrow-down:before {
  content: "\F218";
}

.fa-diamond:before {
  content: "\F219";
}

.fa-ship:before {
  content: "\F21A";
}

.fa-user-secret:before {
  content: "\F21B";
}

.fa-motorcycle:before {
  content: "\F21C";
}

.fa-street-view:before {
  content: "\F21D";
}

.fa-heartbeat:before {
  content: "\F21E";
}

.fa-venus:before {
  content: "\F221";
}

.fa-mars:before {
  content: "\F222";
}

.fa-mercury:before {
  content: "\F223";
}

.fa-intersex:before,
.fa-transgender:before {
  content: "\F224";
}

.fa-transgender-alt:before {
  content: "\F225";
}

.fa-venus-double:before {
  content: "\F226";
}

.fa-mars-double:before {
  content: "\F227";
}

.fa-venus-mars:before {
  content: "\F228";
}

.fa-mars-stroke:before {
  content: "\F229";
}

.fa-mars-stroke-v:before {
  content: "\F22A";
}

.fa-mars-stroke-h:before {
  content: "\F22B";
}

.fa-neuter:before {
  content: "\F22C";
}

.fa-genderless:before {
  content: "\F22D";
}

.fa-facebook-official:before {
  content: "\F230";
}

.fa-pinterest-p:before {
  content: "\F231";
}

.fa-whatsapp:before {
  content: "\F232";
}

.fa-server:before {
  content: "\F233";
}

.fa-user-plus:before {
  content: "\F234";
}

.fa-user-times:before {
  content: "\F235";
}

.fa-bed:before,
.fa-hotel:before {
  content: "\F236";
}

.fa-viacoin:before {
  content: "\F237";
}

.fa-train:before {
  content: "\F238";
}

.fa-subway:before {
  content: "\F239";
}

.fa-medium:before {
  content: "\F23A";
}

.fa-y-combinator:before,
.fa-yc:before {
  content: "\F23B";
}

.fa-optin-monster:before {
  content: "\F23C";
}

.fa-opencart:before {
  content: "\F23D";
}

.fa-expeditedssl:before {
  content: "\F23E";
}

.fa-battery-4:before,
.fa-battery-full:before,
.fa-battery:before {
  content: "\F240";
}

.fa-battery-3:before,
.fa-battery-three-quarters:before {
  content: "\F241";
}

.fa-battery-2:before,
.fa-battery-half:before {
  content: "\F242";
}

.fa-battery-1:before,
.fa-battery-quarter:before {
  content: "\F243";
}

.fa-battery-0:before,
.fa-battery-empty:before {
  content: "\F244";
}

.fa-mouse-pointer:before {
  content: "\F245";
}

.fa-i-cursor:before {
  content: "\F246";
}

.fa-object-group:before {
  content: "\F247";
}

.fa-object-ungroup:before {
  content: "\F248";
}

.fa-sticky-note:before {
  content: "\F249";
}

.fa-sticky-note-o:before {
  content: "\F24A";
}

.fa-cc-jcb:before {
  content: "\F24B";
}

.fa-cc-diners-club:before {
  content: "\F24C";
}

.fa-clone:before {
  content: "\F24D";
}

.fa-balance-scale:before {
  content: "\F24E";
}

.fa-hourglass-o:before {
  content: "\F250";
}

.fa-hourglass-1:before,
.fa-hourglass-start:before {
  content: "\F251";
}

.fa-hourglass-2:before,
.fa-hourglass-half:before {
  content: "\F252";
}

.fa-hourglass-3:before,
.fa-hourglass-end:before {
  content: "\F253";
}

.fa-hourglass:before {
  content: "\F254";
}

.fa-hand-grab-o:before,
.fa-hand-rock-o:before {
  content: "\F255";
}

.fa-hand-paper-o:before,
.fa-hand-stop-o:before {
  content: "\F256";
}

.fa-hand-scissors-o:before {
  content: "\F257";
}

.fa-hand-lizard-o:before {
  content: "\F258";
}

.fa-hand-spock-o:before {
  content: "\F259";
}

.fa-hand-pointer-o:before {
  content: "\F25A";
}

.fa-hand-peace-o:before {
  content: "\F25B";
}

.fa-trademark:before {
  content: "\F25C";
}

.fa-registered:before {
  content: "\F25D";
}

.fa-creative-commons:before {
  content: "\F25E";
}

.fa-gg:before {
  content: "\F260";
}

.fa-gg-circle:before {
  content: "\F261";
}

.fa-tripadvisor:before {
  content: "\F262";
}

.fa-odnoklassniki:before {
  content: "\F263";
}

.fa-odnoklassniki-square:before {
  content: "\F264";
}

.fa-get-pocket:before {
  content: "\F265";
}

.fa-wikipedia-w:before {
  content: "\F266";
}

.fa-safari:before {
  content: "\F267";
}

.fa-chrome:before {
  content: "\F268";
}

.fa-firefox:before {
  content: "\F269";
}

.fa-opera:before {
  content: "\F26A";
}

.fa-internet-explorer:before {
  content: "\F26B";
}

.fa-television:before,
.fa-tv:before {
  content: "\F26C";
}

.fa-contao:before {
  content: "\F26D";
}

.fa-500px:before {
  content: "\F26E";
}

.fa-amazon:before {
  content: "\F270";
}

.fa-calendar-plus-o:before {
  content: "\F271";
}

.fa-calendar-minus-o:before {
  content: "\F272";
}

.fa-calendar-times-o:before {
  content: "\F273";
}

.fa-calendar-check-o:before {
  content: "\F274";
}

.fa-industry:before {
  content: "\F275";
}

.fa-map-pin:before {
  content: "\F276";
}

.fa-map-signs:before {
  content: "\F277";
}

.fa-map-o:before {
  content: "\F278";
}

.fa-map:before {
  content: "\F279";
}

.fa-commenting:before {
  content: "\F27A";
}

.fa-commenting-o:before {
  content: "\F27B";
}

.fa-houzz:before {
  content: "\F27C";
}

.fa-vimeo:before {
  content: "\F27D";
}

.fa-black-tie:before {
  content: "\F27E";
}

.fa-fonticons:before {
  content: "\F280";
}

.fa-reddit-alien:before {
  content: "\F281";
}

.fa-edge:before {
  content: "\F282";
}

.fa-credit-card-alt:before {
  content: "\F283";
}

.fa-codiepie:before {
  content: "\F284";
}

.fa-modx:before {
  content: "\F285";
}

.fa-fort-awesome:before {
  content: "\F286";
}

.fa-usb:before {
  content: "\F287";
}

.fa-product-hunt:before {
  content: "\F288";
}

.fa-mixcloud:before {
  content: "\F289";
}

.fa-scribd:before {
  content: "\F28A";
}

.fa-pause-circle:before {
  content: "\F28B";
}

.fa-pause-circle-o:before {
  content: "\F28C";
}

.fa-stop-circle:before {
  content: "\F28D";
}

.fa-stop-circle-o:before {
  content: "\F28E";
}

.fa-shopping-bag:before {
  content: "\F290";
}

.fa-shopping-basket:before {
  content: "\F291";
}

.fa-hashtag:before {
  content: "\F292";
}

.fa-bluetooth:before {
  content: "\F293";
}

.fa-bluetooth-b:before {
  content: "\F294";
}

.fa-percent:before {
  content: "\F295";
}

.fa-gitlab:before {
  content: "\F296";
}

.fa-wpbeginner:before {
  content: "\F297";
}

.fa-wpforms:before {
  content: "\F298";
}

.fa-envira:before {
  content: "\F299";
}

.fa-universal-access:before {
  content: "\F29A";
}

.fa-wheelchair-alt:before {
  content: "\F29B";
}

.fa-question-circle-o:before {
  content: "\F29C";
}

.fa-blind:before {
  content: "\F29D";
}

.fa-audio-description:before {
  content: "\F29E";
}

.fa-volume-control-phone:before {
  content: "\F2A0";
}

.fa-braille:before {
  content: "\F2A1";
}

.fa-assistive-listening-systems:before {
  content: "\F2A2";
}

.fa-american-sign-language-interpreting:before,
.fa-asl-interpreting:before {
  content: "\F2A3";
}

.fa-deaf:before,
.fa-deafness:before,
.fa-hard-of-hearing:before {
  content: "\F2A4";
}

.fa-glide:before {
  content: "\F2A5";
}

.fa-glide-g:before {
  content: "\F2A6";
}

.fa-sign-language:before,
.fa-signing:before {
  content: "\F2A7";
}

.fa-low-vision:before {
  content: "\F2A8";
}

.fa-viadeo:before {
  content: "\F2A9";
}

.fa-viadeo-square:before {
  content: "\F2AA";
}

.fa-snapchat:before {
  content: "\F2AB";
}

.fa-snapchat-ghost:before {
  content: "\F2AC";
}

.fa-snapchat-square:before {
  content: "\F2AD";
}

.fa-pied-piper:before {
  content: "\F2AE";
}

.fa-first-order:before {
  content: "\F2B0";
}

.fa-yoast:before {
  content: "\F2B1";
}

.fa-themeisle:before {
  content: "\F2B2";
}

.fa-google-plus-circle:before,
.fa-google-plus-official:before {
  content: "\F2B3";
}

.fa-fa:before,
.fa-font-awesome:before {
  content: "\F2B4";
}

.fa-handshake-o:before {
  content: "\F2B5";
}

.fa-envelope-open:before {
  content: "\F2B6";
}

.fa-envelope-open-o:before {
  content: "\F2B7";
}

.fa-linode:before {
  content: "\F2B8";
}

.fa-address-book:before {
  content: "\F2B9";
}

.fa-address-book-o:before {
  content: "\F2BA";
}

.fa-address-card:before,
.fa-vcard:before {
  content: "\F2BB";
}

.fa-address-card-o:before,
.fa-vcard-o:before {
  content: "\F2BC";
}

.fa-user-circle:before {
  content: "\F2BD";
}

.fa-user-circle-o:before {
  content: "\F2BE";
}

.fa-user-o:before {
  content: "\F2C0";
}

.fa-id-badge:before {
  content: "\F2C1";
}

.fa-drivers-license:before,
.fa-id-card:before {
  content: "\F2C2";
}

.fa-drivers-license-o:before,
.fa-id-card-o:before {
  content: "\F2C3";
}

.fa-quora:before {
  content: "\F2C4";
}

.fa-free-code-camp:before {
  content: "\F2C5";
}

.fa-telegram:before {
  content: "\F2C6";
}

.fa-thermometer-4:before,
.fa-thermometer-full:before,
.fa-thermometer:before {
  content: "\F2C7";
}

.fa-thermometer-3:before,
.fa-thermometer-three-quarters:before {
  content: "\F2C8";
}

.fa-thermometer-2:before,
.fa-thermometer-half:before {
  content: "\F2C9";
}

.fa-thermometer-1:before,
.fa-thermometer-quarter:before {
  content: "\F2CA";
}

.fa-thermometer-0:before,
.fa-thermometer-empty:before {
  content: "\F2CB";
}

.fa-shower:before {
  content: "\F2CC";
}

.fa-bath:before,
.fa-bathtub:before,
.fa-s15:before {
  content: "\F2CD";
}

.fa-podcast:before {
  content: "\F2CE";
}

.fa-window-maximize:before {
  content: "\F2D0";
}

.fa-window-minimize:before {
  content: "\F2D1";
}

.fa-window-restore:before {
  content: "\F2D2";
}

.fa-times-rectangle:before,
.fa-window-close:before {
  content: "\F2D3";
}

.fa-times-rectangle-o:before,
.fa-window-close-o:before {
  content: "\F2D4";
}

.fa-bandcamp:before {
  content: "\F2D5";
}

.fa-grav:before {
  content: "\F2D6";
}

.fa-etsy:before {
  content: "\F2D7";
}

.fa-imdb:before {
  content: "\F2D8";
}

.fa-ravelry:before {
  content: "\F2D9";
}

.fa-eercast:before {
  content: "\F2DA";
}

.fa-microchip:before {
  content: "\F2DB";
}

.fa-snowflake-o:before {
  content: "\F2DC";
}

.fa-superpowers:before {
  content: "\F2DD";
}

.fa-wpexplorer:before {
  content: "\F2DE";
}

.fa-meetup:before {
  content: "\F2E0";
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

.flag-icon,
.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.flag-icon {
  position: relative;
  display: inline-block;
  width: 1.33333em;
  line-height: 1em;
}

.flag-icon:before {
  content: "\A0";
}

.flag-icon.flag-icon-squared {
  width: 1em;
}

.flag-icon-ad {
  background-image: url(../fonts/flags//4x3/ad.svg);
}

.flag-icon-ad.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ad.svg);
}

.flag-icon-ae {
  background-image: url(../fonts/flags//4x3/ae.svg);
}

.flag-icon-ae.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ae.svg);
}

.flag-icon-af {
  background-image: url(../fonts/flags//4x3/af.svg);
}

.flag-icon-af.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/af.svg);
}

.flag-icon-ag {
  background-image: url(../fonts/flags//4x3/ag.svg);
}

.flag-icon-ag.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ag.svg);
}

.flag-icon-ai {
  background-image: url(../fonts/flags//4x3/ai.svg);
}

.flag-icon-ai.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ai.svg);
}

.flag-icon-al {
  background-image: url(../fonts/flags//4x3/al.svg);
}

.flag-icon-al.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/al.svg);
}

.flag-icon-am {
  background-image: url(../fonts/flags//4x3/am.svg);
}

.flag-icon-am.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/am.svg);
}

.flag-icon-ao {
  background-image: url(../fonts/flags//4x3/ao.svg);
}

.flag-icon-ao.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ao.svg);
}

.flag-icon-aq {
  background-image: url(../fonts/flags//4x3/aq.svg);
}

.flag-icon-aq.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/aq.svg);
}

.flag-icon-ar {
  background-image: url(../fonts/flags//4x3/ar.svg);
}

.flag-icon-ar.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ar.svg);
}

.flag-icon-as {
  background-image: url(../fonts/flags//4x3/as.svg);
}

.flag-icon-as.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/as.svg);
}

.flag-icon-at {
  background-image: url(../fonts/flags//4x3/at.svg);
}

.flag-icon-at.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/at.svg);
}

.flag-icon-au {
  background-image: url(../fonts/flags//4x3/au.svg);
}

.flag-icon-au.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/au.svg);
}

.flag-icon-aw {
  background-image: url(../fonts/flags//4x3/aw.svg);
}

.flag-icon-aw.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/aw.svg);
}

.flag-icon-ax {
  background-image: url(../fonts/flags//4x3/ax.svg);
}

.flag-icon-ax.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ax.svg);
}

.flag-icon-az {
  background-image: url(../fonts/flags//4x3/az.svg);
}

.flag-icon-az.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/az.svg);
}

.flag-icon-ba {
  background-image: url(../fonts/flags//4x3/ba.svg);
}

.flag-icon-ba.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ba.svg);
}

.flag-icon-bb {
  background-image: url(../fonts/flags//4x3/bb.svg);
}

.flag-icon-bb.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/bb.svg);
}

.flag-icon-bd {
  background-image: url(../fonts/flags//4x3/bd.svg);
}

.flag-icon-bd.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/bd.svg);
}

.flag-icon-be {
  background-image: url(../fonts/flags//4x3/be.svg);
}

.flag-icon-be.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/be.svg);
}

.flag-icon-bf {
  background-image: url(../fonts/flags//4x3/bf.svg);
}

.flag-icon-bf.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/bf.svg);
}

.flag-icon-bg {
  background-image: url(../fonts/flags//4x3/bg.svg);
}

.flag-icon-bg.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/bg.svg);
}

.flag-icon-bh {
  background-image: url(../fonts/flags//4x3/bh.svg);
}

.flag-icon-bh.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/bh.svg);
}

.flag-icon-bi {
  background-image: url(../fonts/flags//4x3/bi.svg);
}

.flag-icon-bi.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/bi.svg);
}

.flag-icon-bj {
  background-image: url(../fonts/flags//4x3/bj.svg);
}

.flag-icon-bj.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/bj.svg);
}

.flag-icon-bl {
  background-image: url(../fonts/flags//4x3/bl.svg);
}

.flag-icon-bl.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/bl.svg);
}

.flag-icon-bm {
  background-image: url(../fonts/flags//4x3/bm.svg);
}

.flag-icon-bm.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/bm.svg);
}

.flag-icon-bn {
  background-image: url(../fonts/flags//4x3/bn.svg);
}

.flag-icon-bn.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/bn.svg);
}

.flag-icon-bo {
  background-image: url(../fonts/flags//4x3/bo.svg);
}

.flag-icon-bo.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/bo.svg);
}

.flag-icon-bq {
  background-image: url(../fonts/flags//4x3/bq.svg);
}

.flag-icon-bq.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/bq.svg);
}

.flag-icon-br {
  background-image: url(../fonts/flags//4x3/br.svg);
}

.flag-icon-br.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/br.svg);
}

.flag-icon-bs {
  background-image: url(../fonts/flags//4x3/bs.svg);
}

.flag-icon-bs.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/bs.svg);
}

.flag-icon-bt {
  background-image: url(../fonts/flags//4x3/bt.svg);
}

.flag-icon-bt.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/bt.svg);
}

.flag-icon-bv {
  background-image: url(../fonts/flags//4x3/bv.svg);
}

.flag-icon-bv.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/bv.svg);
}

.flag-icon-bw {
  background-image: url(../fonts/flags//4x3/bw.svg);
}

.flag-icon-bw.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/bw.svg);
}

.flag-icon-by {
  background-image: url(../fonts/flags//4x3/by.svg);
}

.flag-icon-by.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/by.svg);
}

.flag-icon-bz {
  background-image: url(../fonts/flags//4x3/bz.svg);
}

.flag-icon-bz.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/bz.svg);
}

.flag-icon-ca {
  background-image: url(../fonts/flags//4x3/ca.svg);
}

.flag-icon-ca.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ca.svg);
}

.flag-icon-cc {
  background-image: url(../fonts/flags//4x3/cc.svg);
}

.flag-icon-cc.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/cc.svg);
}

.flag-icon-cd {
  background-image: url(../fonts/flags//4x3/cd.svg);
}

.flag-icon-cd.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/cd.svg);
}

.flag-icon-cf {
  background-image: url(../fonts/flags//4x3/cf.svg);
}

.flag-icon-cf.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/cf.svg);
}

.flag-icon-cg {
  background-image: url(../fonts/flags//4x3/cg.svg);
}

.flag-icon-cg.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/cg.svg);
}

.flag-icon-ch {
  background-image: url(../fonts/flags//4x3/ch.svg);
}

.flag-icon-ch.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ch.svg);
}

.flag-icon-ci {
  background-image: url(../fonts/flags//4x3/ci.svg);
}

.flag-icon-ci.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ci.svg);
}

.flag-icon-ck {
  background-image: url(../fonts/flags//4x3/ck.svg);
}

.flag-icon-ck.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ck.svg);
}

.flag-icon-cl {
  background-image: url(../fonts/flags//4x3/cl.svg);
}

.flag-icon-cl.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/cl.svg);
}

.flag-icon-cm {
  background-image: url(../fonts/flags//4x3/cm.svg);
}

.flag-icon-cm.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/cm.svg);
}

.flag-icon-cn {
  background-image: url(../fonts/flags//4x3/cn.svg);
}

.flag-icon-cn.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/cn.svg);
}

.flag-icon-co {
  background-image: url(../fonts/flags//4x3/co.svg);
}

.flag-icon-co.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/co.svg);
}

.flag-icon-cr {
  background-image: url(../fonts/flags//4x3/cr.svg);
}

.flag-icon-cr.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/cr.svg);
}

.flag-icon-cu {
  background-image: url(../fonts/flags//4x3/cu.svg);
}

.flag-icon-cu.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/cu.svg);
}

.flag-icon-cv {
  background-image: url(../fonts/flags//4x3/cv.svg);
}

.flag-icon-cv.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/cv.svg);
}

.flag-icon-cw {
  background-image: url(../fonts/flags//4x3/cw.svg);
}

.flag-icon-cw.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/cw.svg);
}

.flag-icon-cx {
  background-image: url(../fonts/flags//4x3/cx.svg);
}

.flag-icon-cx.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/cx.svg);
}

.flag-icon-cy {
  background-image: url(../fonts/flags//4x3/cy.svg);
}

.flag-icon-cy.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/cy.svg);
}

.flag-icon-cz {
  background-image: url(../fonts/flags//4x3/cz.svg);
}

.flag-icon-cz.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/cz.svg);
}

.flag-icon-de {
  background-image: url(../fonts/flags//4x3/de.svg);
}

.flag-icon-de.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/de.svg);
}

.flag-icon-dj {
  background-image: url(../fonts/flags//4x3/dj.svg);
}

.flag-icon-dj.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/dj.svg);
}

.flag-icon-dk {
  background-image: url(../fonts/flags//4x3/dk.svg);
}

.flag-icon-dk.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/dk.svg);
}

.flag-icon-dm {
  background-image: url(../fonts/flags//4x3/dm.svg);
}

.flag-icon-dm.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/dm.svg);
}

.flag-icon-do {
  background-image: url(../fonts/flags//4x3/do.svg);
}

.flag-icon-do.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/do.svg);
}

.flag-icon-dz {
  background-image: url(../fonts/flags//4x3/dz.svg);
}

.flag-icon-dz.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/dz.svg);
}

.flag-icon-ec {
  background-image: url(../fonts/flags//4x3/ec.svg);
}

.flag-icon-ec.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ec.svg);
}

.flag-icon-ee {
  background-image: url(../fonts/flags//4x3/ee.svg);
}

.flag-icon-ee.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ee.svg);
}

.flag-icon-eg {
  background-image: url(../fonts/flags//4x3/eg.svg);
}

.flag-icon-eg.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/eg.svg);
}

.flag-icon-eh {
  background-image: url(../fonts/flags//4x3/eh.svg);
}

.flag-icon-eh.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/eh.svg);
}

.flag-icon-er {
  background-image: url(../fonts/flags//4x3/er.svg);
}

.flag-icon-er.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/er.svg);
}

.flag-icon-es {
  background-image: url(../fonts/flags//4x3/es.svg);
}

.flag-icon-es.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/es.svg);
}

.flag-icon-et {
  background-image: url(../fonts/flags//4x3/et.svg);
}

.flag-icon-et.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/et.svg);
}

.flag-icon-fi {
  background-image: url(../fonts/flags//4x3/fi.svg);
}

.flag-icon-fi.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/fi.svg);
}

.flag-icon-fj {
  background-image: url(../fonts/flags//4x3/fj.svg);
}

.flag-icon-fj.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/fj.svg);
}

.flag-icon-fk {
  background-image: url(../fonts/flags//4x3/fk.svg);
}

.flag-icon-fk.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/fk.svg);
}

.flag-icon-fm {
  background-image: url(../fonts/flags//4x3/fm.svg);
}

.flag-icon-fm.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/fm.svg);
}

.flag-icon-fo {
  background-image: url(../fonts/flags//4x3/fo.svg);
}

.flag-icon-fo.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/fo.svg);
}

.flag-icon-fr {
  background-image: url(../fonts/flags//4x3/fr.svg);
}

.flag-icon-fr.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/fr.svg);
}

.flag-icon-ga {
  background-image: url(../fonts/flags//4x3/ga.svg);
}

.flag-icon-ga.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ga.svg);
}

.flag-icon-gb {
  background-image: url(../fonts/flags//4x3/gb.svg);
}

.flag-icon-gb.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/gb.svg);
}

.flag-icon-gd {
  background-image: url(../fonts/flags//4x3/gd.svg);
}

.flag-icon-gd.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/gd.svg);
}

.flag-icon-ge {
  background-image: url(../fonts/flags//4x3/ge.svg);
}

.flag-icon-ge.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ge.svg);
}

.flag-icon-gf {
  background-image: url(../fonts/flags//4x3/gf.svg);
}

.flag-icon-gf.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/gf.svg);
}

.flag-icon-gg {
  background-image: url(../fonts/flags//4x3/gg.svg);
}

.flag-icon-gg.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/gg.svg);
}

.flag-icon-gh {
  background-image: url(../fonts/flags//4x3/gh.svg);
}

.flag-icon-gh.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/gh.svg);
}

.flag-icon-gi {
  background-image: url(../fonts/flags//4x3/gi.svg);
}

.flag-icon-gi.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/gi.svg);
}

.flag-icon-gl {
  background-image: url(../fonts/flags//4x3/gl.svg);
}

.flag-icon-gl.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/gl.svg);
}

.flag-icon-gm {
  background-image: url(../fonts/flags//4x3/gm.svg);
}

.flag-icon-gm.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/gm.svg);
}

.flag-icon-gn {
  background-image: url(../fonts/flags//4x3/gn.svg);
}

.flag-icon-gn.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/gn.svg);
}

.flag-icon-gp {
  background-image: url(../fonts/flags//4x3/gp.svg);
}

.flag-icon-gp.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/gp.svg);
}

.flag-icon-gq {
  background-image: url(../fonts/flags//4x3/gq.svg);
}

.flag-icon-gq.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/gq.svg);
}

.flag-icon-gr {
  background-image: url(../fonts/flags//4x3/gr.svg);
}

.flag-icon-gr.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/gr.svg);
}

.flag-icon-gs {
  background-image: url(../fonts/flags//4x3/gs.svg);
}

.flag-icon-gs.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/gs.svg);
}

.flag-icon-gt {
  background-image: url(../fonts/flags//4x3/gt.svg);
}

.flag-icon-gt.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/gt.svg);
}

.flag-icon-gu {
  background-image: url(../fonts/flags//4x3/gu.svg);
}

.flag-icon-gu.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/gu.svg);
}

.flag-icon-gw {
  background-image: url(../fonts/flags//4x3/gw.svg);
}

.flag-icon-gw.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/gw.svg);
}

.flag-icon-gy {
  background-image: url(../fonts/flags//4x3/gy.svg);
}

.flag-icon-gy.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/gy.svg);
}

.flag-icon-hk {
  background-image: url(../fonts/flags//4x3/hk.svg);
}

.flag-icon-hk.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/hk.svg);
}

.flag-icon-hm {
  background-image: url(../fonts/flags//4x3/hm.svg);
}

.flag-icon-hm.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/hm.svg);
}

.flag-icon-hn {
  background-image: url(../fonts/flags//4x3/hn.svg);
}

.flag-icon-hn.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/hn.svg);
}

.flag-icon-hr {
  background-image: url(../fonts/flags//4x3/hr.svg);
}

.flag-icon-hr.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/hr.svg);
}

.flag-icon-ht {
  background-image: url(../fonts/flags//4x3/ht.svg);
}

.flag-icon-ht.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ht.svg);
}

.flag-icon-hu {
  background-image: url(../fonts/flags//4x3/hu.svg);
}

.flag-icon-hu.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/hu.svg);
}

.flag-icon-id {
  background-image: url(../fonts/flags//4x3/id.svg);
}

.flag-icon-id.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/id.svg);
}

.flag-icon-ie {
  background-image: url(../fonts/flags//4x3/ie.svg);
}

.flag-icon-ie.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ie.svg);
}

.flag-icon-il {
  background-image: url(../fonts/flags//4x3/il.svg);
}

.flag-icon-il.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/il.svg);
}

.flag-icon-im {
  background-image: url(../fonts/flags//4x3/im.svg);
}

.flag-icon-im.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/im.svg);
}

.flag-icon-in {
  background-image: url(../fonts/flags//4x3/in.svg);
}

.flag-icon-in.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/in.svg);
}

.flag-icon-io {
  background-image: url(../fonts/flags//4x3/io.svg);
}

.flag-icon-io.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/io.svg);
}

.flag-icon-iq {
  background-image: url(../fonts/flags//4x3/iq.svg);
}

.flag-icon-iq.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/iq.svg);
}

.flag-icon-ir {
  background-image: url(../fonts/flags//4x3/ir.svg);
}

.flag-icon-ir.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ir.svg);
}

.flag-icon-is {
  background-image: url(../fonts/flags//4x3/is.svg);
}

.flag-icon-is.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/is.svg);
}

.flag-icon-it {
  background-image: url(../fonts/flags//4x3/it.svg);
}

.flag-icon-it.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/it.svg);
}

.flag-icon-je {
  background-image: url(../fonts/flags//4x3/je.svg);
}

.flag-icon-je.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/je.svg);
}

.flag-icon-jm {
  background-image: url(../fonts/flags//4x3/jm.svg);
}

.flag-icon-jm.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/jm.svg);
}

.flag-icon-jo {
  background-image: url(../fonts/flags//4x3/jo.svg);
}

.flag-icon-jo.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/jo.svg);
}

.flag-icon-jp {
  background-image: url(../fonts/flags//4x3/jp.svg);
}

.flag-icon-jp.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/jp.svg);
}

.flag-icon-ke {
  background-image: url(../fonts/flags//4x3/ke.svg);
}

.flag-icon-ke.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ke.svg);
}

.flag-icon-kg {
  background-image: url(../fonts/flags//4x3/kg.svg);
}

.flag-icon-kg.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/kg.svg);
}

.flag-icon-kh {
  background-image: url(../fonts/flags//4x3/kh.svg);
}

.flag-icon-kh.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/kh.svg);
}

.flag-icon-ki {
  background-image: url(../fonts/flags//4x3/ki.svg);
}

.flag-icon-ki.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ki.svg);
}

.flag-icon-km {
  background-image: url(../fonts/flags//4x3/km.svg);
}

.flag-icon-km.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/km.svg);
}

.flag-icon-kn {
  background-image: url(../fonts/flags//4x3/kn.svg);
}

.flag-icon-kn.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/kn.svg);
}

.flag-icon-kp {
  background-image: url(../fonts/flags//4x3/kp.svg);
}

.flag-icon-kp.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/kp.svg);
}

.flag-icon-kr {
  background-image: url(../fonts/flags//4x3/kr.svg);
}

.flag-icon-kr.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/kr.svg);
}

.flag-icon-kw {
  background-image: url(../fonts/flags//4x3/kw.svg);
}

.flag-icon-kw.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/kw.svg);
}

.flag-icon-ky {
  background-image: url(../fonts/flags//4x3/ky.svg);
}

.flag-icon-ky.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ky.svg);
}

.flag-icon-kz {
  background-image: url(../fonts/flags//4x3/kz.svg);
}

.flag-icon-kz.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/kz.svg);
}

.flag-icon-la {
  background-image: url(../fonts/flags//4x3/la.svg);
}

.flag-icon-la.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/la.svg);
}

.flag-icon-lb {
  background-image: url(../fonts/flags//4x3/lb.svg);
}

.flag-icon-lb.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/lb.svg);
}

.flag-icon-lc {
  background-image: url(../fonts/flags//4x3/lc.svg);
}

.flag-icon-lc.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/lc.svg);
}

.flag-icon-li {
  background-image: url(../fonts/flags//4x3/li.svg);
}

.flag-icon-li.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/li.svg);
}

.flag-icon-lk {
  background-image: url(../fonts/flags//4x3/lk.svg);
}

.flag-icon-lk.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/lk.svg);
}

.flag-icon-lr {
  background-image: url(../fonts/flags//4x3/lr.svg);
}

.flag-icon-lr.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/lr.svg);
}

.flag-icon-ls {
  background-image: url(../fonts/flags//4x3/ls.svg);
}

.flag-icon-ls.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ls.svg);
}

.flag-icon-lt {
  background-image: url(../fonts/flags//4x3/lt.svg);
}

.flag-icon-lt.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/lt.svg);
}

.flag-icon-lu {
  background-image: url(../fonts/flags//4x3/lu.svg);
}

.flag-icon-lu.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/lu.svg);
}

.flag-icon-lv {
  background-image: url(../fonts/flags//4x3/lv.svg);
}

.flag-icon-lv.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/lv.svg);
}

.flag-icon-ly {
  background-image: url(../fonts/flags//4x3/ly.svg);
}

.flag-icon-ly.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ly.svg);
}

.flag-icon-ma {
  background-image: url(../fonts/flags//4x3/ma.svg);
}

.flag-icon-ma.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ma.svg);
}

.flag-icon-mc {
  background-image: url(../fonts/flags//4x3/mc.svg);
}

.flag-icon-mc.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/mc.svg);
}

.flag-icon-md {
  background-image: url(../fonts/flags//4x3/md.svg);
}

.flag-icon-md.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/md.svg);
}

.flag-icon-me {
  background-image: url(../fonts/flags//4x3/me.svg);
}

.flag-icon-me.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/me.svg);
}

.flag-icon-mf {
  background-image: url(../fonts/flags//4x3/mf.svg);
}

.flag-icon-mf.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/mf.svg);
}

.flag-icon-mg {
  background-image: url(../fonts/flags//4x3/mg.svg);
}

.flag-icon-mg.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/mg.svg);
}

.flag-icon-mh {
  background-image: url(../fonts/flags//4x3/mh.svg);
}

.flag-icon-mh.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/mh.svg);
}

.flag-icon-mk {
  background-image: url(../fonts/flags//4x3/mk.svg);
}

.flag-icon-mk.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/mk.svg);
}

.flag-icon-ml {
  background-image: url(../fonts/flags//4x3/ml.svg);
}

.flag-icon-ml.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ml.svg);
}

.flag-icon-mm {
  background-image: url(../fonts/flags//4x3/mm.svg);
}

.flag-icon-mm.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/mm.svg);
}

.flag-icon-mn {
  background-image: url(../fonts/flags//4x3/mn.svg);
}

.flag-icon-mn.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/mn.svg);
}

.flag-icon-mo {
  background-image: url(../fonts/flags//4x3/mo.svg);
}

.flag-icon-mo.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/mo.svg);
}

.flag-icon-mp {
  background-image: url(../fonts/flags//4x3/mp.svg);
}

.flag-icon-mp.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/mp.svg);
}

.flag-icon-mq {
  background-image: url(../fonts/flags//4x3/mq.svg);
}

.flag-icon-mq.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/mq.svg);
}

.flag-icon-mr {
  background-image: url(../fonts/flags//4x3/mr.svg);
}

.flag-icon-mr.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/mr.svg);
}

.flag-icon-ms {
  background-image: url(../fonts/flags//4x3/ms.svg);
}

.flag-icon-ms.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ms.svg);
}

.flag-icon-mt {
  background-image: url(../fonts/flags//4x3/mt.svg);
}

.flag-icon-mt.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/mt.svg);
}

.flag-icon-mu {
  background-image: url(../fonts/flags//4x3/mu.svg);
}

.flag-icon-mu.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/mu.svg);
}

.flag-icon-mv {
  background-image: url(../fonts/flags//4x3/mv.svg);
}

.flag-icon-mv.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/mv.svg);
}

.flag-icon-mw {
  background-image: url(../fonts/flags//4x3/mw.svg);
}

.flag-icon-mw.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/mw.svg);
}

.flag-icon-mx {
  background-image: url(../fonts/flags//4x3/mx.svg);
}

.flag-icon-mx.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/mx.svg);
}

.flag-icon-my {
  background-image: url(../fonts/flags//4x3/my.svg);
}

.flag-icon-my.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/my.svg);
}

.flag-icon-mz {
  background-image: url(../fonts/flags//4x3/mz.svg);
}

.flag-icon-mz.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/mz.svg);
}

.flag-icon-na {
  background-image: url(../fonts/flags//4x3/na.svg);
}

.flag-icon-na.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/na.svg);
}

.flag-icon-nc {
  background-image: url(../fonts/flags//4x3/nc.svg);
}

.flag-icon-nc.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/nc.svg);
}

.flag-icon-ne {
  background-image: url(../fonts/flags//4x3/ne.svg);
}

.flag-icon-ne.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ne.svg);
}

.flag-icon-nf {
  background-image: url(../fonts/flags//4x3/nf.svg);
}

.flag-icon-nf.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/nf.svg);
}

.flag-icon-ng {
  background-image: url(../fonts/flags//4x3/ng.svg);
}

.flag-icon-ng.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ng.svg);
}

.flag-icon-ni {
  background-image: url(../fonts/flags//4x3/ni.svg);
}

.flag-icon-ni.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ni.svg);
}

.flag-icon-nl {
  background-image: url(../fonts/flags//4x3/nl.svg);
}

.flag-icon-nl.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/nl.svg);
}

.flag-icon-no {
  background-image: url(../fonts/flags//4x3/no.svg);
}

.flag-icon-no.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/no.svg);
}

.flag-icon-np {
  background-image: url(../fonts/flags//4x3/np.svg);
}

.flag-icon-np.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/np.svg);
}

.flag-icon-nr {
  background-image: url(../fonts/flags//4x3/nr.svg);
}

.flag-icon-nr.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/nr.svg);
}

.flag-icon-nu {
  background-image: url(../fonts/flags//4x3/nu.svg);
}

.flag-icon-nu.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/nu.svg);
}

.flag-icon-nz {
  background-image: url(../fonts/flags//4x3/nz.svg);
}

.flag-icon-nz.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/nz.svg);
}

.flag-icon-om {
  background-image: url(../fonts/flags//4x3/om.svg);
}

.flag-icon-om.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/om.svg);
}

.flag-icon-pa {
  background-image: url(../fonts/flags//4x3/pa.svg);
}

.flag-icon-pa.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/pa.svg);
}

.flag-icon-pe {
  background-image: url(../fonts/flags//4x3/pe.svg);
}

.flag-icon-pe.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/pe.svg);
}

.flag-icon-pf {
  background-image: url(../fonts/flags//4x3/pf.svg);
}

.flag-icon-pf.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/pf.svg);
}

.flag-icon-pg {
  background-image: url(../fonts/flags//4x3/pg.svg);
}

.flag-icon-pg.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/pg.svg);
}

.flag-icon-ph {
  background-image: url(../fonts/flags//4x3/ph.svg);
}

.flag-icon-ph.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ph.svg);
}

.flag-icon-pk {
  background-image: url(../fonts/flags//4x3/pk.svg);
}

.flag-icon-pk.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/pk.svg);
}

.flag-icon-pl {
  background-image: url(../fonts/flags//4x3/pl.svg);
}

.flag-icon-pl.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/pl.svg);
}

.flag-icon-pm {
  background-image: url(../fonts/flags//4x3/pm.svg);
}

.flag-icon-pm.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/pm.svg);
}

.flag-icon-pn {
  background-image: url(../fonts/flags//4x3/pn.svg);
}

.flag-icon-pn.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/pn.svg);
}

.flag-icon-pr {
  background-image: url(../fonts/flags//4x3/pr.svg);
}

.flag-icon-pr.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/pr.svg);
}

.flag-icon-ps {
  background-image: url(../fonts/flags//4x3/ps.svg);
}

.flag-icon-ps.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ps.svg);
}

.flag-icon-pt {
  background-image: url(../fonts/flags//4x3/pt.svg);
}

.flag-icon-pt.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/pt.svg);
}

.flag-icon-pw {
  background-image: url(../fonts/flags//4x3/pw.svg);
}

.flag-icon-pw.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/pw.svg);
}

.flag-icon-py {
  background-image: url(../fonts/flags//4x3/py.svg);
}

.flag-icon-py.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/py.svg);
}

.flag-icon-qa {
  background-image: url(../fonts/flags//4x3/qa.svg);
}

.flag-icon-qa.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/qa.svg);
}

.flag-icon-re {
  background-image: url(../fonts/flags//4x3/re.svg);
}

.flag-icon-re.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/re.svg);
}

.flag-icon-ro {
  background-image: url(../fonts/flags//4x3/ro.svg);
}

.flag-icon-ro.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ro.svg);
}

.flag-icon-rs {
  background-image: url(../fonts/flags//4x3/rs.svg);
}

.flag-icon-rs.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/rs.svg);
}

.flag-icon-ru {
  background-image: url(../fonts/flags//4x3/ru.svg);
}

.flag-icon-ru.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ru.svg);
}

.flag-icon-rw {
  background-image: url(../fonts/flags//4x3/rw.svg);
}

.flag-icon-rw.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/rw.svg);
}

.flag-icon-sa {
  background-image: url(../fonts/flags//4x3/sa.svg);
}

.flag-icon-sa.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/sa.svg);
}

.flag-icon-sb {
  background-image: url(../fonts/flags//4x3/sb.svg);
}

.flag-icon-sb.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/sb.svg);
}

.flag-icon-sc {
  background-image: url(../fonts/flags//4x3/sc.svg);
}

.flag-icon-sc.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/sc.svg);
}

.flag-icon-sd {
  background-image: url(../fonts/flags//4x3/sd.svg);
}

.flag-icon-sd.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/sd.svg);
}

.flag-icon-se {
  background-image: url(../fonts/flags//4x3/se.svg);
}

.flag-icon-se.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/se.svg);
}

.flag-icon-sg {
  background-image: url(../fonts/flags//4x3/sg.svg);
}

.flag-icon-sg.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/sg.svg);
}

.flag-icon-sh {
  background-image: url(../fonts/flags//4x3/sh.svg);
}

.flag-icon-sh.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/sh.svg);
}

.flag-icon-si {
  background-image: url(../fonts/flags//4x3/si.svg);
}

.flag-icon-si.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/si.svg);
}

.flag-icon-sj {
  background-image: url(../fonts/flags//4x3/sj.svg);
}

.flag-icon-sj.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/sj.svg);
}

.flag-icon-sk {
  background-image: url(../fonts/flags//4x3/sk.svg);
}

.flag-icon-sk.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/sk.svg);
}

.flag-icon-sl {
  background-image: url(../fonts/flags//4x3/sl.svg);
}

.flag-icon-sl.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/sl.svg);
}

.flag-icon-sm {
  background-image: url(../fonts/flags//4x3/sm.svg);
}

.flag-icon-sm.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/sm.svg);
}

.flag-icon-sn {
  background-image: url(../fonts/flags//4x3/sn.svg);
}

.flag-icon-sn.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/sn.svg);
}

.flag-icon-so {
  background-image: url(../fonts/flags//4x3/so.svg);
}

.flag-icon-so.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/so.svg);
}

.flag-icon-sr {
  background-image: url(../fonts/flags//4x3/sr.svg);
}

.flag-icon-sr.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/sr.svg);
}

.flag-icon-ss {
  background-image: url(../fonts/flags//4x3/ss.svg);
}

.flag-icon-ss.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ss.svg);
}

.flag-icon-st {
  background-image: url(../fonts/flags//4x3/st.svg);
}

.flag-icon-st.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/st.svg);
}

.flag-icon-sv {
  background-image: url(../fonts/flags//4x3/sv.svg);
}

.flag-icon-sv.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/sv.svg);
}

.flag-icon-sx {
  background-image: url(../fonts/flags//4x3/sx.svg);
}

.flag-icon-sx.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/sx.svg);
}

.flag-icon-sy {
  background-image: url(../fonts/flags//4x3/sy.svg);
}

.flag-icon-sy.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/sy.svg);
}

.flag-icon-sz {
  background-image: url(../fonts/flags//4x3/sz.svg);
}

.flag-icon-sz.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/sz.svg);
}

.flag-icon-tc {
  background-image: url(../fonts/flags//4x3/tc.svg);
}

.flag-icon-tc.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/tc.svg);
}

.flag-icon-td {
  background-image: url(../fonts/flags//4x3/td.svg);
}

.flag-icon-td.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/td.svg);
}

.flag-icon-tf {
  background-image: url(../fonts/flags//4x3/tf.svg);
}

.flag-icon-tf.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/tf.svg);
}

.flag-icon-tg {
  background-image: url(../fonts/flags//4x3/tg.svg);
}

.flag-icon-tg.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/tg.svg);
}

.flag-icon-th {
  background-image: url(../fonts/flags//4x3/th.svg);
}

.flag-icon-th.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/th.svg);
}

.flag-icon-tj {
  background-image: url(../fonts/flags//4x3/tj.svg);
}

.flag-icon-tj.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/tj.svg);
}

.flag-icon-tk {
  background-image: url(../fonts/flags//4x3/tk.svg);
}

.flag-icon-tk.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/tk.svg);
}

.flag-icon-tl {
  background-image: url(../fonts/flags//4x3/tl.svg);
}

.flag-icon-tl.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/tl.svg);
}

.flag-icon-tm {
  background-image: url(../fonts/flags//4x3/tm.svg);
}

.flag-icon-tm.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/tm.svg);
}

.flag-icon-tn {
  background-image: url(../fonts/flags//4x3/tn.svg);
}

.flag-icon-tn.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/tn.svg);
}

.flag-icon-to {
  background-image: url(../fonts/flags//4x3/to.svg);
}

.flag-icon-to.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/to.svg);
}

.flag-icon-tr {
  background-image: url(../fonts/flags//4x3/tr.svg);
}

.flag-icon-tr.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/tr.svg);
}

.flag-icon-tt {
  background-image: url(../fonts/flags//4x3/tt.svg);
}

.flag-icon-tt.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/tt.svg);
}

.flag-icon-tv {
  background-image: url(../fonts/flags//4x3/tv.svg);
}

.flag-icon-tv.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/tv.svg);
}

.flag-icon-tw {
  background-image: url(../fonts/flags//4x3/tw.svg);
}

.flag-icon-tw.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/tw.svg);
}

.flag-icon-tz {
  background-image: url(../fonts/flags//4x3/tz.svg);
}

.flag-icon-tz.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/tz.svg);
}

.flag-icon-ua {
  background-image: url(../fonts/flags//4x3/ua.svg);
}

.flag-icon-ua.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ua.svg);
}

.flag-icon-ug {
  background-image: url(../fonts/flags//4x3/ug.svg);
}

.flag-icon-ug.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ug.svg);
}

.flag-icon-um {
  background-image: url(../fonts/flags//4x3/um.svg);
}

.flag-icon-um.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/um.svg);
}

.flag-icon-us {
  background-image: url(../fonts/flags//4x3/us.svg);
}

.flag-icon-us.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/us.svg);
}

.flag-icon-uy {
  background-image: url(../fonts/flags//4x3/uy.svg);
}

.flag-icon-uy.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/uy.svg);
}

.flag-icon-uz {
  background-image: url(../fonts/flags//4x3/uz.svg);
}

.flag-icon-uz.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/uz.svg);
}

.flag-icon-va {
  background-image: url(../fonts/flags//4x3/va.svg);
}

.flag-icon-va.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/va.svg);
}

.flag-icon-vc {
  background-image: url(../fonts/flags//4x3/vc.svg);
}

.flag-icon-vc.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/vc.svg);
}

.flag-icon-ve {
  background-image: url(../fonts/flags//4x3/ve.svg);
}

.flag-icon-ve.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ve.svg);
}

.flag-icon-vg {
  background-image: url(../fonts/flags//4x3/vg.svg);
}

.flag-icon-vg.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/vg.svg);
}

.flag-icon-vi {
  background-image: url(../fonts/flags//4x3/vi.svg);
}

.flag-icon-vi.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/vi.svg);
}

.flag-icon-vn {
  background-image: url(../fonts/flags//4x3/vn.svg);
}

.flag-icon-vn.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/vn.svg);
}

.flag-icon-vu {
  background-image: url(../fonts/flags//4x3/vu.svg);
}

.flag-icon-vu.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/vu.svg);
}

.flag-icon-wf {
  background-image: url(../fonts/flags//4x3/wf.svg);
}

.flag-icon-wf.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/wf.svg);
}

.flag-icon-ws {
  background-image: url(../fonts/flags//4x3/ws.svg);
}

.flag-icon-ws.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ws.svg);
}

.flag-icon-ye {
  background-image: url(../fonts/flags//4x3/ye.svg);
}

.flag-icon-ye.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/ye.svg);
}

.flag-icon-yt {
  background-image: url(../fonts/flags//4x3/yt.svg);
}

.flag-icon-yt.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/yt.svg);
}

.flag-icon-za {
  background-image: url(../fonts/flags//4x3/za.svg);
}

.flag-icon-za.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/za.svg);
}

.flag-icon-zm {
  background-image: url(../fonts/flags//4x3/zm.svg);
}

.flag-icon-zm.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/zm.svg);
}

.flag-icon-zw {
  background-image: url(../fonts/flags//4x3/zw.svg);
}

.flag-icon-zw.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/zw.svg);
}

.flag-icon-es-ct {
  background-image: url(../fonts/flags//4x3/es-ct.svg);
}

.flag-icon-es-ct.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/es-ct.svg);
}

.flag-icon-eu {
  background-image: url(../fonts/flags//4x3/eu.svg);
}

.flag-icon-eu.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/eu.svg);
}

.flag-icon-gb-eng {
  background-image: url(../fonts/flags//4x3/gb-eng.svg);
}

.flag-icon-gb-eng.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/gb-eng.svg);
}

.flag-icon-gb-nir {
  background-image: url(../fonts/flags//4x3/gb-nir.svg);
}

.flag-icon-gb-nir.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/gb-nir.svg);
}

.flag-icon-gb-sct {
  background-image: url(../fonts/flags//4x3/gb-sct.svg);
}

.flag-icon-gb-sct.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/gb-sct.svg);
}

.flag-icon-gb-wls {
  background-image: url(../fonts/flags//4x3/gb-wls.svg);
}

.flag-icon-gb-wls.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/gb-wls.svg);
}

.flag-icon-un {
  background-image: url(../fonts/flags//4x3/un.svg);
}

.flag-icon-un.flag-icon-squared {
  background-image: url(../fonts/flags//1x1/un.svg);
}

.modal-background {
  background-color: #000;
  display: none;
  height: 100%;
  position: fixed;
  opacity: 0.5;
  width: 100%;
  top: 0;
  left: 0;
}

input[placeholder] {
  text-overflow: ellipsis;
}

header~#maincontent .container a:not(.btn-primary):not(.btn-outline-primary) {
  color: var(--skin-link-color-2);
}

.hide-order-discount,
.hide-shipping-discount {
  display: none;
}

.order-discount,
.shipping-discount {
  color: #008827;
}

.error-messaging {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.error-hero {
  background-image: url("../images/storelocator.jpg");
  margin-bottom: 0.625em;
}

.error-message {
  margin-top: 1.5rem;
  margin-bottom: 3.125rem;
}

.error.continue-shopping {
  margin-bottom: 6.25em;
}

.error-unassigned-category {
  color: #c00;
}

.skip {
  position: absolute;
  top: -4.2em;
  overflow: hidden;
  padding: 1em 1.5em;
}

.skip,
a.skip:active,
a.skip:focus,
a.skip:hover {
  left: 0;
  background: #fff;
  transition: all 0.2s ease-in-out;
}

a.skip:active,
a.skip:focus,
a.skip:hover {
  top: 0;
  width: auto;
  height: auto;
  z-index: 10000000;
}

.card-header-custom {
  font-size: 1.5rem;
  margin-bottom: 0;
}

@media (max-width: 543.98px) {
  .menu-toggleable-left.navbar-toggleable-xs {
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block;
    max-width: 100%;
  }

  .menu-toggleable-left.navbar-toggleable-xs.in {
    min-width: 50%;
    left: 0;
  }
}

@media (max-width: 768.98px) {
  .menu-toggleable-left.navbar-toggleable-sm {
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block;
    max-width: 100%;
  }

  .menu-toggleable-left.navbar-toggleable-sm.in {
    min-width: 50%;
    left: 0;
  }
}

@media (max-width: 991.98px) {
  .menu-toggleable-left.navbar-toggleable-md {
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block;
    max-width: 100%;
  }

  .menu-toggleable-left.navbar-toggleable-md.in {
    min-width: 50%;
    left: 0;
  }
}

@media (max-width: 1199.98px) {
  .menu-toggleable-left.navbar-toggleable-lg {
    position: fixed;
    left: -100%;
    top: 0;
    bottom: 0;
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block;
    max-width: 100%;
  }

  .menu-toggleable-left.navbar-toggleable-lg.in {
    min-width: 50%;
    left: 0;
  }
}

.menu-toggleable-left.navbar-toggleable-xl {
  position: fixed;
  left: -100%;
  top: 0;
  bottom: 0;
  transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
  display: block;
  max-width: 100%;
}

.menu-toggleable-left.navbar-toggleable-xl.in {
  min-width: 50%;
  left: 0;
}

.navbar.bg-inverse {
  background-color: transparent !important;
  padding: 0;
  justify-content: center;
  border-top: 1px solid #B6B6B6;
  border-bottom: 1px solid #B6B6B6;
}

@media (min-width: 769px) {
  .navbar.bg-inverse .navbar-nav .nav-item+.nav-item {
    margin-left: 0;
  }

  .navbar.bg-inverse .navbar-nav .nav-link {
    padding: 0.8rem;
    white-space: nowrap;
  }
}

.navbar-expand-md .navbar-nav.nav-center {
  -ms-flex-pack: center;
  justify-content: center;
}

.navbar-expand-md .navbar-nav.nav-spaced {
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}

.navbar-expand-md .navbar-nav.nav-right {
  -ms-flex-pack: end;
  justify-content: end;
}

.nav-item .nav-link:focus,
.nav-item .nav-link:hover,
.nav-item.show .nav-link {
  color: #444;
}

@media (min-width: 769px) {
  .nav-item>.nav-link {
    color: #fff;
  }
}

@media (max-width: 768.98px) {
  .main-menu.menu-toggleable-left {
    z-index: 4;
  }
}

.menu-toggleable-left .close-menu {
  padding: 15px;
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

@media (min-width: 769px) {
  .menu-toggleable-left .close-menu {
    display: none;
  }
}

.menu-toggleable-left .menu-group {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

.menu-toggleable-left li>.close-menu {
  margin-right: 0;
  margin-top: -0.6rem;
  margin-left: 0;
}

@media (max-width: 768.98px) {
  .menu-toggleable-left .bg-inverse {
    background-color: #fff !important;
    color: #222;
  }
}

@media (max-width: 543.98px) {
  .menu-toggleable-left.in {
    right: 0;
    margin-right: 1.25em;
  }
}

@media (max-width: 768.98px) {
  .menu-toggleable-left.in .nav-item+.nav-item {
    border-top: 1px solid #eee;
  }

  .menu-toggleable-left.in .dropdown {
    display: block;
    position: static;
  }

  .menu-toggleable-left.in .dropdown-toggle {
    padding-left: 1rem;
  }

  .menu-toggleable-left.in .dropdown-toggle:after {
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
    position: absolute;
    right: 0.3em;
    margin-top: 0.55em;
  }

  .menu-toggleable-left.in .nav-item .nav-link {
    padding-left: 1rem;
  }

  .menu-toggleable-left.in .show>.dropdown-menu {
    left: 0;
  }

  .menu-toggleable-left.in .dropdown-menu {
    position: absolute;
    left: -100%;
    top: 0;
    width: 100%;
    height: 100%;
    border: 0 none;
    transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
    display: block;
  }
}

.multilevel-dropdown .dropdown-menu {
  top: 90%;
  border: 0;
  border-radius: 0;
}

@media (min-width: 769px) {
  .multilevel-dropdown .dropdown-menu {
    box-shadow: 0 3px 5px rgba(43, 36, 25, 0.4);
  }
}

.multilevel-dropdown .dropdown-item.dropdown>.dropdown-toggle:after {
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
  position: absolute;
  right: 0.3em;
  margin-top: 0.55em;
}

@media (min-width: 769px) {
  .multilevel-dropdown .dropdown-menu>.dropdown>.dropdown-menu {
    top: -0.65em;
    left: 99%;
  }
}

.multilevel-dropdown .navbar>.close-menu>.back {
  display: none;
}

.multilevel-dropdown .close-menu .back .caret-left {
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-left: 0.3 solid transparent;
  width: 0;
  height: 0;
  display: inline-block;
  margin-bottom: 0.125em;
}

.multilevel-dropdown .dropdown-item {
  padding: 0 0 0 1em;
}

.multilevel-dropdown .dropdown-item .dropdown-link {
  display: block;
  padding: 0.425em 5em 0.425em 0;
}

.multilevel-dropdown .dropdown-item+.dropdown-item {
  border-top: 1px solid #eee;
}

.multilevel-dropdown .dropdown-item.top-category {
  font-weight: 700;
}

.multilevel-dropdown .dropdown-item.top-category>.nav-link {
  padding-left: 0;
}

.header {
  position: relative;
}

.navbar-header {
  height: 4.375em;
}

.navbar-header .country-selector,
.navbar-header .minicart,
.navbar-header .search,
.navbar-header .user {
  display: inline-block;
  margin: 1.125em 0 0 0.5em;
}

.navbar-header .country-selector,
.navbar-header .minicart,
.navbar-header .navbar-toggler,
.navbar-header .user {
  line-height: 2.25em;
  height: auto;
}

.navbar-header .navbar-toggler {
  font-size: 1.6em;
  width: auto;
}

.navbar-header .user {
  position: relative;
}

.navbar-header .user .popover {
  position: absolute;
  display: none;
  padding: 1em;
  top: 85%;
  left: 0;
}

.navbar-header .user .popover a {
  white-space: nowrap;
  margin-bottom: 0.5em;
}

.navbar-header .user .popover:after,
.navbar-header .user .popover:before {
  left: 1.5rem;
}

.brand,
.navbar-header .user .popover.show {
  display: block;
}

.brand {
  position: absolute;
  left: 50%;
  text-align: center;
}

.brand img {
  width: 100%;
}

@media (min-width: 992px) {
  .brand {
    width: 14.125em;
    margin-left: -7.0625em;
    padding-top: 0.5em;
  }
}

@media (max-width: 991.98px) {
  .brand {
    width: 4em;
    margin-left: -2em;
    padding-top: 0.8em;
  }
}

/* .main-menu {
  background-color: #444;
} */

.main-menu .navbar .close-button button,
.main-menu .navbar .close-menu button {
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.header-banner {
  background-color: #222;
  text-align: center;
  color: #fff;
}

.header-banner .close-button {
  width: 2.125em;
}

.header-banner .close-button .close {
  opacity: 1;
  color: #fff;
  width: 100%;
  height: 100%;
  background-color: var(--skin-primary-color-1);
}

.header-banner .content {
  margin-right: 1.5em;
  padding-top: 0.3125em;
  padding-bottom: 0.3125em;
}

.minicart {
  margin-top: 0.1875em;
  vertical-align: top;
}

.minicart .minicart-icon {
  font-size: 1.5em;
}

.minicart a.minicart-link:hover {
  text-decoration: none;
}

.minicart .minicart-quantity {
  font-size: 10px;
  color: #fff;
}

a.normal {
  color: var(--skin-primary-color-1);
  text-decoration: underline;
}

.slide-up {
  transition-duration: 0.5s;
  transition-timing-function: ease-in;
  max-height: 100px;
  overflow: hidden;
}

.slide-up.hide {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.dropdown-country-selector {
  margin-top: -0.0625em;
}

.cookie-warning-messaging.cookie-warning {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
  display: none;
}

.valid-cookie-warning {
  background-color: var(--skin-link-color-1);
  color: #fff;
  white-space: nowrap;
}

.valid-cookie-warning p {
  margin-top: 0;
  margin-bottom: 0.2em;
  padding-right: 2em;
}

@media (max-width: 543.98px) {
  .collapsible-xs .title {
    line-height: 2.5rem;
  }

  .collapsible-xs .title:after {
    display: block;
    clear: both;
    content: "";
    float: right;
    content: "\F078";
    font-family: FontAwesome;
  }

  .collapsible-xs .card-body,
  .collapsible-xs .content {
    display: none;
  }

  .collapsible-xs.active .title:after {
    content: "\F077";
    margin-top: -0.125em;
  }

  .collapsible-xs.active .card-body,
  .collapsible-xs.active .content {
    display: block;
  }
}

.container div.collapsible-xs button.title {
  color: #000;
  text-decoration: none;
  border: none;
  background-color: transparent;
}

.container div.collapsible-xs button.title:hover {
  text-decoration: none;
}

@media (max-width: 768.98px) {
  .collapsible-sm .title {
    line-height: 2.5rem;
  }

  .collapsible-sm .title:after {
    display: block;
    clear: both;
    content: "";
    float: right;
    content: "\F078";
    font-family: FontAwesome;
  }

  .collapsible-sm.active .title:after {
    content: "\F077";
    margin-top: -0.125em;
  }

}

.container div.collapsible-sm button.title {
  color: #000;
  text-decoration: none;
  border: none;
  background-color: transparent;
}

.container div.collapsible-sm button.title:hover {
  text-decoration: none;
}

@media (max-width: 991.98px) {
  .collapsible-md .title {
    line-height: 2.5rem;
  }

  .collapsible-md .title:after {
    display: block;
    clear: both;
    content: "";
    float: right;
    content: "\F078";
    font-family: FontAwesome;
  }

  .collapsible-md .card-body,
  .collapsible-md .content {
    display: none;
  }

  .collapsible-md.active .title:after {
    content: "\F077";
    margin-top: -0.125em;
  }

  .collapsible-md.active .card-body,
  .collapsible-md.active .content {
    display: block;
  }
}

.container div.collapsible-md button.title {
  color: #000;
  text-decoration: none;
  border: none;
  background-color: transparent;
}

.container div.collapsible-md button.title:hover {
  text-decoration: none;
}

@media (max-width: 1199.98px) {
  .collapsible-lg .title {
    line-height: 2.5rem;
  }

  .collapsible-lg .title:after {
    display: block;
    clear: both;
    content: "";
    float: right;
    content: "\F078";
    font-family: FontAwesome;
  }

  .collapsible-lg .card-body,
  .collapsible-lg .content {
    display: none;
  }

  .collapsible-lg.active .title:after {
    content: "\F077";
    margin-top: -0.125em;
  }

  .collapsible-lg.active .card-body,
  .collapsible-lg.active .content {
    display: block;
  }
}

.container div.collapsible-lg button.title {
  color: #000;
  text-decoration: none;
  border: none;
  background-color: transparent;
}

.container div.collapsible-lg button.title:hover {
  text-decoration: none;
}

.collapsible-xl .title {
  line-height: 2.5rem;
}

.collapsible-xl .title:after {
  display: block;
  clear: both;
  content: "";
  float: right;
  content: "\F078";
  font-family: FontAwesome;
}

.collapsible-xl .card-body,
.collapsible-xl .content {
  display: none;
}

.collapsible-xl.active .title:after {
  content: "\F077";
  margin-top: -0.125em;
}

.collapsible-xl.active .card-body,
.collapsible-xl.active .content {
  display: block;
}

.container div.collapsible-xl button.title {
  color: #000;
  text-decoration: none;
  border: none;
  background-color: transparent;
}

.container div.collapsible-xl button.title:hover {
  text-decoration: none;
}

footer {
  background-color: #e9ecef;
  padding-top: 1.25em;
  padding-bottom: 1.25em;
}

footer h2 {
  font-size: 1rem;
  margin-bottom: 0;
  line-height: 2.5em;
}

footer ul {
  list-style: none;
  padding-left: 0;
}

footer .social h2 {
  margin-top: 0;
}

footer .social:after {
  display: block;
  clear: both;
  content: "";
}

footer .copyright,
footer .social {
  margin-top: 1.25em;
}

footer .footer-container .footer-item.collapsible-xs button {
  font-family: Dosis, sans-serif;
  padding: 0;
}

footer .social-links {
  float: left;
}

footer .social-links:after {
  display: block;
  clear: both;
  content: "";
}

@media (max-width: 543.98px) {
  footer .social-links {
    width: 80%;
  }
}

footer .social-links li {
  float: left;
  margin: 0.313em;
}

@media (max-width: 543.98px) {
  footer .social-links li {
    width: 20%;
    text-align: center;
  }
}

footer .social-links a {
  font-size: 2.25em;
}

footer .social-links a:hover {
  text-decoration: none;
}

@media (max-width: 543.98px) {
  footer .store {
    border-bottom: 1px solid #444;
  }

  footer .store .content {
    display: none;
  }

  footer .store h2:after {
    display: block;
    clear: both;
    content: "";
    font-family: FontAwesome;
    float: right;
    content: "\F041";
  }
}

footer .content {
  font-size: 0.875em;
}

footer .content li {
  height: 1.875rem;
}

footer .copyright,
footer .postscript {
  font-size: 0.8125em;
}

footer .copyright {
  margin-bottom: 0.625em;
}

footer .back-to-top {
  margin: 0.1em 0.313em;
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

footer .back-to-top i.fa-arrow-up {
  color: rgba(0, 0, 0, 0.7);
}

footer .back-to-top i.fa-circle {
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

@media (max-width: 543.98px) {
  .footer-item {
    border-bottom: 1px solid #444;
  }
}

#consent-tracking .button-wrapper button {
  margin: 0.5em;
}

.email-signup-message {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 15%;
  left: 50%;
  z-index: 5;
}

.email-signup-alert {
  animation: fade 5s linear forwards;
  box-shadow: 1px 1px 5px grey;
  padding: 1em;
  z-index: 5;
}

.email-signup-alert.show {
  display: block;
}

.hero {
  height: 25vw;
  background-size: cover;
  background-position: 50%;
  position: relative;
}

.hero h1.page-title {
  top: 50%;
  margin: -1em 0 0;
}

@media (min-width: 544px) {
  .slant-down:after {
    bottom: 0;
    right: 0;
    border: 0 solid transparent;
    border-right-width: 0;
    border-left-width: 90vw;
    border-bottom: 4vw solid #f9f9f9;
  }
}

@media (min-width: 544px) {

  .slant-down:after,
  .slant-up:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
  }

  .slant-up:after {
    top: 0;
    left: 0;
    border: 0 solid transparent;
    border-left-width: 0;
    border-right-width: 90vw;
    border-top: 4vw solid #f9f9f9;
  }
}

h1.page-title {
  position: relative;
  color: #fff;
  padding: 0.3125em 0.625em 0.3125em 15px;
  background-color: var(--skin-primary-color-1);
  display: inline-block;
  margin: 0.9375em 0;
  font-size: 1.5rem;
}

@media (min-width: 544px) {
  h1.page-title {
    font-size: 2rem;
  }
}

@media (min-width: 769px) {
  h1.page-title {
    font-size: 3rem;
  }
}

@media (min-width: 1200px) {
  h1.page-title:before {
    width: calc((100vw - 1140px) / 2);
    left: calc((100vw - 1140px) / 2 * -1);
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  h1.page-title:before {
    width: calc((100vw - 940px) / 2);
    left: calc((100vw - 940px) / 2 * -1);
  }
}

@media (min-width: 769px) and (max-width: 991.98px) {
  h1.page-title:before {
    width: calc((100vw - 720px) / 2);
    left: calc((100vw - 720px) / 2 * -1);
  }
}

@media (min-width: 1200px) {
  h1.page-title {
    left: calc((100% - 1140px) / 2);
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  h1.page-title {
    left: calc((100% - 940px) / 2);
  }
}

@media (min-width: 769px) and (max-width: 991.98px) {
  h1.page-title {
    left: calc((100% - 720px) / 2);
  }
}

@media (max-width: 768.98px) {
  h1.page-title {
    left: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Dosis, sans-serif;
}

.notify {
  position: fixed;
  top: 0;
}

.price {
  color: #222;
  font-family: "Avenir-Next";
}

.price .strike-through {
  text-decoration: line-through;
  display: flex;
  align-items: center;
}

.price .tiered {
  color: #222;
}

.price .tiered table {
  border-top: 1px solid #ccc;
  margin: 0 auto;
}

.price .tiered table tr:nth-child(odd) {
  background-color: #eee;
}

.price .tiered span.price,
.price .tiered td {
  font-size: 0.875rem;
  font-weight: 700;
}

.price .tiered td {
  padding: 0.313rem;
}

.price .tiered td.quantity {
  font-weight: 400;
  text-align: right;
}

.price .tiered td.value {
  text-align: left;
}

.price .tiered .table-header {
  font-size: 1.125rem;
  padding: 0.313rem;
}

.price .tiered .column-header {
  font-size: 1rem;
  padding: 0.313rem;
  font-weight: 400;
}

.price .tiered .column-header.quantity {
  text-align: right;
}

.attribute {
  margin-top: 0.938em;
}

.attribute label {
  display: block;
}

.swatch a {
  text-decoration: none;
}

@media (max-width: 543.98px) {
  .primary-images {
    /* margin: 0;
    padding: 0; */
  }
}

.prices,
.prices-add-to-cart-actions .price {
  text-align: center;
}

.prices {
  padding-bottom: 0.5em;
  padding-top: 0;
}

.cart-and-ipay {
  text-align: center;
}

@media (max-width: 543.98px) {
  .cart-and-ipay {
    padding-bottom: 26px;
  }

  .cart-and-ipay .btn {
    width: 100%;
    margin: 0;
    display: block;
  }
}

.add-to-cart-messages {
  position: fixed;
  animation: fade 5s linear forwards;
}

.add-to-basket-alert {
  padding: 1em;
  position: absolute;
  top: 6%;
  right: 2%;
}

.add-to-cart-messages button {
  position: absolute;
  right: 1rem;
  top: 20px;
  font-size: 2.3rem;
  background: transparent;
  color: white;
  border: 0;
  z-index: 9999;
  outline: 0;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}

.add-to-basket-alert.show {
  display: block;
}

.main-attributes,
.simple-quantity {
  margin-top: 1em;
}

.size-chart,
div.availability {
  margin-top: 1.071em;
}

.bundle-item {
  padding-bottom: 1em;
  border-bottom: 1px solid #ccc;
}

.bundle-item:last-child {
  border-bottom: none;
}

.container.product-detail {
  margin-top: 12px;
  margin-bottom: 2em;
}

@media (max-width: 543.98px) {
  .container.product-detail {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.product-option:not(:first-child) {
  margin-top: 1.071em;
}

.choose-bonus-product-dialog,
.quick-view-dialog {
  max-width: 60em;
}

.choose-bonus-product-dialog .selectable-bonus-product-line-item,
.quick-view-dialog .selectable-bonus-product-line-item {
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}

.choose-bonus-product-dialog .beenSelected,
.quick-view-dialog .beenSelected {
  background-color: #eee;
}

.choose-bonus-product-dialog .modal-header,
.quick-view-dialog .modal-header {
  background-color: #eee;
  border-bottom: 2px solid #ccc;
  border-top-left-radius: 0.1875rem;
  border-top-right-radius: 0.1875rem;
}

.choose-bonus-product-dialog .modal-header .full-pdp-link,
.quick-view-dialog .modal-header .full-pdp-link {
  color: var(--skin-primary-color-1);
}

.choose-bonus-product-dialog .modal-header .close,
.quick-view-dialog .modal-header .close {
  font-size: 2rem;
  line-height: 1.5rem;
}

.choose-bonus-product-dialog .modal-title,
.quick-view-dialog .modal-title {
  font-size: 1em;
}

.choose-bonus-product-dialog .product-name,
.quick-view-dialog .product-name {
  font-size: 1.875em;
}

.choose-bonus-product-dialog .swatch-circle,
.quick-view-dialog .swatch-circle {
  width: 18px;
  height: 18px;
  background: #fff;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}

.swatch-circle-verde-oscuro{
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #2d572c;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.choose-bonus-product-dialog .swatch-circle.color-value[data-selected="true"]:after,
.quick-view-dialog .swatch-circle.color-value[data-selected="true"]:after {
  color: #000;
  content: "";
  width: 20px;
  height: 20px;
  border: 1px solid #000;
}

.choose-bonus-product-dialog .swatch-circle.color-value.selected:after,
.quick-view-dialog .swatch-circle.color-value.selected:after {
  border-radius: 50%;
  content: "";
  padding: 10px;
  background-clip: content-box;
  background-color: transparent;
  border: 1px solid #000;
  position: absolute;
  left: -3px;
  top: -3px;
}

.choose-bonus-product-dialog .swatch-circle i.fa-times-circle,
.quick-view-dialog .swatch-circle i.fa-times-circle {
  background: #fff;
  border-radius: 50%;
  height: 0.75em;
  line-height: 0.8em;
  width: 0.8em;
}

.choose-bonus-product-dialog a[disabled] .swatch-circle,
.quick-view-dialog a[disabled] .swatch-circle {
  cursor: not-allowed;
}

.choose-bonus-product-dialog a[disabled] .swatch-circle.color-value.selected:after,
.quick-view-dialog a[disabled] .swatch-circle.color-value.selected:after {
  background-color: #495057;
}

.choose-bonus-product-dialog .availablity-container,
.quick-view-dialog .availablity-container {
  text-align: right;
}

.choose-bonus-product-dialog .availablity-container,
.choose-bonus-product-dialog .size-chart,
.quick-view-dialog .availablity-container,
.quick-view-dialog .size-chart {
  margin-top: 0.938em;
}

.choose-bonus-product-dialog .modal-content,
.quick-view-dialog .modal-content {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.choose-bonus-product-dialog .modal-body,
.quick-view-dialog .modal-body {
  max-height: 28.125em;
  overflow-y: auto;
  background: #ffffff;
}

.choose-bonus-product-dialog button.close,
.quick-view-dialog button.close {
  font-size: 1.25em;
}

.choose-bonus-product-dialog .modal-footer,
.quick-view-dialog .modal-footer {
  background-color: #ffffff;
  border: none;
  border-bottom-right-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem;
}

.choose-bonus-product-dialog .modal-footer .prices .price,
.quick-view-dialog .modal-footer .prices .price {
  font-size: 1.6em;
}

.choose-bonus-product-dialog .prices .sales,
.quick-view-dialog .prices .sales {
  font-size: 1.5rem;
}

.choose-bonus-product-dialog .promotions,
.quick-view-dialog .promotions {
  text-align: left;
  color: #c00;
}

.attributesCont.row {
  display: grid;
  gap: 10px;
}

.col-talla {
  grid-row: 2;
  grid-column: 1;
}

.col-color {
  grid-row: 1;
  grid-column-start: 1;
  grid-column-end: 2;
}


.quantityCont {
  grid-column: 2;
  grid-row: 2;
}

.favCont {
  grid-row: 2;
  grid-column: 3;
}

.attributesPdp {
  display: -ms-inline-grid;
  display: -moz-inline-grid;
  display: inline-grid;
  grid-template-columns: 1fr auto;
  margin-bottom: 18px;
  width: 100%;
  padding-left: 0;
}

.attributesPdp .row-color {
  grid-row: 1;
  margin-bottom: 24px;
}

.attribute-label{
  grid-row: 2;
  grid-column: 1;
}

.attributesPdp .row-talla {
  grid-row: 3;
  grid-column-start: 1;
  grid-column-end: 4;
  width: 100%;
  padding-top: 10px;
}

.quantityPDP {
  grid-row: 2;
  grid-column: 2;
}

.favPDP {
  grid-row: 2;
  grid-column: 3;
}

.product-options {
  grid-row: 3;
}

.attributeDetailTalla {
  display: flex;
}

@media (max-width: 768.98px) {

  .choose-bonus-product-dialog .bonus-summary,
  .quick-view-dialog .bonus-summary {
    font-size: 0.625em;
  }

  .attributesPdp {
    display: -ms-inline-grid;
    display: -moz-inline-grid;
    display: inline-grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 18px;
  }

  .attributesPdp .row-talla {
    grid-column: -5/-2;
    grid-row: 5;
  }

  .quantityPDP {
    grid-column: -2/-1;
    grid-row: 1;

  }

  .attribute-label{
    grid-row: 4;
    grid-column: 1;
    grid-column-end: 4;
  }
}

@media (max-width: 768.98px) {

  .choose-bonus-product-dialog .bonus-summary .bonus-product-name,
  .quick-view-dialog .bonus-summary .bonus-product-name {
    padding: 0;
  }
}

.choose-bonus-product-dialog .pre-cart-products,
.quick-view-dialog .pre-cart-products {
  margin-right: 0.125em;
}

.choose-bonus-product-dialog .color-attribute,
.quick-view-dialog .color-attribute {
  border: none;
  padding: 0;
  background: none;
}

.choose-bonus-product-dialog .non-input-label,
.quick-view-dialog .non-input-label {
  display: block;
  margin-bottom: 0.5rem;
}

.product-tile {
  border: 0;
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .product-tile {
    min-height: 23.4375em;
  }
}

@media (max-width: 768.98px) {
  .product-tile {
    min-height: 28.6875em;
  }
}

@media (max-width: 543.98px) {
  .product-tile {
    min-height: 25.4375em;
  }
}

.product-tile .tile-body {
  padding: 0;
}

.product-tile .tile-body .color-swatches {
  min-height: 2.25em;
}

.product-tile .tile-body .color-swatches .product-tile-color-label {
  cursor: pointer;
  font-size: 1em;
}

@media (max-width: 991.98px) {
  .product-tile .tile-body .color-swatches .product-tile-color-label {
    font-size: 0.9375em;
  }
}

@media (max-width: 768.98px) {
  .product-tile .tile-body .color-swatches .product-tile-color-label {
    font-size: 0.8125em;
  }
}

.product-tile .tile-body .price {
  font-size: 14px;
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .product-tile .tile-body .price {
    font-size: 1.0625em;
  }
}

@media (max-width: 768.98px) {
  .product-tile .tile-body .price {
    font-size: 16px;
  }
}

.product-tile .tile-body .price .tiered {
  font-size: 0.875em;
}

.product-tile .tile-body .price .tiered .value {
  font-weight: 700;
}

.product-tile .tile-body .coming-soon-tile {
  text-align: center;
}

.product-tile .tile-body .pdp-link {
  line-height: 1.2;
}

.search-results .product-tile .tile-body .pdp-link a {
  width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  text-transform: capitalize;
}

.product-tile .tile-body .pdp-link a {
  width: 320px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  font-size: 14px;
  text-transform: capitalize;
}

.footer-email-signup {
  padding-left: 15px;
}

.lastItemFooter {
  position: relative;
}

.footer-miniLogo {
  position: absolute;
  right: 13%;
  bottom: 42%;
}


@media (max-width: 1350px) {
  .product-tile .tile-body .pdp-link a {
    width: 210px;
  }
}

@media (max-width: 1100px) {
  .product-tile .tile-body .pdp-link a {
    width: 225px;
  }

  .search-results .product-tile .tile-body .pdp-link a {
    width: 190px;
  }

  .add-to-basket-alert {
    top: 18%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 768px) {

  .search-results .product-tile .tile-body .pdp-link a {
    width: -webkit-fill-available;
  }

  .experience-commerce_layouts-navTabs .product-tile .tile-body .pdp-link a {
    width: 310px !important;
    font-size: 16px !important;
  }
}

.experience-commerce_layouts-navTabs .product-tile .tile-body .pdp-link a {
  width: 333px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.summary .tile-body {
  height: 100px;
}

.product-tile .tile-body .ratings {
  font-size: 0.9em;
}

.product-tile .image-container {
  position: relative;
  overflow: auto;
}

.product-tile .image-container .quickview {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.product-tile .image-container .quickview i.fa-expand {
  color: rgba(0, 0, 0, 0.7);
}

.product-tile .image-container .quickview i.fa-circle {
  color: hsla(0, 0%, 100%, 0.7);
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.quickviewInfo .promotion-item{
  margin-left: 10px;
  background-color: #FD4237;
  font-size: 18px;
  color: #fff;
  padding-top: 6px;
}

.product-tile .image-container a {
  display: block;
}

.container-custom-plp .product-tile .image-container {
  /* height: 425px; */
  height: auto;
}

.product-tile .image-container a .tile-image {
  width: 100%;
}

.product-tile .swatches a {
  text-decoration: none;
  margin-right: 6px;
}

/* .product-tile .swatch-circle {
  width: 1.8em;
  height: 1.8em;
  background: #fff;
  border-radius: 0.9em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;
  position: relative;
} */

.product-tile .swatch-circle.color-value[data-selected="true"]:after {
  color: #000;
  content: "\F058";
  display: table-caption;
  font-family: FontAwesome;
  font-size: 1.625em;
  left: 0.295em;
  position: absolute;
}

.product-tile .swatch-circle.color-value.selected:after {
  color: #000;
  content: "\F058";
  display: table-caption;
  font-family: FontAwesome;
  font-size: 1.625em;
  left: 0.31em;
  position: absolute;
  top: 0.35em;
}

.product-tile .swatch-circle.color-value.selected:after,
.product-tile .swatch-circle i.fa-times-circle {
  background: #fff;
  border-radius: 50%;
  height: 0.75em;
  line-height: 0.8em;
  width: 0.8em;
}

#chooseBonusProductModal .modal-footer .container {
  margin-left: 0;
  width: 100%;
  margin-right: 0;
}

#chooseBonusProductModal .select-cbp-container {
  margin-top: auto;
  margin-bottom: auto;
}

#chooseBonusProductModal .product-name-wrapper {
  width: 100%;
}

#chooseBonusProductModal .bonus-option,
#chooseBonusProductModal .bonus-quantity {
  margin-top: 0.938em;
}

#chooseBonusProductModal .bonus-quantity-select {
  min-width: 5em;
}

#chooseBonusProductModal .select-bonus-product {
  margin-top: 1em;
}

#chooseBonusProductModal .selected-pid {
  border: 1px solid #ccc;
}

#chooseBonusProductModal .selected-pid .bonus-product-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#chooseBonusProductModal .bonus-product-price {
  text-align: center;
  margin-top: 1em;
}

.form-nav .nav-tabs .nav-link {
  color: #333;
}

.form-nav .nav-tabs .nav-link.active,
.form-nav .nav-tabs .nav-link.active:focus,
.form-nav .nav-tabs .nav-link.active:hover {
  color: #333;
}

.nav-tabs .nav-link.active {
  background-color: transparent;
}

@media (min-width: 992px) {
  .form-nav .nav-item>.nav-link {
    color: #333;
  }
}


.container.product-detail .product-breadcrumb {
  padding: 0;
}

.veil {
  z-index: 100;
  text-align: center;
}

.veil,
.veil .underlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.veil .underlay {
  opacity: 0.5;
  background-color: #000;
}

.spinner {
  width: 80px;
  height: 80px;
  text-align: center;
  animation: sk-rotate 2s infinite linear;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
}

.dot1,
.dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #fff;
  border-radius: 100%;
  animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  animation-delay: -1s;
}

@keyframes sk-rotate {
  to {
    transform: rotate(1turn);
  }
}

@keyframes sk-bounce {

  0%,
  to {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

.item-attributes {
  vertical-align: top;
  padding-left: 0;
}

.line-item-attributes,
.line-item-option {
  font-size: 0.813rem;
  margin: 0;
}

.line-item-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #222;
  font-size: 1em;
  font-weight: 700;
  margin-bottom: 0.313em;
}

.line-item-pricing-info {
  margin-bottom: 0;
}

.line-item-pricing-info+.price,
.line-item-pricing-info+.unit-price .price {
  font-size: 1em;
  font-weight: bolder;
}

.line-item-price-quantity-info {
  margin-top: 0.625em;
  border-top: 1px solid #ccc;
}

.line-item-total-text {
  font-size: 0.813em;
}

.pricing {
  font-size: 1em;
  font-weight: bolder;
}

.non-adjusted-price {
  display: none;
}

.line-item-promo {
  color: #008827;
  font-size: 0.813em;
}

.line-item-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.bundled-line-item+.bundled-line-item {
  margin-top: 0.625em;
}

.bundle-includes {
  font-size: 0.813em;
  margin-bottom: 0.625em;
}

.line-item-divider {
  margin: 0.625em -1.225em 0.625em -1.325em;
}

.line-dotted {
  border-top: 0.063em dashed #ccc;
}

.line-item-availability {
  font-size: 0.813rem;
}

.product-line-item-details {
  overflow-y: auto;
}

.minicart {
  position: relative;
}

.minicart h1 {
  font-size: 1rem;
}

.minicart .cart {
  padding-top: 0.625em;
  padding-bottom: 0.625em;
  background-color: #f9f9f9;
}

.minicart .remove-btn {
  color: #999;
  float: right;
  background-color: #fff;
  border: none;
  font-size: 1.625em;
  margin-top: -0.313em;
  padding: 0;
}

.minicart .product-summary {
  margin-right: -0.938em;
  max-height: 21.875em;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 0.938em;
}

.minicart .card-body {
  padding: 0.625em;
}

.minicart .quantity-label {
  font-size: 0.813em;
}

.minicart .quantity {
  width: 100%;
}

.minicart .popover {
  top: 100%;
  left: auto;
  right: 0;
  min-width: 23.44rem;
  max-width: 23.44rem;
  min-height: 22.7rem;
  display: none;
}

.minicart .popover:before {
  left: auto;
  right: 15px;
}

.minicart .popover:after {
  left: auto;
  right: 16px;
}

.minicart .popover.show {
  display: block;
}

.minicart .minicart-footer {
  border-top: 1px solid #ccc;
}

.minicart .estimated-total {
  margin-top: 0.625em;
}

.minicart .sub-total,
.minicart .sub-total-label {
  font-size: 1em;
  font-weight: 600;
}

.minicart .line-item-divider {
  margin: 0.625em -0.625em 0.625em -1.325em;
}

.minicart .line-item-name {
  width: 90%;
}

@media (min-width: 544px) and (max-width: 768.98px) {
  .hide-link-med {
    display: none;
  }
}

@media (min-width: 769px) {
  .hide-no-link {
    display: none;
  }
}

@media (max-width: 543.98px) {
  .hide-no-link {
    display: none;
  }
}

.info-icon {
  position: relative;
  cursor: pointer;
  display: inline-block;
  border: none;
  padding: 0;
  background: inherit;
}

.info-icon .icon {
  border-radius: 1.25rem;
  background-color: var(--skin-primary-color-1);
  width: 1.5625rem;
  display: inline-block;
  text-align: center;
  color: #fff;
  font-weight: 600;
}

.info-icon .tooltip {
  position: absolute;
  bottom: 100%;
  padding: 0.312rem;
  border-radius: 0.1875rem;
  background-color: #444;
  color: #fff;
  font-size: 0.928rem;
  min-width: 20rem;
  max-width: 15rem;
  transform: translate(-50%, -0.625em);
  left: 50%;
  margin-left: 4px;
  animation: fade-in 0.5s linear forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.info-icon .tooltip:before {
  content: " ";
  position: absolute;
  display: block;
  height: 1.25em;
  left: 0;
  bottom: -1.25em;
  width: 100%;
}

.info-icon .tooltip:after {
  border-left: 0.625em solid transparent;
  border-right: 0.625em solid transparent;
  border-top: 0.625em solid #444;
  bottom: -0.625em;
  content: " ";
  height: 0;
  left: 50%;
  margin-left: -13px;
  position: absolute;
  width: 0;
}

.swatch-circle-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000000;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-black.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000;
  border-radius: 0.69em;
  background-color: #000;
  display: block;
  position: relative;
}

.swatch-filter-black.disabled {
  opacity: 0.5 !important;
}

.swatch-circle-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #88A3CF;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-blue.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #0070d2;
  border-radius: 0.69em;
  background-color: #0070d2;
  display: block;
  position: relative;
}

.swatch-filter-blue.disabled {
  opacity: 0.5 !important;
}

.swatch-circle-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #A2E48B;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-green.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #008827;
  border-radius: 0.69em;
  background-color: #008827;
  display: block;
  position: relative;
}

.swatch-filter-green.disabled {
  opacity: 0.5 !important;
}

/* Colores español ok */

.swatch-circle-azul-marino {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #12346C;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-azul-marino.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-azul-marino {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #12346C;
  border-radius: 0.69em;
  background-color: #12346C;
  display: block;
  position: relative;
}

.swatch-filter-azul-marino.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-azul-marino {
  width: 18px;
  height: 18px;
  background: #12346C;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}

.swatch-circle-gris-oxford {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #64656A;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-gris-oxford.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-gris-oxford {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #393d42;
  border-radius: 0.69em;
  background-color: #393d42;
  display: block;
  position: relative;
}

.swatch-filter-gris-oxford.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-gris-oxford {
  width: 18px;
  height: 18px;
  background: #64656A;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}

.swatch-circle-negro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000000;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-negro.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-negro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000000;
  border-radius: 0.69em;
  background-color: #000000;
  display: block;
  position: relative;
}

.swatch-filter-negro.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-negro {
  width: 18px;
  height: 18px;
  background: #000000;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}

.swatch-circle-gris {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #D9D9D9;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-gris.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-gris {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #D9D9D9;
  border-radius: 0.69em;
  background-color: #D9D9D9;
  display: block;
  position: relative;
}

.swatch-filter-gris.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-gris {
  width: 18px;
  height: 18px;
  background: #D9D9D9;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}

.swatch-circle-azul {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #88A3CF;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-azul.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-azul {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #88A3CF;
  border-radius: 0.69em;
  background-color: #88A3CF;
  display: block;
  position: relative;
}

.swatch-filter-azul.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-azul {
  width: 18px;
  height: 18px;
  background: #88A3CF;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}

.swatch-circle-rosa-claro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #F0ACC2;
  border-radius: 1.25em;
  background-color: #F0ACC2;
  display: block;
  position: relative;
}

.swatch-circle-rosa-claro.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-rosa-claro {
  width: 18px;
  height: 18px;
  background: #F0ACC2;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}

.swatch-circle-blanco {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #FFFFFF;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-blanco.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-blanco {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #FFFFFF;
  border-radius: 0.69em;
  background-color: #FFFFFF;
  display: block;
  position: relative;
}

.swatch-filter-blanco.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-blanco {
  width: 18px;
  height: 18px;
  background: #FFFFFF;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}

.swatch-circle-vino {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #8E2A3B;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-vino.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-vino {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8E2A3B;
  border-radius: 0.69em;
  background-color: #8E2A3B;
  display: block;
  position: relative;
}

.swatch-filter-vino.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-vino {
  width: 18px;
  height: 18px;
  background: #8E2A3B;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}

.swatch-circle-amarillo {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #F5EAA1;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-amarillo.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-amarillo {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #F5EAA1;
  border-radius: 0.69em;
  background-color: #F5EAA1;
  display: block;
  position: relative;
}

.swatch-filter-amarillo.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-amarillo {
  width: 18px;
  height: 18px;
  background: #F5EAA1;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}

.swatch-circle-café {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #988774;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-café.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-café {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #988774;
  border-radius: 0.69em;
  background-color: #988774;
  display: block;
  position: relative;
}

.swatch-filter-café.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-café {
  width: 18px;
  height: 18px;
  background: #988774;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}

.swatch-circle-verde-obscuro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #679857;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-verde-obscuro.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-verde-obscuro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #679857;
  border-radius: 0.69em;
  background-color: #679857;
  display: block;
  position: relative;
}

.swatch-filter-verde-obscuro.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-verde-obscuro {
  width: 18px;
  height: 18px;
  background: #679857;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}

.swatch-circle-morado {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #925292;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-morado.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-morado {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #925292;
  border-radius: 0.69em;
  background-color: #925292;
  display: block;
  position: relative;
}

.swatch-filter-morado.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-morado {
  width: 18px;
  height: 18px;
  background: #925292;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}

.swatch-circle-naranja {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #F3B274;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-naranja.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-naranja {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #F3B274;
  border-radius: 0.69em;
  background-color: #F3B274;
  display: block;
  position: relative;
}

.swatch-filter-naranja.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-naranja {
  width: 18px;
  height: 18px;
  background: #F3B274;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}

.swatch-circle-rosa {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #F0ACC2;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-rosa.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-rosa {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #F0ACC2;
  border-radius: 0.69em;
  background-color: #F0ACC2;
  display: block;
  position: relative;
}

.swatch-filter-rosa.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-rosa {
  width: 18px;
  height: 18px;
  background: #F0ACC2;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}

.swatch-circle-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #EFE3C4;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-beige.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #EFE3C4;
  border-radius: 0.69em;
  background-color: #EFE3C4;
  display: block;
  position: relative;
}

.swatch-filter-beige.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-beige {
  width: 18px;
  height: 18px;
  background: #EFE3C4;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}

.swatch-circle-azul-claro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #88A3CF;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-azul-claro.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-azul-claro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #88A3CF;
  border-radius: 0.69em;
  background-color: #88A3CF;
  display: block;
  position: relative;
}

.swatch-filter-azul-claro.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-azul-claro {
  width: 18px;
  height: 18px;
  background: #88A3CF;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}

.swatch-circle-lila {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #E2A6E2;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-lila.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-lila {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #E2A6E2;
  border-radius: 0.69em;
  background-color: #E2A6E2;
  display: block;
  position: relative;
}

.swatch-filter-lila.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-lila {
  width: 18px;
  height: 18px;
  background: #E2A6E2;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}

.swatch-circle-verde {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #A2E48B;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-verde.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-verde {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #A2E48B;
  border-radius: 0.69em;
  background-color: #A2E48B;
  display: block;
  position: relative;
}

.swatch-filter-verde.disabled {
  opacity: 0.5 !important;
}


.swatch-circle-rojo {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #D75859;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-rojo.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-rojo {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #D75859;
  border-radius: 0.69em;
  background-color: #D75859;
  display: block;
  position: relative;
}

.swatch-filter-rojo.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-rojo {
  width: 18px;
  height: 18px;
  background: #D75859;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}

.swatch-circle-combinado {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #FFFFFF;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-combinado.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-combinado {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #FFFFFF;
  border-radius: 0.69em;
  background-color: #FFFFFF;
  display: block;
  position: relative;
}

.swatch-filter-combinado.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-combinado {
  width: 18px;
  height: 18px;
  background: #FFFFFF;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}

.swatch-circle-azul-obscuro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #12346C;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-azul-obscuro.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-azul-obscuro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #12346C;
  border-radius: 0.69em;
  background-color: #12346C;
  display: block;
  position: relative;
}

.swatch-filter-azul-obscuro.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-azul-obscuro {
  width: 18px;
  height: 18px;
  background: #12346C;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}

.swatch-circle-gris-medio {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #BCBAB8;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-gris-medio.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-gris-medio {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #BCBAB8;
  border-radius: 0.69em;
  background-color: #BCBAB8;
  display: block;
  position: relative;
}

.swatch-filter-gris-medio.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-gris-medio {
  width: 18px;
  height: 18px;
  background: #BCBAB8;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}

.swatch-circle-vino-medio {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #8E2A3B;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-vino-medio.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-vino-medio {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8E2A3B;
  border-radius: 0.69em;
  background-color: #8E2A3B;
  display: block;
  position: relative;
}

.swatch-filter-vino-medio.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-vino-medio {
  width: 18px;
  height: 18px;
  background: #8E2A3B;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}
  
.swatch-circle-cafe-claro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #988774;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-cafe-claro.disabled {
  opacity: 0.5 !important;
}

.swatch-circle-cafe {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #988774;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-cafe.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-cafe-claro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #988774;
  border-radius: 0.69em;
  background-color: #988774;
  display: block;
  position: relative;
}

.swatch-filter-cafe-claro.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-cafe-claro {
  width: 18px;
  height: 18px;
  background: #988774;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}


.swatch-circle-azul-medio {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #4D6AB7;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-azul-medio.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-azul-medio {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #4D6AB7;
  border-radius: 0.69em;
  background-color: #4D6AB7;
  display: block;
  position: relative;
}

.swatch-filter-azul-medio.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-azul-medio {
  width: 18px;
  height: 18px;
  background: #4D6AB7;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}


.swatch-circle-rojo-medio {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #D75859;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-rojo-medio.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-rojo-medio {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #D75859;
  border-radius: 0.69em;
  background-color: #D75859;
  display: block;
  position: relative;
}

.swatch-filter-rojo-medio.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-rojo-medio {
  width: 18px;
  height: 18px;
  background: #D75859;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}


.swatch-circle-beige-claro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #EFE3C4;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-beige-claro.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-beige-claro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #EFE3C4;
  border-radius: 0.69em;
  background-color: #EFE3C4;
  display: block;
  position: relative;
}

.swatch-filter-beige-claro.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-beige-claro {
  width: 18px;
  height: 18px;
  background: #EFE3C4;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}


.swatch-circle-rojo-cereza {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #D75859;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-rojo-cereza.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-rojo-cereza {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #D75859;
  border-radius: 0.69em;
  background-color: #D75859;
  display: block;
  position: relative;
}

.swatch-filter-rojo-cereza.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-rojo-cereza {
  width: 18px;
  height: 18px;
  background: #D75859;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}


.swatch-circle-naranja-claro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #F3B274;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-naranja-claro.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-naranja-claro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #F3B274;
  border-radius: 0.69em;
  background-color: #F3B274;
  display: block;
  position: relative;
}

.swatch-filter-naranja-claro.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-naranja-claro {
  width: 18px;
  height: 18px;
  background: #F3B274;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}


.swatch-circle-gris-obscuro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #64656A;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-gris-obscuro.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-gris-obscuro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #64656A;
  border-radius: 0.69em;
  background-color: #64656A;
  display: block;
  position: relative;
}

.swatch-filter-gris-obscuro.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-gris-obscuro {
  width: 18px;
  height: 18px;
  background: #64656A;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}

.swatch-circle-gris-obscuro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #64656A;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-gris-obscuro.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-gris-obscuro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #64656A;
  border-radius: 0.69em;
  background-color: #64656A;
  display: block;
  position: relative;
}

.swatch-filter-gris-obscuro.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-gris-obscuro {
  width: 18px;
  height: 18px;
  background: #64656A;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}


.swatch-circle-verde-medio {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #679857;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-verde-medio.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-verde-medio {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #679857;
  border-radius: 0.69em;
  background-color: #679857;
  display: block;
  position: relative;
}

.swatch-filter-verde-medio.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-verde-medio {
  width: 18px;
  height: 18px;
  background: #679857;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}


.swatch-circle-amarillo-claro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #F5EAA1;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-amarillo-claro.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-amarillo-claro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #F5EAA1;
  border-radius: 0.69em;
  background-color: #F5EAA1;
  display: block;
  position: relative;
}

.swatch-filter-amarillo-claro.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-amarillo-claro {
  width: 18px;
  height: 18px;
  background: #F5EAA1;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}


.swatch-circle-rosa-medio {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #F0ACC2;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-rosa-medio.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-rosa-medio {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #F0ACC2;
  border-radius: 0.69em;
  background-color: #F0ACC2;
  display: block;
  position: relative;
}

.swatch-filter-rosa-medio.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-rosa-medio {
  width: 18px;
  height: 18px;
  background: #F0ACC2;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}


.swatch-circle-verde-claro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #A2E48B;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-verde-claro.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-verde-claro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #A2E48B;
  border-radius: 0.69em;
  background-color: #A2E48B;
  display: block;
  position: relative;
}

.swatch-filter-verde-claro.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-verde-claro {
  width: 18px;
  height: 18px;
  background: #A2E48B;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}


.swatch-circle-beige-medio {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #CBBFA1;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-beige-medio.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-beige-medio {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #CBBFA1;
  border-radius: 0.69em;
  background-color: #CBBFA1;
  display: block;
  position: relative;
}

.swatch-filter-beige-medio.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-beige-medio {
  width: 18px;
  height: 18px;
  background: #CBBFA1;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}


.swatch-circle-cafe-obscuro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #695237;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-cafe-obscuro.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-cafe-obscuro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #695237;
  border-radius: 0.69em;
  background-color: #695237;
  display: block;
  position: relative;
}

.swatch-filter-cafe-obscuro.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-cafe-obscuro {
  width: 18px;
  height: 18px;
  background: #695237;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}


.swatch-circle-lila-medio {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #E2A6E2;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-lila-medio.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-lila-medio {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #E2A6E2;
  border-radius: 0.69em;
  background-color: #E2A6E2;
  display: block;
  position: relative;
}

.swatch-filter-lila-medio.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-lila-medio {
  width: 18px;
  height: 18px;
  background: #E2A6E2;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}


.swatch-circle-lila-claro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #E2A6E2;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-lila-claro.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-lila-claro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #E2A6E2;
  border-radius: 0.69em;
  background-color: #E2A6E2;
  display: block;
  position: relative;
}

.swatch-filter-lila-claro.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-lila-claro {
  width: 18px;
  height: 18px;
  background: #E2A6E2;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}


.swatch-circle-naranja-medio {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #F3B274;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-naranja-medio.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-naranja-medio {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #F3B274;
  border-radius: 0.69em;
  background-color: #F3B274;
  display: block;
  position: relative;
}

.swatch-filter-naranja-medio.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-naranja-medio {
  width: 18px;
  height: 18px;
  background: #F3B274;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}


.swatch-circle-morado-obscuro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #925292;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-morado-obscuro.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-morado-obscuro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #925292;
  border-radius: 0.69em;
  background-color: #925292;
  display: block;
  position: relative;
}

.swatch-filter-morado-obscuro.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-morado-obscuro {
  width: 18px;
  height: 18px;
  background: #925292;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}


.swatch-circle-cafe-medio {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #695237;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-cafe-medio.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-cafe-medio {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #695237;
  border-radius: 0.69em;
  background-color: #695237;
  display: block;
  position: relative;
}

.swatch-filter-cafe-medio.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-cafe-medio {
  width: 18px;
  height: 18px;
  background: #695237;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}


.swatch-circle-gris-claro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #D9D9D9;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-gris-claro.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-gris-claro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #D9D9D9;
  border-radius: 0.69em;
  background-color: #D9D9D9;
  display: block;
  position: relative;
}

.swatch-filter-gris-claro.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-gris-claro {
  width: 18px;
  height: 18px;
  background: #D9D9D9;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}


.swatch-circle-shedron {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #d94f00;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-shedron.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-shedron {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #d94f00;
  border-radius: 0.69em;
  background-color: #d94f00;
  display: block;
  position: relative;
}

.swatch-filter-shedron.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-shedron {
  width: 18px;
  height: 18px;
  background: #d94f00;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}


.swatch-circle-amarillo-medio {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #F5EAA1;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-amarillo-medio.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-amarillo-medio {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #F5EAA1;
  border-radius: 0.69em;
  background-color: #F5EAA1;
  display: block;
  position: relative;
}

.swatch-filter-amarillo-medio.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-amarillo-medio {
  width: 18px;
  height: 18px;
  background: #F5EAA1;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}


.swatch-circle-morado-medio {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #925292;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-morado-medio.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-morado-medio {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #925292;
  border-radius: 0.69em;
  background-color: #925292;
  display: block;
  position: relative;
}

.swatch-filter-morado-medio.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-morado-medio {
  width: 18px;
  height: 18px;
  background: #925292;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}


.swatch-circle-amarillo-obscuro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #F5EAA1;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-amarillo-obscuro.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-amarillo-obscuro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #F5EAA1;
  border-radius: 0.69em;
  background-color: #F5EAA1;
  display: block;
  position: relative;
}

.swatch-filter-amarillo-obscuro.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-amarillo-obscuro {
  width: 18px;
  height: 18px;
  background: #F5EAA1;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}


.swatch-circle-rosa-obscuro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #F0ACC2;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-rosa-obscuro.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-rosa-obscuro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #F0ACC2;
  border-radius: 0.69em;
  background-color: #F0ACC2;
  display: block;
  position: relative;
}

.swatch-filter-rosa-obscuro.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-rosa-obscuro {
  width: 18px;
  height: 18px;
  background: #F0ACC2;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}


.swatch-circle-rojo-quemado {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #D75859;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-rojo-quemado.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-rojo-quemado {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #D75859;
  border-radius: 0.69em;
  background-color: #D75859;
  display: block;
  position: relative;
}

.swatch-filter-rojo-quemado.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-rojo-quemado {
  width: 18px;
  height: 18px;
  background: #D75859;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}


.swatch-circle-beige-obscuro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #CBBFA1;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-beige-obscuro.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-beige-obscuro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #CBBFA1;
  border-radius: 0.69em;
  background-color: #CBBFA1;
  display: block;
  position: relative;
}

.swatch-filter-beige-obscuro.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-beige-obscuro {
  width: 18px;
  height: 18px;
  background: #CBBFA1;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}


.swatch-circle-vino-obscuro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #8E2A3B;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-vino-obscuro.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-vino-obscuro {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8E2A3B;
  border-radius: 0.69em;
  background-color: #8E2A3B;
  display: block;
  position: relative;
}

.swatch-filter-vino-obscuro.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-vino-obscuro {
  width: 18px;
  height: 18px;
  background: #8E2A3B;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}


.swatch-circle-rojo-carmesi {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #D75859;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-rojo-carmesi.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-rojo-carmesi {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #D75859;
  border-radius: 0.69em;
  background-color: #D75859;
  display: block;
  position: relative;
}

.swatch-filter-rojo-carmesi.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-rojo-carmesi {
  width: 18px;
  height: 18px;
  background: #D75859;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}


.swatch-circle-color-rojo-caramelo {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #D75859;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-color-rojo-caramelo.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-color-rojo-caramelo {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #D75859;
  border-radius: 0.69em;
  background-color: #D75859;
  display: block;
  position: relative;
}

.swatch-filter-color-rojo-caramelo.disabled {
  opacity: 0.5 !important;
}

.product-detail .attributes .swatch-circle-color-rojo-caramelo {
  width: 18px;
  height: 18px;
  background: #D75859;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
}

/* Colores español ok */



.swatch-circle-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #925292;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-purple.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #925292;
  border-radius: 0.69em;
  background-color: purple;
  display: block;
  position: relative;
}

.swatch-filter-purple.disabled {
  opacity: 0.5 !important;
}

.swatch-circle-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #FFFFFF;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-white.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #FFFFFF;
  border-radius: 0.69em;
  background-color: #FFFFFF;
  display: block;
  position: relative;
}

.swatch-filter-white.disabled {
  opacity: 0.5 !important;
}

.swatch-circle-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #F5EAA1;
  border-radius: 1.25em;
  display: block;
  position: relative;
}

.swatch-circle-yellow.disabled {
  opacity: 0.5 !important;
}

.swatch-filter-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #F5EAA1;
  border-radius: 0.69em;
  background-color: #F5EAA1;
  display: block;
  position: relative;
}

.swatch-filter-yellow.disabled {
  opacity: 0.5 !important;
}

.swatch-circle-miscellaneous {
  background: linear-gradient(0deg,
      #821e91,
      #821e91 25%,
      #edd134 0,
      #ff0 50%,
      #edd134 0,
      #59ba00 0,
      #59ba00 76%,
      #111 0,
      #111),
    linear-gradient(0deg, #0e5cd1, #0e5cd1 50%, #e20b0b 0, #e20b0b);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25em;
  display: block;
  height: 2.5em;
  position: relative;
  transform: rotate(35deg);
  width: 2.5em;
}

.swatch-circle-miscellaneous.disabled {
  opacity: 0.5 !important;
}

.swatch-circle-miscellaneous.selected:after {
  transform: rotate(-35deg);
}

.site-search {
  position: relative;
  height: 2.5em;
}

@media (min-width: 544px) {
  .site-search {
    margin-right: 0.5em;
  }
}

@media (min-width: 1200px) {
  .site-search {
    width: 10em;
  }
}

@media (min-width: 769px) and (max-width: 991.98px) {
  .site-search {
    width: 14.0625em;
  }
}

@media (min-width: 544px) and (max-width: 768.98px) {
  .site-search {
    width: 12.5em;
  }
}

.site-search .fa-close,
.site-search .fa-search {
  right: 0.5625em;
}

.site-search .fa-close,
.site-search .fa-search,
.site-search .reset-button {
  position: absolute;
  border: none;
  top: 0.5625em;
  padding: 0;
  background-color: transparent;
}

.site-search .reset-button {
  right: 2em;
}

.site-search input {
  padding-right: 2rem;
  height: 100%;
}

@media (max-width: 543.98px) {
  .header-search .site-search {
    display: none;
  }
}

.suggestions-wrapper {
  position: relative;
}

.suggestions {
  display: block;
  position: absolute;
  border: 1px solid #ccc;
  background-color: #fff;
  top: 0;
  right: 0;
  width: 21.875rem;
  z-index: 3;
}

@media (max-width: 543.98px) {
  .suggestions {
    display: -ms-flexbox;
    display: flex;
    position: fixed;
    width: 100%;
  }
}

.suggestions .swatch-circle {
  width: 2.5em;
  height: 2.5em;
  background: #fff;
  border-radius: 1.25em;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  display: inline-block;
  /*margin-right: 0.313em;*/
  position: relative;
  margin-right: 0.85em;
}

.suggestions .swatch-circle.color-value[data-selected="true"]:after {
  color: #000;
  content: "\F058";
  display: table-caption;
  font-family: FontAwesome;
  font-size: 1.625em;
  left: 0.295em;
  position: absolute;
}

.suggestions .swatch-circle.color-value.selected:after {
  color: #000;
  content: "\F058";
  display: table-caption;
  font-family: FontAwesome;
  font-size: 1.625em;
  left: 0.31em;
  position: absolute;
  top: 0.35em;
}

.suggestions .swatch-circle.color-value.selected:after,
.suggestions .swatch-circle i.fa-times-circle {
  background: #fff;
  border-radius: 50%;
  height: 0.75em;
  line-height: 0.8em;
  width: 0.8em;
}

.suggestions .header {
  color: #999;
  font-size: 0.875em;
  padding-top: 0.625em;
}

.suggestions .header:not(:first-child) {
  border-top: 1px solid #ccc;
}

.suggestions .items {
  padding: 0.313em 0;
}

.suggestions .items .list {
  padding: 0;
  list-style-type: none;
}

.suggestions .items .link {
  text-decoration: none;
}

.suggestions .items .link:hover .name {
  text-decoration: underline;
}

.suggestions .item {
  padding-bottom: 0.625em;
}

.suggestions .item .name {
  margin-top: 0.313em;
}

.suggestions .item .ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 543.98px) {
  .suggestions .item {
    padding-bottom: 0.938em;
  }
}

.suggestions .category-parent {
  color: #999;
  font-size: 0.875em;
}

.suggestions .selected {
  background-color: #eee;
}

.suggestions .container {
  list-style-type: none;
}

.more-below {
  -moz-border-radius: 1.25em;
  background: #ccc;
  border: 0.063em solid rgba(0, 0, 0, 0.1);
  border-radius: 1.25em;
  bottom: 1.875em;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.3);
  display: none;
  height: 2.5em;
  position: fixed;
  right: 1.875em;
  width: 2.5em;
}

.more-below i.fa-long-arrow-down {
  border-radius: 50%;
  color: #fff;
  display: table-caption;
  height: 0.75em;
  font-size: 1.5rem;
  left: 0.57em;
  line-height: 0.8em;
  position: absolute;
  top: 0.4em;
  width: 0.8em;
}

.isFullWidth .carousel-inner {
  width: 100%;
}

.isFullWidth .carousel-inner .carousel-control-prev {
  left: 3% !important;
}

.isFullWidth .carousel-inner .carousel-control-next {
  right: 3% !important;
}

.btn.btn-primary.wishlist_unselected_button {
  background: none !important;
  border: 1px solid #CED4DA;
  color: #000;
  font-size: 16px;
  opacity: 1;
  text-transform: uppercase;
  transition: 0.3s ease all;
  height: 32px;
  width: 32px;
  padding: 10px;
  border-radius: 3px;
}

.btn.btn-primary.wishlist_unselected_button i {
  pointer-events: none;
}

.summary__pricing .price {
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.summary__pricing .price .prices-container .sales-price .sales {
  display: flex;
  flex-direction: row;
  color: #231F20;
  font-size: 16px;
  font-family: 'Avenir-Next';
  font-weight: 500;
}

.container-custom-plp .summary__pricing .price .prices-container .sales-price .sales {
  font-size: 16px;
}

.summary__pricing .prices-container .strike-price del {
  color: rgba(68, 68, 68, 0.5);
}

.summary__pricing .price .prices-container,  .summary__pricing .price .stikePriceCont{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.summary_price {
  display: flex;
  flex-direction: row;
  margin: 12px 0;
}

.add-to-cart-global.btn.btn-primary {
  text-align: center;
  border-radius: 0;
  border: 1px solid transparent;
  background-color: #d8d8d8;
  padding: 12px;
  font-family: "Avenir-LT-Std";
  font-size: 20px;
  color: #000000 !important;
  text-transform: uppercase;
}

.add-to-cart-global.btn.btn-primary:hover {
  background-color: #000;
  color: #fff !important;
}

button.close:focus {
  outline: none;
}

#guiaDeTallas .modal-body {
  display: contents;
}

.title-container {
  background: #000;
  padding: 20px 115px;
  height: 89px;
  display: flex;
  align-items: center;
}

.title-container .page-title {
  position: initial;
  background-color: transparent !important;
  font-size: 36px;
}

.myAccount-title {
  color: #fff;
  font-family: "Avenir-Next";
  font-size: 36px;
  font-weight: 700;
}

.page-title {
  color: #fff !important;
  font-family: "Avenir-Next" !important;
  font-size: 36px !important;
  font-weight: 700 !important;
  padding: 0 !important;
  margin: 0 !important;
}


.login-page .nav-tabs {
  border: 0.3px solid #64656a;
  border-radius: 3px;
}

.login-page .tab-content {
  border: 0.3px solid #64656a;
  border-radius: 3px;
  padding: 0 31px 0 22px;
}

.checkout__form {
  border-radius: 3px;
}

.login-page .breadcrumb {
  margin: 0 115px;
  font-family: 'Avenir-Next';
}

.login-page .card-body {
  padding: 0;
}

.login-page .nav-tabs {
  background-color: #64656a;
  border-bottom: none;
}

.login-page .nav-tabs .nav-link {
  font-family: "Avenir Next";
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.login-page .nav-tabs .nav-link.active {
  border-bottom: none;
  color: #ffffff;
  position: relative;
}

.login-page .nav-tabs .nav-link:hover,
.login-page .nav-tabs .nav-link.active:hover,
.login-page .nav-tabs .nav-link.active:focus {
  background-color: #64656a;
}

.login-page .nav-item .nav-link:focus,
.login-page .nav-item .nav-link:hover,
.login-page .nav-item.show .nav-link {
  color: #ffffff !important;
}

.login-form-nav .form-group {
  margin: 0 auto 0 auto;
  width: auto;
}

.clearfix {
  width: 80%;
  margin: 0 auto;
}

.login-page .nav-tabs .nav-link.active:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 3.3px;
  position: absolute;
  background: #ff0000;
  width: 100px;
  transform: translate(-50%, 0);
  left: 50%;
}

.login-page .nav-item {
  position: relative;
}

.login-form-nav .checkout__text--bold,
.login-form-nav .checkout__text--space {
  font-family: "Avenir-Next" !important;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
}

.login-page .login-form-nav .tab-pane {
  margin-top: 26px !important;
}

#login-form-email,
#login-form-password {
  margin: 16px auto;
  border-radius: 3px;
  width: 100%;
}

#password-reset {
  margin-right: 27px;
  color: #ff0000;
  font-size: 12px;
  font-family: 'Avenir-Next';
}

.login-form-nav .btn__small {
  margin: 15px auto 25px auto;
  display: block;
  height: 44px;
  width: 100%;
  font-family: "Avenir-Next";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
}

.login-form-nav .custom-checkbox .custom-control-label:before {
  border-radius: 50%;
  background-color: #ff0000;
  border: 1px solid #dddddd;
}

.login-form-nav .custom-checkbox .custom-control-label {
  font-family: "Avenir-Next";
  font-weight: 400;
  font-size: 14px;
}

.login {
  margin-bottom: 0;
}

.login-oauth .form-group {
  border: 0.3px solid #000000;
  border-radius: 3px;
  width: 100%;
  height: 30px;
  margin: 0 auto 16px auto;
}

.login-oauth .form-group a {
  font-size: 14px;
  padding: 5px 0 0 0;
  color: #000000 !important;
  background: #ffffff !important;
}

.login-oauth .form-group:last-child {
  margin-bottom: 30px;
}

.login-oauth .btn-outline-primary {
  border: none;
  border-radius: 3px;
}

#register label {
  margin-bottom: 9px;
  font-family: "Avenir-LT-Std";
  font-size: 16px;
  font-weight: 800;
}

#register .form-control {
  margin: 0 auto 20px auto;
  width: 100%;
}

#register .privacy-policy {
  font-family: "Avenir-LT-Std";
  font-weight: 400;
  font-size: 14px;
  margin: 35px 0 9px 0;
  text-align: center;
}

#register .privacy-policy a {
  color: #0280c8;
  text-decoration: underline;
}

.card-header.track-order-header {
  padding: 12px 23px;
  background-color: #64656a !important;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 900;
  font-family: "Avenir-Next" !important;
}

.track-order-header {
  border: none !important;
  border-radius: 0 !important;
  line-height: 15px;
}

.trackorder .card-body {
  border: 0.3px solid #000000;
  border-radius: 3px;
  padding: 26px 31px 0 22px;
}

.track-order-header-text {
  font-family: "Avenir-Next";
  font-size: 14px;
}

.trackorder .form-group label {
  font-family: "Avenir-Next";
  font-size: 16px;
  font-weight: 800;
}

.trackorder .btn-block {
  background-color: #d9d9d9 !important;
  width: 100%;
  height: 34px;
  margin: 0 auto;
  border: none;
  border-radius: 3px;
  color: #000000 !important;
  padding: 0;
  font-family: "Avenir-Next";
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
}

.trackorder .btn-block:hover {
  background-color: #000 !important;
  color: #fff !important;
}

.login-oauth .btn-block {
  height: 100%;
}

.login-oauth .btn-block:hover {
  background-color: #000000;
  color: #ffffff !important;
}


.confirmation-banner {
  width: 100%;
}

.cart-check-img {
  width: 93.75px;
  display: block;
  margin: 40px auto;
}

.order-thank-you-msg {
  text-align: center;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  color: #64656a;
  font-family: "Avenir-LT-Std";
}

.order-thank-you-email-msg {
  text-align: center;
  font-weight: 400;
  font-size: 24px;
  font-family: "Avenir-LT-Std";
}

.request-password-title {
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  background-color: #64656a;
  border-radius: 3px;
  margin-top: 40px;
  width: 100%;
  padding: 12px;
}


.request-password-title {
  margin-bottom: 0;
}

.myAccount .breadcrumb {
  padding: 0;
  position: absolute;
  left: -9%;
}

.myAccount .col-sm-6 {
  padding: 85px 115px;
}

.myAccount .card {
  border: 1px solid #000;
  border-radius: 3px;
  font-family: "Avenir-Next";
}


.myAccount .card-header {
  background-color: #64656a;
  font-family: "Avenir-Next" !important;
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  width: 100%;
  margin: 0;
  padding: 0 20px;
}

.myAccount .card-header h2 {
  height: 43px;
  display: flex;
  align-items: center;
  font-family: 'Avenir-Next';
  font-size: 16px
}

.myAccount .card-header h2~a {
  height: 43px;
  display: flex;
  align-items: center;
  margin: 0 !important;
}

.myAccount .card-header h2~a img {
  margin-bottom: 0 !important;
}


.card-header>a {
  margin-top: 20px;
}

.myAccount .clearfix::after {
  background-image: url(../images/edit.png) !important;
}

.card-body dt {
  font-weight: bold;
  color: #000000 !important;
}

.card-body dd {
  color: #000000 !important;
}

.myAccount .card-link {
  color: #000;
  font-family: "Avenir-Next";
  font-size: 14px;
}

.myAccount .card-body {
  background-color: #fff;
}

.title-container-account h1.page-title {
  display: block;
  text-align: center;
  position: inherit;
  background-color: #e8e8e8 !important;
  color: #000 !important;
  font-size: 40px;

}

.account-sections .card-header {
  background: #e8e8e8;
  font-weight: 900;
}

.account-sections .card-header h2 {
  font-weight: 900;
  font-family: "Avenir-Next";
  font-size: 18px;
}

.account-sections .card-header h4 {
  font-weight: 900;
  font-family: "Avenir-Next";
}

.account-sections .card-body {
  padding: 28px 60px;
  border: 1px solid #000;
}

.account-sections .form-group label {
  font-weight: 700;
  font-size: 16px;
}

.account-sections .btn {
  font-family: "Avenir-LT-Std";
  font-weight: 100;
  text-transform: uppercase;
  border: 1px solid #000;
  padding: 5px 0;
  margin: 15px auto;
  height: 37px;
}

.account-sections .btn:hover,
.account-sections .btn:active {
  background-color: #000;
  color: #ffffff !important;
}

.account-sections .btn-save {
  font-family: "Avenir-LT-Std";
  font-weight: 100;
  text-transform: uppercase;
  background-color: #e8e8e8;
  border: none;
  margin: 15px auto;
  height: 37px;
  color: #000;
}

.account-sections .btn-save-address {
  font-family: "Avenir-LT-Std";
  font-weight: 100;
  text-transform: uppercase;
  background-color: #e8e8e8 !important;
  border: none;
  margin: 15px auto;
  height: 37px;
  color: #000 !important;
}

.account-sections .btn-save-address:hover {
  background-color: #000 !important;
  color: #fff !important;
}

.account-sections .breadcrumb {
  margin: 0;
}

.profile-back-to-account-link {
  color: #2d64fc !important;
  text-decoration: underline;
}

.container.account-sections .adrressBtns {
  padding: 0 !important;
  margin: 35px 0;
}

.adrressBtns .btn-save {
  background-color: #000000;
  color: #fff !important;
  padding: 5px 0;
  border: none;
  font-family: "Avenir-Next" !important;
  font-weight: 100 !important;
}

.adrressBtns .btn-save:hover,
.adrressBtns .btn-save:active {
  color: #000 !important;
  border: 1px solid #000;
}

.order-top .form-group {
  position: relative;
  top: -50px;
}

.order-list-container .card-header,
.orderDetail .card-header {
  background: #e7e7e7;
}

.order-list-container .card-header h3,
.orderDetail .card-header h2 {
  font-family: "Avenir-Next";
  font-size: 16px;
  font-weight: 600;
}

.order-list-container .card-header.clearfix {
  width: 72%;
  margin: 0 auto;
}

.order-list-container .card-body,
.order-list-container .card-footer {
  width: 72%;
  margin: 0 auto;
  border: 1px solid #000;
  border-top: none;
}

.orderDetail {
  margin: 50px 200px;
}

.orderDetail .card-body {
  border: 1px solid #000;
}

.my-account a {
  color: #2d64fc;
  text-decoration: underline;
}

.leading-lines {
  margin-top: 1rem !important;
}

.paymentsListBtns {
  margin: 20px 0;
}

.paymentsListBtns .btn-save {
  background-color: #000;
  color: #fff !important;
  border: none;
}

.paymentsListBtns .btn-save:hover {
  background-color: #fff;
  color: #000 !important;
  border: 1px solid #000;
}

.form-group.required.passwordLabel:before {
  content: "*";
  color: #c00;
}

.form-group.required.checkout.passwordLabel:before {
  content: none !important;
}

.request-password-title {
  /* background: #e8e8e8; */
  background: white;
  padding: 0;
  font-weight: 900;
  color: #000000;
  font-weight: 900;
  font-family: "Avenir-Next";
  line-height: inherit;
  margin-top: 0 !important;
  font-size: 16px !important;
}

.item-image {
  height: 5.625em;
  width: 5.625em;
  margin-right: 0.938em;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.item-image img.product-image {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
  max-height: 5.625em;
}

.container.account-sections .row.backBtn {
  padding-top: 11px;
  padding-bottom: 80px;
}

#register .passwordLeyendCont {
  margin-bottom: 20px;
}

.card-header h2,
.card-header h3 {
  font-weight: 900;
}

.myAccount .card-body {
  padding: 5px 20px 0;
}

.card-footer-border {
  border: none !important;
}

.dashboard-order-card-footer-name {
  font-weight: 600;
}

.dashboard-order-card-footer-value {
  font-weight: 400 !important;
}

.wl-social-sharing .share-icons {
  margin: 0 6px;
}

.container.account-sections .row.newAddress {
  padding: 25px 0;
}

.remove-address {
  background-color: #fff !important;
  right: 10px !important;
}

.container.account-sections .addressBottom .adrressBtns {
  margin: 0;
}

.container.account-sections .addressBottom .adrressBtns .btn-save {
  background-color: #e8e8e8;
  color: #000 !important;
}

.container.account-sections .addressBottom .adrressBtns .btn-save:hover {
  background-color: #000000;
  color: #fff !important;
}

.container.account-sections .row.order-top {
  padding: 33px 177px 0 177px;
}

.container.account-sections .row.payMonth {
  padding: 33px 0;
}

.backPay {
  margin-top: 25px;
  margin-bottom: 0;
}

.paypal-account-button {
  margin: 10px auto 5px auto;
  padding: 0 !important;
}

.payCheckbox .custom-control-input:checked~.custom-control-label:before {
  border-radius: 0;
  background: #ff0000 !important;
  height: 20px;
  width: 20px;
  border: none;
}

.custom-radio .custom-control-input:checked~.custom-control-label:after {
  background-image: url(../images/check.png);
  background-repeat: no-repeat;
  margin-top: 4px;
  margin-left: 2px;
}

.remove-payment {
  background-color: #fff;
}

#homepage .wishlistTile {
  display: none;
}

.wishlist-account-card .card .card-body {
  margin-top: 0;
}

.account-wishlist-item .remove-from-wishlist {
  top: 1rem !important;
}

.account-wishlist-item.card {
  border-bottom: 1px solid #000 !important;
  border-radius: 0 !important;
  border: none;
}

.container.account-sections .row.wishlistRow {
  padding: 0;
}

.promo-carousel .value {
  font-family: "Avenir-Next" !important;
}

.experience-commerce_layouts-promoContainer .price .strike-through {
  color: #c4c4c4 !important;
  font-size: 20px !important;
}

.experience-commerce_layouts-isFullWidth .isFullWidth .col {
  padding: 0 !important;
}

.hero.slant-down .header.page-title {
  text-transform: uppercase;
}

.grid-header .page-title-mobile {
  text-transform: uppercase;
}

.storelocator .modal-header {
  border-bottom: none !important;
}

.storelocator .modal-footer {
  border-top: none !important;
}

.storelocator .modal-body {
  padding: 0 0 10px 0;
}

.storelocator .modal-body .sub-item {
  padding-left: 30px;
}

#storeDetailModal #details-image {
  padding-bottom: 9px;
}

#search-storelocator .title-container {
  background-color: transparent;
}

#search-storelocator .title-container {
  padding: 0 !important;
  justify-content: flex-start;
}

.storelocator #listing .item .name {
  font-weight: 900;
}

#storeDetailModal #details-name {
  font-weight: 900;
}

#storeDetailModal button {
  font-size: 18px !important;
  font-family: "Avenir-Bold" !important;
  font-weight: 900;
}

#details-hours span {
  top: 0 !important;
}

#storeDetailModal #route {
  background-color: transparent !important;
  color: #000 !important;
}

#storeDetailModal button {
  font-size: 14px !important;
  font-family: "Avenir-LT-Std" !important;
  font-weight: 900;
}

.product-detail .prices {
  margin: 5px 0;
}

.collapsible-xl.active .card-body,
.collapsible-xl.active .content {
  padding: 0 !important;
}

.product-detail .description-and-detail .product-attributes ul {
  padding: 0;
}

.btn_checkout {
  background-color: #d9d9d9 !important;
  color: #000000 !important;
}

.btn_checkout:hover {
  background-color: #000000 !important;
  color: #ffffff !important;
}

.checkout .btn {
  font-family: "Avenir-Bold";
}

.checkout #password-reset,
.forgot-password a {
  color: #0280c8;
  font-size: 14px;
  text-align: left !important;
}

.checkout .clearfix {
  width: 100%;
}

.checkout .custom-checkbox .custom-control-label:before {
  border: 1px solid #000000;
  background: #ffffff !important;
  border-radius: 50%;
}

.checkout .custom-checkbox .custom-control-input:checked~.custom-control-label:after {
  background: #000000 !important;
}

.checkout_top {
  display: none;
}

.checkout__cart .prices-container .sales-price .sales .value,
.checkout__item .line-item-total-price .price .pricing {
  font-size: 14px;
  font-family: "Avenir-Next";
}

.cart-page .line-item-name {
  font-family: "Avenir-Next";
  font-weight: 900;
}

.cart_bottom {
  margin-top: 55px;
  margin-bottom: 110px;
}

.qty-card-quantity-count,
.line-item-total-price-amount {
  font-weight: 700;
}

.need-help-description p {
  margin: 0;
}

.need-help-email a {
  text-decoration: underline;
}

.card.checkout__order .card-header-custom {
  font-size: 20px;
  font-family: "Avenir-Next";
  font-weight: 400 !important;
}

.footerCheckText p,
.footerCheckCopy p {
  text-align: center;
  color: #ffffff;
}

.footerCheckImg {
  justify-content: center;
  display: flex;
  margin-bottom: 15px;
}

.footerCheckImg img {
  margin: 0 15px;
}

.checkout-mail-cont {
  margin-top: 15px;
}

.card-header-custom-title {
  font-size: 32px;
  text-align: start;
  font-family: "Avenir-Bold";
}

.card-header-custom {
  font-size: 32px;
  text-align: start;
  font-family: "Avenir-Bold";
}

.submit-payment,
.add-payment {
  font-family: "AvenirLTStd-Heavy" !important;
}

#checkout-main {
  margin-top: 90px;
}

.cart_bottom_text {
  font-family: "Avenir-Next";
  font-weight: 900;
  font-size: 12px;
}

.cart-cvc {
  font-size: 12px;
  font-family: "Avenir-Next";
}

.cart_cvc_cont {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #000;
}

.cart_cvc {
  margin-right: 47%;
  margin-left: 10px;
}

.embeddedServiceHelpButton .helpButton .uiButton {
  background-color: #000 !important;
}

#guiaDeTallas img {
  max-width: 100%;
  height: 60vh;
  object-fit: contain;
  object-position: center;
  margin: auto;
}

.login-checkout {
  margin: 0 70px;
}

.checkoutRightCheck {
  padding-left: 7rem;
}

.add-to-wishlist-alert {
  position: fixed;
  padding: 1rem;
}

.carousel-inner .product-tile .tile-image {
  height: auto;
  object-fit: contain;
  border: 1px solid #e5e2e3;
}

@media (max-width: 1200px) {
  .carousel-inner .product-tile .tile-image {
    /* height: 245px; */
    object-fit: contain;
  }

  .experience-commerce_layouts-navTabs .carousel-inner .product-tile .tile-image {
    height: auto;
    object-fit: contain;
  }
}

.promo-carousel .btn.btn--gray.btn__small:hover {
  background-color: #000;
}

.promo-carousel .btn.btn--gray.btn__small:hover {
  color: #fff;
}

.product-container .product-tile .strike-through span {
  font-size: 18px;
  font-weight: 300;
  color: rgba(68, 68, 68, 0.5);
  text-decoration: line-through;
  text-decoration-color: rgba(68, 68, 68, 0.5);
}

.product-container .product-tile .sales-price span {
  font-size: 20px;
  font-weight: 900;
}

.wishlist-container .login-page .wishlistItemCards {
  margin: 13px 20px 0 40px;
}

.wishlist-container .login-page .login-form-nav {
  margin: 13px 40px 0 20px;
}

.wishlistLoginText {
  margin: 33px 50px 0 50px;
  font-family: 'AvenirLTStd-Heavy';
}

.newsBtn {
  padding: 0;
}

.product-options {
  margin-top: 0 !important;
}

.promo-code-form .form-group label {
  margin-bottom: 0;
}

.coupons-and-promos {
  margin-bottom: 10 !important;
}

.checkout__totals .form-group,
.checkout__totals .form-group label {
  margin-bottom: 0 !important;
  font-size: 14px;
  font-family: 'Avenir-Bold' !important;
}

.checkout_login .form-group .form-control-label {
  margin-bottom: 0 !important;
}

#productdetailpage .prices-container .stikePriceCont, .product-tile .prices-container .stikePriceCont{
  margin-bottom: 12px;
} 

.product-details-wrapper .attributes .attribute {
  margin-top: 0;
}

.summary__pricing .strike-through span {
  color: rgba(68, 68, 68, 0.5);
  font-weight: 400;
  font-family: 'Avenir-Next';
  font-size: 14px;
  margin-right: 10px !important;
}

.quickviewInfo {
  padding: 36px 30px 0 30px;
  margin: auto;
}

.modal-body .product-quickview .product-name {
  font-family: 'Avenir-Next';
  font-size: 20px;
  font-weight: 900;
}

.modal-body .product-quickview .prices .sales .value {
  font-family: 'Avenir-Next';
  font-size: 20px;
  font-weight: 500;
}

.modal-body .product-quickview .prices .price .strike-through {
  font-family: 'Avenir-Next';
  font-size: 20px;
  font-weight: 500;
}

.modal-body .product-quickview .prices-container {
  flex-direction: row;
}

.quickviewInfo .attribute {
  margin-top: 0;
  padding: 0;
}

.quickviewInfo .attribute {
  margin-top: 0;
  padding: 0;
}

.quickviewInfo .quantityCont {
  padding: 0;
}

.quickviewInfo .favCont {
  margin-top: 22px;
  padding-left: 0;
}

.modal-body .product-quickview .prices .price .prices-container .stikePriceCont .strike-price {
  margin-top: 4px;
}

.product-quickview .carousel-item img {
  height: 390px;
  margin: 0 auto;
}

.cartContainer {
  padding: 0 45px;
}

.account-sections {
  padding-top: 20px;
}

.checkout__order--product .price .strike-through {
  font-size: 12px;
}

.checkout__order--product .sales .value {
  font-size: 12px;
}

.checkout__order--product .strike-through span {
  font-size: 12px
}

.checkout__order--product .line-item-total-price-amount {
  font-size: 12px;
}

.checkout__order--product .qty-card-quantity-label {
  margin: 0;
}

.checkout__order--product .qty-card-quantity-count {
  font-size: 12px;
  margin-top: 3px;
}

.checkout__order--product .total-price-wrapper {
  display: block !important;
  text-align: left !important;
}

.result-count {
  font-size: 14px;
  font-family: 'Avenir-Next';
}

.refinements .card,
#pdp-items-container,
.product-detail .product-details-container,
.search-results,
#filter-container .card-header {
  background: #ffffff;
}

#filter-container .card-header {
  cursor: pointer;
}

#productdetailpage .summary .tile-body {
  margin: 0 17%;
}

.lastItemFooter {
  position: relative;
}

#footer-miniLogo .item {
  position: absolute;
  right: 10%;
  bottom: 40%;
}

.refinements .values.content {
  margin-bottom: 0 !important;
  overflow: hidden;
}
.refinement-bar .refinement-colores .values.content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.refinement {
  margin-bottom: 10px !important;
}

.refinement span {
  font-size: 13px;
  font-family: 'Avenir-Next';
}

.card.shipping-section,
.card.shipping-section .card-header,
.data-checkout-stage .card,
.data-checkout-stage .card-header {
  background-color: #f5f5f5;
}

.footer-item .title {
  font-family: 'Avenir-Bold' !important;
  padding-bottom: 12px;
}

.refinements .card-header h2 {
  margin-bottom: 0;
  font-family: 'Avenir-Next' !important;
  font-size: 16px !important;
}

.card-header.clearfix.summary-edit-btn {
  background-color: transparent;
}

.orderDetail .checkout__text {
  padding: 0 15px;
}

.orderDetail .grand-total .checkout__text {
  font-family: 'Avenir-Bold' !important;
}

.dropdown .containerDropdown {
  padding: 0 14.5;
}

.dropdown .containerDropdown .dropdown-menu-content {
  width: 100%;
}

.dropdown .containerDropdown .dropdown-menu-content .menuCont {
  flex-wrap: wrap;
}

.dropdown .containerDropdown .dropdown-menu-content .menuCont .menuColCont {
  padding: 10px 1.5rem;
  width: 30%;
}


.add-to-cart-actions , .add-to-cart-and-checkout{
  position: relative;
  z-index: 99;
}

.add-to-cart-actions > .add-to-cart-alert-tooltip::after, .add-to-cart-and-checkout > .add-to-cart-alert-tooltip::after{
  content: "";
  border: 6px solid transparent;
  border-top-color: red;
  position: absolute;
  top: 100%;
  left:50%;
  transform: translateX(-50%);
}
.add-to-cart-actions > .add-to-cart-alert-tooltip , .add-to-cart-and-checkout > .add-to-cart-alert-tooltip {
  background-color: #F00;
  color: white;
  font-size: 14px;
  position: absolute;
  border-radius: 4px;
  text-align: center;
  min-height: 42px;
  left: 0;
  bottom:100%;
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9000;
}
.add-to-cart-and-checkout > .add-to-cart-alert-tooltip {
  bottom: auto;
  top: 100%;
}
.add-to-cart-and-checkout > .add-to-cart-alert-tooltip::after {
  transform: rotate(180deg);
  top: auto;
  bottom: 100%;
}

@media screen and (min-width: 768px) {

  .add-to-cart-actions > .add-to-cart-alert-tooltip , .add-to-cart-and-checkout > .add-to-cart-alert-tooltip {
    right: 103%;
    top:0;
    left: auto;
    min-width: 305px;
  }
  
  .add-to-cart-actions > .add-to-cart-alert-tooltip::after , .add-to-cart-and-checkout > .add-to-cart-alert-tooltip::after{
    border: 6px solid transparent;
    border-left-color: red;
    top: 15px;
    left:100%;
    transform: translateX(0);
  }
  
}


.add-to-cart-actions,
.add-to-cart-and-checkout {
  width: 100%;
}

.description-and-detail {
  /* width: 100%; */
}

.accordion-description ul{
  padding: 0 !important
}

/* #checkout-main .coupon-price-adjustment{
  display: none !important;
} */

@media (max-width: 1200px) {
  .checkout__cart {
    background: #ffffff !important;
  }

}

@media (max-width: 991.98px) {
  .product-tile .tile-body .pdp-link a {
    font-size: 0.9375em;
  }

  .login-form-nav {
    margin: 13px 20px 0 20px;
  }

  .trackorder {
    margin: 13px 20px 0 20px;
  }

  .product-tile{

  }

  .product-tile .summary__pricing{
    flex-direction: column;
  }

}

@media (max-width: 768.98px) {
  .product-tile .tile-body .pdp-link a {
    font-size: 16px;
    width: 186px;
  }

  .summary__pricing .strike-through span {
    font-size: 16px;
  }

  .summary__pricing .price .prices-container .sales-price .sales {
    font-size: 16px;
  }

  .orderDetail {
    margin: 0 20px;
  }

  .socialMediaDesk {
    display: none !important;
  }

  .footer-email-signup {
    padding: 15px 25px 0 15px;
  }

  .copyright .copyright-container span {
    display: block;
    padding: 0;
    font-size: 10px;
  }

  .add-to-cart-actions,
  .add-to-cart-and-checkout {
    width: 100%;
  }
}

@media (max-width: 395px) {
  .product-tile .tile-body .pdp-link a {
    font-size: 13px;
    width: 163px;
  }

  .summary__pricing .price .prices-container .sales-price .sales {
    font-size: 14px;
  }

  .summary__pricing .strike-through span {
    font-size: 12px;
  }
}

@media (max-width: 350px) {
  .product-tile .tile-body .pdp-link a {
    width: 150px;
  }

  .checkout__item .line-item-total-price .price .pricing {
    margin-top: 15px;
  }
}

@media (max-width: 2560px) {
  .containerDropdown {
    padding: 0 25rem;
  }
}

.containerDropdown {
  padding: 0 4rem;
}

@media (max-width: 2300px) {
  .containerDropdown {
    padding: 0 20rem;
  }
}

@media (max-width: 2000px) {
  .containerDropdown {
    padding: 0 17rem;
  }
}

@media (max-width: 1750px) {
  .containerDropdown {
    padding: 0 14rem;
  }
}

@media (max-width: 1590px) {
  .containerDropdown {
    padding: 0 11rem;
  }
}

@media (max-width: 1590px) {
  .product-detail .cart-and-ipay button {
    font-size: 14px;
  }
}

@media (max-width: 1200px) {
  .containerDropdown {
    padding: 0 6rem;
  }
}

@media (max-width: 1100px) {
  .containerDropdown {
    padding: 0 4rem;
  }
}

@media (max-width: 1024px) {
  .container-custom-plp .product-tile .image-container {
    height: auto;
  }
}

@media (max-width: 768px) {
  .container-custom-plp .product-tile .image-container {
    height: fit-content;
  }
}

@media (max-width: 425px) {
  .container-custom-plp .product-tile .image-container {
    height: auto;
  }
}

@media (max-width: 375px) {
  .container-custom-plp .product-tile .image-container {
    height: auto;
  }
}

.video-home-hero-container{
  padding: 20px 5.5rem !important;
  background-color: #fff !important;
}

.title-carousel-00a8d357039a7493c7ce202d31{
  font-family: "Avenir-Next" !important;
  font-size: 24px;
}

.minicart_icon::after{
  content: '';
  display: block;
  background-image: url('../images/mini_cart.svg');
  width: 24px;
  height: 24px;
  margin-left: 10px;
}
.habers-btn{
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 0;
  font-family: 'Poppins-Medium';
  font-weight: 500;
  font-size: 18px;
  line-height: 26.1px;
  text-align: center;
  width: 100%;
  @media (max-width: 768px) {
    height: 40px;
  }
}
.habers-btn-primary{
  background-color: #000000;
  color: #FFFFFF;
  border: none;
  border: none;
  &:hover{
    background: #4A4A4A;
    box-shadow: 0px 2px 2px 0px rgba(103, 80, 164, 0.40);
    color: #FFFFFF;
    border: none;
  }
  &:disabled{
    background-color: #A0A0A0;
    color: #FFFFFF;
    border: none;
  }
  &:focus{
    outline: none;
  }
}
.habers-btn-secondary{
  background-color: #FFFFFF;
  color: #000;
  border: 1px solid #000;
  &:hover{
    background-color: #FFFFFF;
    color: #000;
    border: 2.5px solid #000;
  }
  &:disabled{
    border-radius: 6px;
    border: 1px solid #A0A0A0;
    background-color: #F2F2F2;
    color: #A0A0A0;
  }
  &:focus{
    outline: none;
  }
}
.promotions-tile{
  display: none;
}
.default_product_cls{
  display: flex;
  flex-direction: row-reverse;
}
.experience-commerce_layouts-customCarousel{
  margin: 0 auto;
  position: relative;
  padding: 0 15px;
  margin-top: 72px;
  @media screen and (max-width: 768px) {
    padding: 0;
    margin-top: 32px;
  }
  .carousel-title-container{
    margin-bottom: 70px;
    @media screen and (max-width: 768px) {
      margin-bottom: 30px;
    }
    .carousel-title{
      font-family: "Poppins-Medium";
      font-weight: 500;
      font-size: 28px ;
      line-height: 135%;
      letter-spacing: -1px;
      text-align: center;
      color: #222222;
    }
  }
  .owl-stage-outer{
    overflow-x: clip;
    overflow-y: visible;
    .owl-item.center{
      transform: scale(1.1);
      transform-origin: bottom;
      transition: transform 0.5s ease-in-out;
      box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.12);
      border-radius: 6px;
      @media screen and (max-width: 768px) {
        box-shadow: none;
        .pdp-link{
          background-color: transparent;
        }
      }
    }
  }
  .item{
    border-radius: inherit;
    .product-tile{
      border-radius: inherit;
      border: none;
      min-height: fit-content !important;
      @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
      }
    }
    .image-container{
      margin: 10px 10px 0 10px;
      .tile-image{
        object-fit: cover;
      }
      .promotions-tile{
        display: block;
        position: static;
        .promotion-tile-name{
          position: absolute;
          right: 0;
          top: 0;
        }
        .promotion-tile-message{
          position: absolute;
          bottom: 0;
          width: 100%;
          text-align: center;
          color: #FFF;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 300;
          line-height: 145%;
          background-color:#FF4438;
          padding: 2px 8px;
          @media screen and (max-width: 768px) {
            line-height: 140%;
            font-size: 10px;
          }
        }
        .promotion-tile-name{
          color: #FFF;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 150%;
          background-color:#FF4438;
          padding: 2px;
          @media screen and (max-width: 768px) {
            font-size: 12px;
          }
        }
      }
    }
    .tile-body{
      margin: 20px 10px 0 !important;
      @media screen and (max-width: 768px) {
        margin: 0 !important;
        height: fit-content;
        .pdp-link{
          background-color: #F2F2F2;
          line-height: 150%;
          .link{
            font-size: 12px !important;
          }
        }
      }
      .summary__name{
        margin-bottom: 5px;
        @media screen and (max-width: 768px) {
          margin-bottom: -3px;
        }
        .pdp-link{
          line-height: 140%;
          .link{
            width: 100%;
            font-family: 'Poppins-Medium';
            font-weight: 500;
            font-size: 16px;
            line-height: inherit;
            vertical-align: middle;
            color: #000000;
          }
        }
      }
      .summary__pricing {
        .price {
          .default_product_cls {
            margin-bottom: 10px;
            @media screen and (max-width: 768px) {
              margin-bottom: 5px;
            }
            .strike-through, .strike-through span{
              color: #A0A0A0;
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 300;
              line-height: 145%;
            }
            .original_sales {
              display: flex;
              flex-direction: row-reverse;
              gap: 5px;

            }
            span {
              font-family: 'Poppins-Medium';
              font-weight: 400;
              font-size: 14px;
              line-height: 145%;
              color: #FF4438;

              @media screen and (max-width: 768px) {
                font-size: 10px !important;
                line-height: 160%;
              }
            }
            .original_sales:not(.strike-through ~ .original_sales) {
              color: #000;
              span{
                color: #000 !important;
              }
            }
          }
        }

        .highlight-container {
          display: none;
        }
      }
      .summary__swatches{
        margin-bottom: 0;
        .color-swatches{
          .swatch-border-circle, .border-none{
            border: none !important;
            .swatch{
              border: 1px solid #EBEBEB !important;
            }
          }
        }
      }
    }
  }
  .custom-nav{
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    inset-block: 50%;
    .prev,.next{
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
      background-size: contain;
      background-color: transparent;
      border: none;
      z-index: 2;
      padding: 0;
      position: relative;
      @media screen and (max-width: 768px) {
        width: 35px;
        height: 35px;
      }
      &:focus{
        outline: none;
      }
    }
    .prev{
      background-image: url('../images/prev.svg');
      @media screen and (max-width: 768px) {
        background-image: url('../images/prev-mobile.svg');
        left: 7px;
      }
    }
    .next{
      background-image: url('../images/next.svg');
      @media screen and (max-width: 768px) {
        background-image: url('../images/next-mobile.svg');
        right: 7px;
      }
    }
  }
}